import React, {FC} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import {OverlapDisplayType, ProductOverlap} from '../../types';

type props = {
  skuOverlaps: ProductOverlap[];
  textPrefix: OverlapDisplayType;
};

const SKUOverlapContent: FC<props> = ({skuOverlaps, textPrefix}) => (
  <Accordion elevation={0} sx={{backgroundColor: 'inherit'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        minHeight: 'unset !important',
        '& .MuiAccordionSummary-content': {margin: '0 !important', paddingTop: '1rem'},
        '& .MuiAccordionSummary-expandIconWrapper': {
          order: -1,
          marginRight: 1,
        },
      }}
    >
      <Stack direction="column" gap={1}>
        <Typography color="warning.main" fontWeight="600">
          Sku overlap
        </Typography>
        <Typography sx={{'.Mui-expanded &': {display: 'none'}}} color="warning.main">
          Click to view details
        </Typography>
      </Stack>
    </AccordionSummary>

    <AccordionDetails
      sx={{
        '& .MuiAccordionDetails-root': {py: 0},
        '& p': {lineHeight: 'unset'},
      }}
    >
      {skuOverlaps.map((skuOverlap, index) => (
        <List sx={{pl: 2}} key={index}>
          <ListItem sx={{py: 0}} disablePadding>
            <ListItemIcon sx={{minWidth: '1rem'}}>
              <FiberManualRecordIcon sx={{fontSize: '0.375rem', color: 'warning.main'}} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="warning.main">{`${textPrefix} Id: ${skuOverlap.id}`}</Typography>
              }
            />
          </ListItem>
          <ListItem sx={{py: 0, pl: 3}} disablePadding>
            <ListItemIcon sx={{minWidth: '1rem'}}>
              <FiberManualRecordIcon sx={{fontSize: '0.375rem'}} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography>{`${textPrefix} Description: ${skuOverlap.description}`}</Typography>
              }
            />
          </ListItem>
          <ListItem sx={{py: 0, pl: 3}} disablePadding>
            <ListItemIcon sx={{minWidth: '1rem'}}>
              <FiberManualRecordIcon sx={{fontSize: '0.375rem'}} />
            </ListItemIcon>
            <ListItemText primary={<Typography>Owner: {skuOverlap.ownedByStaffName}</Typography>} />
          </ListItem>
          {skuOverlap.departments && skuOverlap.departments.length > 0 && (
            <ListItem sx={{py: 0, pl: 3}} disablePadding>
              <ListItemIcon sx={{minWidth: '1rem'}}>
                <FiberManualRecordIcon sx={{fontSize: '0.375rem'}} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>Department/s: {skuOverlap.departments?.join(', ')}</Typography>
                }
              />
            </ListItem>
          )}
        </List>
      ))}
    </AccordionDetails>
  </Accordion>
);

export default SKUOverlapContent;
