import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Department, Order, Pagination} from '../types';
import {ClaimVendor} from '../components/Form/Agolia/VendorSearch';
import Consts from './Consts';
import {FinanceAccount} from './financialAccountsReducer';
import {Owner} from './ownersReducer';

export interface DealSideFilter {
  claimVendor: ClaimVendor | null;
  financeAccount: FinanceAccount | null;
  startAt: string | null;
  endAt: string | null;
  owner: Owner | null;
  departments: Department[] | null;
  status: string | null;
  dealTypes: {label: string; value: string}[] | null;
  productCode: string | null;
}

export interface DealsState {
  searchText: string | null;
  order: Order;
  pagination: Pagination;
  filterSelection: DealSideFilter;
  templateModalOpen: boolean;
  mixAndMatchTypeChangeLoading: boolean;
}

export const initialState: DealsState = {
  searchText: null,
  order: {
    orderBy: 'endAt',
    orderByDirection: 'desc',
  },
  filterSelection: {
    claimVendor: null,
    startAt: null,
    endAt: null,
    financeAccount: null,
    owner: null,
    departments: [],
    status: null,
    dealTypes: [],
    productCode: null,
  },
  pagination: {
    totalCount: Consts.DefaultPagination.totalCount,
    totalPages: Consts.DefaultPagination.totalPages,
    pageSize: Consts.DefaultPagination.pageSize,
    currentPage: Consts.DefaultPagination.currentPage,
  },
  templateModalOpen: false,
  mixAndMatchTypeChangeLoading: false,
};

const sliceName = 'deals';

const deals = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setDealsSearch(state, action: PayloadAction<string | null>) {
      state.searchText = action.payload;
    },
    setDealsFilter(state, action: PayloadAction<DealSideFilter>) {
      state.filterSelection = action.payload;
    },
    setDealsOwnerFilter(state, action: PayloadAction<Owner | null>) {
      state.filterSelection = {...state.filterSelection, owner: action.payload};
    },
    setDealsPagination(state, action: PayloadAction<Partial<Pagination>>) {
      state.pagination = {
        ...Consts.DefaultPagination,
        ...state.pagination,
        ...action.payload,
      };
    },
    setDealsOrder(state, action: PayloadAction<Order>) {
      state.order = action.payload;
    },
    setDealsTemplateModalOpen(state, action: PayloadAction<boolean>) {
      state.templateModalOpen = action.payload;
    },
    setMixAndMatchTypeChangeLoading(state, action: PayloadAction<boolean>) {
      state.mixAndMatchTypeChangeLoading = action.payload;
    },
  },
});

const dealsReducer = deals.reducer;
export const {
  setDealsSearch,
  setDealsFilter,
  setDealsOwnerFilter,
  setDealsPagination,
  setDealsOrder,
  setDealsTemplateModalOpen,
  setMixAndMatchTypeChangeLoading,
} = deals.actions;
export default dealsReducer;
