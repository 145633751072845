import React, {FC} from 'react';
import Consts from '../../../app/Consts';
import {MixAndMatchSummaryResponse} from '../../../types';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {getFormattedDateTime} from '../../../utils/DateUtils';

type Props = {
  summaryData: MixAndMatchSummaryResponse;
};

const MixAndMatchSummaryDetails: FC<Props> = ({summaryData}) => {
  const isCancelled = summaryData.claimingStatus === Consts.AgreementStatusEnum.Cancelled;
  const details = [
    {
      title: 'Mix & Match Type',
      value: summaryData.mixAndMatchType,
    },
    {
      title: 'Claim Vendor/s',
      value: summaryData.claimVendorName,
    },
    {
      title: 'Department/s',
      value: summaryData.departmentDescriptions,
    },
    {
      title: 'Start Date',
      value: getFormattedDateTime(summaryData.startAt),
    },
    {
      title: 'End Date',
      value: getFormattedDateTime(summaryData.endAt),
    },
    {
      title: 'Owner',
      value: `${summaryData.createdByStaffCode} - ${summaryData.createdByStaffName}`,
    },
    {
      title: isCancelled ? 'Cancelled Date' : 'Last Modified Date',
      value: getFormattedDateTime(summaryData.lastUpdatedAt),
    },
    {
      title: isCancelled ? 'Cancelled By' : 'Last Modified By',
      value: summaryData.lastUpdatedByStaffName ?? '-',
    },
    ...(isCancelled
      ? []
      : [
          {
            title: 'Finance Account',
            value: summaryData.financeAccount ?? '-',
          },
        ]),
    {
      title: 'Customer Receipt Description',
      value: summaryData.receiptDescription ?? '-',
    },
  ];

  return (
    <SummaryDetailsComponent
      id={summaryData.id}
      details={details}
      status={summaryData.claimingStatus}
      desc={summaryData.description}
      staffCode={summaryData.createdByStaffCode}
      staffName={summaryData.createdByStaffName}
      groupClaimsOnSingleInvoice={summaryData.groupClaimsOnSingleInvoice}
      hideOwner
      type="Mix & Match"
    />
  );
};

export default MixAndMatchSummaryDetails;
