import React, {FC, useCallback, useContext, useEffect, useState, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import {api, put, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {
  RebateFormValues,
  RebateFormBag,
  RebateFinanceDetailsResponse,
  AccountOption,
  EditRebateFinanceDetailsRequest,
  EntityActionType,
} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FinanceAccount from '../Form/FinanceAccount/FinanceAccount';
import OptionalIndicator from '../Form/OptionalIndicator';
import SelectField from '../Form/SelectField';
import CheckboxField from '../Form/CheckboxField';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';

const ExcludeReturnsLabel = styled('div')`
  margin-top: 1.25rem;
  > span {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    padding-left: 5px;
  }
`;

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  lastStepButtonText?: string;
  handleBack: (values: RebateFormValues, bag: RebateFormBag) => void;
  handleNext: (values: RebateFormValues, bag: RebateFormBag) => void;
};

const StepFinanceDetails: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  lastStepButtonText = 'Finished',
  handleBack,
  handleNext,
}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<RebateFormValues>();
  const {
    setFieldValue,
    values: {
      id,
      amountType,
      amount,
      claimInterval,
      externalId,
      splitAccounts,
      status,
      financeAccountCleared,
      rebateType,
      excludeReturns,
      hasActiveClaim,
      isOngoing,
      hasRaisedClaim,
    },
  } = bag;
  const [accountOptions, setAccountOptions] = useState<AccountOption[]>([]);

  const updateFormValues = useCallback(
    (financeDetailsResponse: RebateFinanceDetailsResponse) => {
      setFieldValue('claimInterval', financeDetailsResponse.claimInterval);
      const shouldExcludeReturns =
        financeDetailsResponse.excludeReturns === null
          ? rebateType === Consts.RebateTypeEnum.Sales && status === Consts.RebateStatusEnum.Draft
          : financeDetailsResponse.excludeReturns;
      setFieldValue('excludeReturns', shouldExcludeReturns);
      setAccountOptions(financeDetailsResponse.accountOptions);
      setFieldValue('accountOptions', financeDetailsResponse.accountOptions);
      setFieldValue('splitAccounts', financeDetailsResponse.splitAccounts || []);
      setFieldValue('externalId', financeDetailsResponse.externalId || '');
      const splitAccountAmountType =
        amountType === Consts.AmountTypeEnum.Percentage
          ? Consts.SplitAccountAmountTypeEnum.Percentage
          : Consts.SplitAccountAmountTypeEnum.Value;
      setFieldValue('splitAccountAmountType', splitAccountAmountType);
    },
    [setFieldValue, amountType, rebateType, status]
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    showLoading();
    get(api(Consts.Api.RebateFinanceDetails.replace(':id', `${id}`)))
      .then((response: AxiosResponse<RebateFinanceDetailsResponse>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [id, hideLoading, showLoading, updateFormValues]);

  async function saveFinanceDetails(values: RebateFormValues, bag: RebateFormBag) {
    if (!values.id) {
      return;
    }
    showLoading();
    const requestData: EditRebateFinanceDetailsRequest = {
      externalId,
      claimInterval,
      excludeReturns,
      splitAccounts: splitAccounts.map((x) => {
        return {
          accountId: x.accountId,
          additionalInfo: x.additionalInfo,
          amount: x.amount,
          hasAdditionalField: x.hasAdditionalField,
        };
      }),
    };
    return await put(
      api(Consts.Api.RebateFinanceDetails.replace(':id', `${values.id}`)),
      requestData
    )
      .then(() => {
        alertService.clear(defaultAlertId);
        setFieldValue('financeAccountCleared', false);
        handleNext(values, bag);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }
  const claimIntervalOptions = useMemo(() => {
    if (isOngoing) {
      return Consts.RebateClaimInterval.filter(
        (x) => x.value !== Consts.RebateClaimIntervalEnum.EndOfRebate
      );
    }
    return Consts.RebateClaimInterval;
  }, [isOngoing]);

  const defaultClaimInterval = Consts.RebateClaimInterval.find(
    (item) => item.value === claimInterval
  );

  return (
    <>
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.Rebate}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
        />
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>Which finance account is the money going to?</FieldLabel>
          <FinanceAccount
            name="splitAccounts"
            accountOptions={accountOptions}
            canSplit
            totalAmount={Number(amount)}
            agreementType={Consts.AgreementTypeEnum.ContractAgreement}
            setDefaultIfEmpty={status === Consts.RebateStatusEnum.Draft && !financeAccountCleared}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>When is this rebate going to be claimed?</FieldLabel>
          <SelectField
            name="claimInterval"
            id="claimInterval"
            fullWidth
            options={claimIntervalOptions}
            defaultOption={defaultClaimInterval}
          />
          <CheckboxField
            id="excludeReturns"
            name="excludeReturns"
            sx={{marginTop: '1.25rem'}}
            disabled={rebateType !== Consts.RebateTypeEnum.Sales}
            label={
              <ExcludeReturnsLabel>
                Exclude returns when claiming
                <span>(*Note - including returns will reduce margin)</span>
              </ExcludeReturnsLabel>
            }
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="externalId" fullWidth>
            External ID <OptionalIndicator />
          </FieldLabel>
          <InputField id="externalId" name="externalId" fullWidth />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={saveFinanceDetails}
        step={step}
        totalStep={totalStep}
        lastStepButtonText={lastStepButtonText}
      />
    </>
  );
};

export default StepFinanceDetails;
