import React, {FC, useState, useEffect, useContext, useCallback} from 'react';
import qs from 'qs';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Consts from '../../app/Consts';
import {useAppDispatch, useAppSelector} from '../../app/store';
import {selectConfigsData} from '../../app/selectors';
import {setDealsTemplateModalOpen} from '../../app/dealsReducer';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import {
  getDisplayAmountValue,
  getDisplayCommasValue,
  isNullish,
  numberWithCommas,
} from '../../utils';
import {post, api, put, del, get} from '../../utils/Request';
import {
  DataMode,
  LocalSPIVValue,
  OverlapDisplayType,
  Pagination,
  ProductOverlap,
  SPIVValue,
  SelectOption,
  SpivValuesResponse,
  TError,
  TableColumn,
  ValidLocalSPIVValue,
  ValidSPIVFormValues,
} from '../../types';
import {ClearSPIVValueDataConfirmModal, BulkUploadModal, BulkUploadConfirmModal} from '../Modal';
import SkuSearch from '../Form/Agolia/SkuSearch';
import TablePaginationWithAddButton from '../Table/Pagination/TablePaginationWithAddButton';
import {
  CancelActionButton,
  DeleteActionButton,
  EditActionButton,
  SaveActionButton,
  TableCellInputField,
  TableCellSelect,
  TableCellUnitField,
} from '../Table';
import NumberFormatInput, {WholeNumberFormatField} from '../NumberFormatInput';
import {WarnIcon} from '../Icons';
import {BulkUploadIconButton} from '../Button';
import {spivValuesValidationSchema} from './validationSchema';
import {
  calculateSpivWrittenAmount,
  createNewRowData,
  formatErrorMsg,
  getDealCapType,
  getIncentiveAmountMessage,
  getSpivValueRequestData,
  isDuplicateSKUError,
  spivDisabled,
} from './spivUtils';
import SKUOverlapContent from '../SKUOverlapContent';
import {ErrorBox} from '../Alert';

const PREFIX = 'SPIVValuesTable';

const classes = {
  tableCell: `${PREFIX}-tableCell`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
});

const minSellPriceTypes = {
  GoSell: 'GoSell',
  MinSellPrice: 'MinSellPrice',
};

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;
const ActionCellContainer = styled('div')`
  display: flex;
`;

type SKUOverlapState = Record<ProductOverlap['productCode'], ProductOverlap[]>;

type ProductSupplier = {
  supplierName: string;
  supplierNumber: number;
  supplierNameAndNumber: string;
};
type SPIVValueColumn = Omit<TableColumn<LocalSPIVValue>, 'id'>;
type Props = {
  onTableDataUpdating: (isUpdating: boolean) => void;
};

const SPIVValuesTable: FC<Props> = ({onTableDataUpdating}) => {
  const [keepAdding, setKeepAdding] = useState(false);
  const [data, setData] = useState<LocalSPIVValue[]>([]);
  const [valueErrors, setValueErrors] = useState<TError>();
  const [duplicateSKUErrorMsg, setDuplicateSKUErrorMsg] = useState<string | null>(null);
  const [valuesSnapshot, setValuesSnapshot] = useState<(SPIVValue | LocalSPIVValue)[]>([]);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [bulkUploadConfirmModalOpen, setBulkUploadConfirmModalOpen] = useState(false);
  const [clearExistingDuringBulkUpload, setClearExistingDuringBulkUpload] = useState(false);
  const [hasErrorResponse, setHasErrorResponse] = useState(false);
  const [skuOverlaps, setSKUOverlaps] = useState<SKUOverlapState | null>(null);

  const tableHasSkuOverlaps = Object.keys(skuOverlaps ?? {}).some((sku) =>
    data?.some((product) => product.productCode === sku)
  );

  const configs = useAppSelector(selectConfigsData);

  const dispatch = useAppDispatch();

  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {id: spivId, claimVendorSuppliers, isFinalClaimRaised},
    setFieldValue,
    touched,
  } = bag;

  const {showLoading, hideLoading} = useContext(LoadingContext);

  const {
    data: spivValuesData,
    isClaimVendorGstFree,
    suppliers,
    ...spivValuesPagination
  } = bag.values?.spivValues ?? {};

  const {actions} = spivDisabled(bag.values);

  const getSpivValues = useCallback(
    async (params?: Partial<Pagination>) => {
      showLoading();
      alertService.clear(defaultAlertId);
      try {
        const response: AxiosResponse<SpivValuesResponse> = await get(
          api(Consts.Api.SpivValues.replace(':id', `${spivId}`)),
          {
            params,
            paramsSerializer: (params: Partial<Pagination>) =>
              qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'}),
          }
        );
        setFieldValue('spivValues', response.data);
        setFieldValue('spivValueCount', response.data.data?.length ?? 0);
        setFieldValue('suppliers', response.data.suppliers);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load spiv values', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [spivId, setFieldValue, showLoading, hideLoading]
  );

  const clearSPIVValues = useCallback(async () => {
    if (!spivId) {
      return;
    }
    showLoading();
    try {
      await del(api(Consts.Api.SpivValues.replace(':id', `${spivId}`)));
      const spivValues: ValidSPIVFormValues['spivValues'] = {
        data: [],
        suppliers: [],
        isClaimVendorGstFree: false,
        ...Consts.DefaultPagination,
      };
      setFieldValue('spivValues', spivValues);
      setFieldValue('spivValueCount', 0);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to clear spiv values', response: error.response},
      });
    } finally {
      hideLoading();
    }
  }, [spivId, showLoading, hideLoading, setFieldValue]);

  useEffect(() => {
    setValuesSnapshot(spivValuesData ?? []);
  }, [spivValuesData]);

  // check if there are unsaved changes
  useEffect(() => {
    const isRowEditing = data.some((item) => item.dataMode === DataMode.Edit);
    const dataChanged = (Object.values(touched) || []).some(Boolean);
    onTableDataUpdating(isRowEditing || dataChanged || hasErrorResponse);
  }, [data, onTableDataUpdating, touched, hasErrorResponse]);

  useEffect(() => {
    const newRowData = createNewRowData(spivId, isClaimVendorGstFree);
    if (spivValuesData && spivValuesData.length === 0 && !isFinalClaimRaised) {
      setKeepAdding(true);
      setData([newRowData]);
    } else if (keepAdding && !isFinalClaimRaised) {
      setData([...(spivValuesData ?? []), newRowData]);
    } else {
      setData(spivValuesData ?? [newRowData]);
    }
  }, [spivValuesData, isFinalClaimRaised, spivId, isClaimVendorGstFree, keepAdding]);

  useEffect(() => {
    if (spivId) {
      getSpivValues(Consts.DefaultPagination);
    }
  }, [spivId, getSpivValues]);

  const onPagination = useCallback(
    (next?: Partial<Pagination>) => {
      const params = {
        currentPage: next?.currentPage ?? spivValuesPagination.currentPage,
        pageSize: next?.pageSize ?? spivValuesPagination.pageSize,
      };
      getSpivValues(params);
    },
    [getSpivValues, spivValuesPagination]
  );

  const onUploadComplete = () => {
    setBulkUploadModalOpen(false);
    getSpivValues(Consts.DefaultPagination);
  };

  const setFormErrors = (error: any, setAllTouched?: boolean) => {
    setValueErrors((prevErrors) => {
      if (error?.inner?.length === 0) {
        return {};
      }
      return error?.inner?.reduce((acc: any, item: any) => {
        const propName = item.path;
        if (item.message && propName) {
          acc[propName] = {
            ...prevErrors?.[propName],
            error: item.message,
            ...(setAllTouched ? {touched: true} : {}),
          };
        }
        return acc;
      }, {});
    });
  };

  const validateRow = async (
    rowData: LocalSPIVValue,
    setAllTouched?: boolean
  ): Promise<boolean> => {
    try {
      await spivValuesValidationSchema.validate(rowData, {abortEarly: false});
      setFormErrors({});
      return true;
    } catch (error: any) {
      setFormErrors(error, setAllTouched);
      return false;
    }
  };

  const handleUpdateValue = async (rowData: LocalSPIVValue, index: number) => {
    const isValid = await validateRow(rowData, true);
    if (!isValid) {
      return;
    }
    setDuplicateSKUErrorMsg(null);
    try {
      showLoading();
      const {agreementId, ...requestData} = getSpivValueRequestData(rowData as ValidLocalSPIVValue);
      const response: AxiosResponse<SPIVValue> = await put(
        api(
          Consts.Api.SpivValue.replace(':id', `${spivId}`).replace(':spivValueId', `${rowData.id}`)
        ),
        requestData
      );
      alertService.clear(defaultAlertId);
      const nextOverlapState = !response.data?.productOverlaps
        ? {}
        : response.data?.productOverlaps?.reduce((acc: SKUOverlapState, curr: ProductOverlap) => {
            acc[curr.productCode] = [...(acc[curr.productCode] ?? []), curr];
            return acc;
          }, {});
      setSKUOverlaps(nextOverlapState);
      setData((prevData) => {
        const newRowData = {...response.data} as LocalSPIVValue;
        newRowData.dataMode = DataMode.Display;
        const newData = [...prevData];
        newData.splice(index, 1, newRowData);
        return newData;
      });
      setValuesSnapshot((prevData) => {
        const newRowData = {...response.data} as LocalSPIVValue;
        newRowData.dataMode = DataMode.Display;
        const newData = [...prevData];
        newData.splice(index, 1, newRowData);
        return newData;
      });
    } catch (error: any) {
      const errMsg = formatErrorMsg(error);
      if (isDuplicateSKUError(errMsg)) {
        setDuplicateSKUErrorMsg(errMsg);
      } else {
        alertService.alert({
          message: errMsg,
        });
      }
    } finally {
      hideLoading();
    }
  };

  const handleAddValue = async (rowData: LocalSPIVValue) => {
    const isValid = await validateRow(rowData, true);
    if (!isValid) {
      return;
    }
    setDuplicateSKUErrorMsg(null);
    showLoading();
    const validData = rowData as ValidLocalSPIVValue;
    const requestData = getSpivValueRequestData(validData);
    try {
      const response: AxiosResponse<SPIVValue> = await post(
        api(Consts.Api.SpivValues).replace(':id', `${spivId}`),
        requestData
      );
      alertService.clear(defaultAlertId);
      const nextOverlapState = !response.data?.productOverlaps
        ? {}
        : response.data?.productOverlaps?.reduce((acc: SKUOverlapState, curr: ProductOverlap) => {
            acc[curr.productCode] = [...(acc[curr.productCode] ?? []), curr];
            return acc;
          }, {});
      setSKUOverlaps(nextOverlapState);
      getSpivValues();
      setHasErrorResponse(false);
    } catch (error: any) {
      setHasErrorResponse(true);
      const errMsg = formatErrorMsg(error);
      if (isDuplicateSKUError(errMsg)) {
        setDuplicateSKUErrorMsg(errMsg);
      } else {
        alertService.alert({
          message: errMsg,
        });
      }
    } finally {
      hideLoading();
    }
  };

  const handleStartEdit = (rowData: LocalSPIVValue, rowIndex: number) => {
    const data = getUpdatedData(rowData, 'dataMode', rowIndex, DataMode.Edit);
    setData(data);
  };

  const handleDeleteValue = async (rowData: LocalSPIVValue, index: number) => {
    setDuplicateSKUErrorMsg(null);
    if (rowData.dataMode === DataMode.Add) {
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
      setKeepAdding(false);
    } else {
      try {
        showLoading();
        await del(
          api(
            Consts.Api.SpivValue.replace(':id', `${spivId}`).replace(
              ':spivValueId',
              `${rowData.id}`
            )
          )
        );
        alertService.clear(defaultAlertId);
        setSKUOverlaps((prevOverlaps) => {
          if (prevOverlaps && rowData.productCode) {
            const {[rowData.productCode]: _, ...rest} = prevOverlaps;
            return rest;
          }
          return prevOverlaps;
        });
        postDeletion();
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    }
  };

  const handleCancelEdit = (_rowData: LocalSPIVValue, rowIndex: number) => {
    const data = getUpdatedData(valuesSnapshot[rowIndex], 'dataMode', rowIndex, DataMode.Display);
    setData(data);
  };

  const getColumns = () => {
    const columns: SPIVValueColumn[] = [
      {
        field: 'isSupplierMismatch',
        render: (rowData) =>
          rowData.isSupplierMismatch ? (
            <Tooltip title={Consts.ValidationMessage.ProductSupplierMismatch} placement="top-start">
              <WarnIcon />
            </Tooltip>
          ) : null,
        style: {
          verticalAlign: 'middle',
          maxWidth: '1.5rem',
          minWidth: '0rem',
          padding: 0,
        },
      },
      {
        field: 'productCode',
        title: renderSkuColumnTitle(),
        render: renderSkuColumn,
        renderEdit: renderEditSkuColumn,
        editStyle: {minWidth: '12.5rem'},
      },
      {
        field: 'amount',
        title: 'SPIV Claim Amount*',
        render: (rowData) => renderPriceColumn(rowData.amount),
        renderEdit: renderEditAmountColumn,
        editStyle: {minWidth: '10.5rem'},
      },
      {
        field: 'gstType',
        title: 'GST*',
        render: (rowData) => rowData.gstType,
        renderEdit: renderEditGstColumn,
        editStyle: {width: '7.25rem'},
      },
      {
        field: 'ticketPrice',
        title: 'Ticket/ELQ Price',
        render: (rowData) => renderPriceColumn(rowData.ticketPrice),
        renderEdit: renderEditPriceColumn,
        editStyle: {minWidth: '7.5rem'},
      },
      {
        field: 'payableIncentiveAmountToStaff',
        title: 'Staff SPIV Payment*',
        render: (rowData) => renderPriceColumn(rowData.payableIncentiveAmountToStaff),
        renderEdit: renderEditIncentiveColumn,
        editStyle: {minWidth: '8.5rem'},
      },
      {
        field: 'capValue',
        title: <span>Claim Unit or $ Cap (ex. GST)</span>,
        render: renderCapColumn,
        renderEdit: renderEditCapColumn,
        editStyle: {minWidth: '10.5rem'},
      },
      {
        field: 'minSellPrice',
        title: 'Min Sell Price',
        render: renderMinSellPriceColumn,
        renderEdit: renderEditMinSellPriceColumn,
        editStyle: {minWidth: '11.5rem'},
      },
      {
        field: null,
        title: '',
        render: renderActionColumn,
        style: {width: '6.25rem'},
        isAction: true,
      },
    ];
    return columns;
  };

  const getUpdatedData = (rowData: LocalSPIVValue, propName: string, index: number, value: any) => {
    const newRowData = {...rowData, [propName]: value};
    setValueErrors((prevErrors) => ({
      ...prevErrors,
      [propName]: {
        ...prevErrors?.[propName],
        touched: true,
      },
    }));

    validateRow(newRowData);
    const newData = [...data];
    newData.splice(index, 1, newRowData);
    return newData;
  };

  const onConfirmClearData = () => {
    setClearDataWarningModalOpen(false);
  };

  const onBulkUpload = () => {
    if (!!spivValuesData && spivValuesData.length > 0) {
      setBulkUploadConfirmModalOpen(true);
      setBulkUploadModalOpen(false);
    } else {
      setBulkUploadModalOpen(true);
    }
  };

  const renderSkuColumnTitle = () => (
    <span>
      SKU*
      <>
        <BulkUploadIconButton
          onClick={(event) => {
            onBulkUpload();
          }}
          sx={{
            lineHeight: 'normal',
            fontWeight: 'normal',
            paddingLeft: '1rem',
            paddingRight: '0',
          }}
          disabled={actions.bulkUploadValues}
        >
          Bulk Upload SKUs
        </BulkUploadIconButton>
        <ClearSPIVValueDataConfirmModal
          open={clearDataWarningModalOpen}
          onOk={onConfirmClearData}
          onCancel={() => {
            setClearDataWarningModalOpen(false);
          }}
        />
      </>
    </span>
  );

  const renderSkuColumn = (rowData: LocalSPIVValue) => {
    const hasSkuOverlap =
      rowData.productCode && (skuOverlaps?.[rowData.productCode] ?? [])?.length > 0;
    const text = rowData.productCode
      ? `${rowData.productCode} ${rowData.productDescription}`
      : null;
    return (
      <Stack direction="row" alignItems="flex-end" gap={1}>
        {hasSkuOverlap ? <WarnIcon /> : null}
        <span>{text}</span>
      </Stack>
    );
  };

  const renderEditSkuColumn = (rowData: LocalSPIVValue, rowIndex: number) => {
    const errorMessage = valueErrors?.productCode?.touched ? valueErrors?.productCode?.error : null;
    return (
      <SkuSearch
        fullWidth
        placeholder="Product search"
        defaultValue={
          rowData.productCode
            ? {
                code: rowData.productCode,
                name: rowData.productDescription,
                ticketPrice: rowData.ticketPrice,
                claimVendorSuppliers,
              }
            : null
        }
        errorMessage={errorMessage}
        onChanged={(item: {
          code: string;
          name: string;
          ticketPrice: number;
          suppliers: ProductSupplier[];
        }) => {
          setData((prevData) => {
            const editedRow = prevData.find((x) => x.id === rowData.id);
            if (!editedRow) {
              return prevData;
            }

            const isSupplierMismatch =
              claimVendorSuppliers?.length > 0 &&
              item &&
              !item.suppliers?.some((x) =>
                claimVendorSuppliers.some((s) => s.number === x.supplierNumber)
              );

            const nextRow = {
              ...editedRow,
              productCode: item?.code,
              productDescription: item?.name,
              ticketPrice: item?.ticketPrice,
              isSupplierMismatch,
            };
            return getUpdatedData(nextRow, 'productCode', rowIndex, item?.code);
          });
        }}
      />
    );
  };

  const fivePCThresholdWarning = (
    amount: string | number | undefined,
    compare: string | number | undefined
  ) => {
    const showWarning = !!amount && !!compare && Number(amount ?? 1) / Number(compare ?? 1) > 0.05;
    if (showWarning) {
      return 'Value should not exceed more than 5% of the ticket price';
    }
  };

  const renderEditAmountColumn = (rowData: LocalSPIVValue, rowIndex: number) => {
    const showError = valueErrors?.amount?.touched && !!valueErrors?.amount?.error;
    const warningMessage = fivePCThresholdWarning(rowData.amount, rowData.ticketPrice);
    const defaultValue =
      rowData.amount !== null && rowData.amount !== undefined ? `${rowData.amount}` : '';
    const handleAmountChange = (value: string) => {
      const payableIncentiveAmountToStaff = calculateSpivWrittenAmount(configs, value);
      const data = getUpdatedData(
        {
          ...rowData,
          payableIncentiveAmountToStaff:
            payableIncentiveAmountToStaff ?? rowData.payableIncentiveAmountToStaff,
        },
        'amount',
        rowIndex,
        value
      );
      setData(data);
    };
    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$ p.unit" sx={{maxWidth: '5rem'}} />
        <TableCellInputField
          unitInLeft
          fullWidth
          error={showError}
          warning={!showError && !!warningMessage}
          helperText={showError ? valueErrors?.amount?.error : warningMessage}
          value={defaultValue}
          placeholder="Add value"
          regexValidation={/^(-?[\d,]*(?:\.\d{0,2})?)?$/}
          onChanged={handleAmountChange}
          InputProps={{
            inputComponent: NumberFormatInput as any,
          }}
        />
      </CellContainer>
    );
  };

  const renderEditGstColumn = (rowData: LocalSPIVValue, index: number) => {
    const defaultValue = Consts.GstType.find((item) => item.value === rowData.gstType);
    const options = isClaimVendorGstFree
      ? Consts.GstType.filter((x) => x.value === Consts.GstTypeEnum.Free)
      : Consts.GstType;
    return (
      <TableCellSelect
        disabled={isClaimVendorGstFree}
        fullWidth
        sx={{minWidth: '115px'}}
        options={options}
        defaultValue={defaultValue}
        onChanged={(option: SelectOption) => {
          const data = getUpdatedData(rowData, 'gstType', index, option.value);
          setData(data);
        }}
      />
    );
  };

  const renderPriceColumn = (price: number | null | undefined) => getDisplayAmountValue(price, '$');

  const renderEditPriceColumn = (rowData: LocalSPIVValue) => {
    const defaultValue =
      rowData.ticketPrice !== null && rowData.ticketPrice !== undefined
        ? `${rowData.ticketPrice}`
        : '';
    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$" sx={{maxWidth: '2.5rem'}} />
        <TableCellInputField
          unitInLeft
          readOnly
          value={defaultValue}
          regexValidation={/^(-?[\d,]*(?:\.\d{0,2})?)?$/}
        />
      </CellContainer>
    );
  };

  const renderEditIncentiveColumn = (rowData: LocalSPIVValue, rowIndex: number) => {
    const showError =
      valueErrors?.payableIncentiveAmountToStaff?.touched &&
      !!valueErrors?.payableIncentiveAmountToStaff?.error;
    const fivePCWarning = fivePCThresholdWarning(
      rowData.payableIncentiveAmountToStaff,
      rowData.ticketPrice
    );
    const incentiveWarning = getIncentiveAmountMessage(
      configs,
      rowData?.payableIncentiveAmountToStaff,
      rowData.amount
    );
    const defaultValue =
      rowData.payableIncentiveAmountToStaff !== null &&
      rowData.payableIncentiveAmountToStaff !== undefined
        ? `${rowData.payableIncentiveAmountToStaff}`
        : '';

    const helperText = showError ? (
      valueErrors?.payableIncentiveAmountToStaff?.error
    ) : (
      <>
        {fivePCWarning ? (
          <span>
            {fivePCWarning}
            <br />
            <br />
          </span>
        ) : null}
        {incentiveWarning ? <span>{incentiveWarning}</span> : null}
      </>
    );

    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$" sx={{maxWidth: '2.5rem'}} />
        <TableCellInputField
          unitInLeft
          fullWidth
          error={showError}
          warning={!showError && (!!fivePCWarning || !!incentiveWarning)}
          helperText={helperText}
          value={defaultValue}
          placeholder="Add price"
          regexValidation={/^(-?[\d,]*(?:\.\d{0,2})?)?$/}
          onChanged={(value) => {
            setValueErrors((prevErrors) => ({
              ...prevErrors,
              payableIncentiveAmountToStaff: {
                ...prevErrors?.payableIncentiveAmountToStaff,
                touched: true,
              },
            }));
            const data = getUpdatedData(rowData, 'payableIncentiveAmountToStaff', rowIndex, value);
            setData(data);
          }}
          InputProps={{
            inputComponent: NumberFormatInput as any,
          }}
        />
      </CellContainer>
    );
  };

  const renderCapColumn = (rowData: LocalSPIVValue) => {
    const unit = Consts.DealCapType.find((item) => item.value === rowData.capType);
    const value = numberWithCommas(rowData.capValue);
    if (!value || !unit) {
      return null;
    }
    if (unit.value === Consts.DealCapTypeEnum.UnitCount) {
      const capValue = rowData.capValue ?? null;
      return getDisplayCommasValue(capValue, '', capValue && capValue > 1 ? ' Units' : ' Unit');
    }
    return getDisplayAmountValue(rowData.capValue, '$');
  };

  const renderEditCapColumn = (rowData: LocalSPIVValue, rowIndex: number) => {
    const {capValue, capType} = rowData;
    const defaultType = getDealCapType(capType);
    const digits = !!capType && capType === Consts.DealCapTypeEnum.TotalValue ? 2 : 0;
    // update the cap value to integer if its unit count
    if (digits === 0 && !!capValue && !Number.isInteger(capValue)) {
      const nextValue =
        !!capValue && isNaN(Math.round(capValue)) ? undefined : Math.round(capValue);

      const data = getUpdatedData(rowData, 'capValue', rowIndex, nextValue);
      rowData = data[rowIndex];
      setData(data);
    }
    const defaultValue = capValue !== null && capValue !== undefined ? `${capValue}` : '';

    const regex =
      (rowData.capType ?? defaultType.value) === Consts.DealCapTypeEnum.UnitCount
        ? /^[\d,]*$/
        : /^(-?[\d,]*(?:\.\d{0,2})?)?$/;

    const handleValueChange = (value: string) => {
      const data = getUpdatedData(rowData, 'capValue', rowIndex, value);
      setData(data);
    };

    const Input =
      capValue && rowData.capType === Consts.DealCapTypeEnum.TotalValue
        ? NumberFormatInput
        : WholeNumberFormatField;

    return (
      <CellContainer>
        <TableCellSelect
          sx={{minWidth: '75px'}}
          options={Consts.DealCapType}
          defaultValue={defaultType}
          onChanged={(option: SelectOption) => {
            const data = getUpdatedData(rowData, 'capType', rowIndex, option.value);
            setData(data);
          }}
        />
        <TableCellInputField
          fullWidth
          digits={digits}
          value={defaultValue}
          placeholder="Add cap"
          regexValidation={regex}
          onChanged={handleValueChange}
          InputProps={{
            inputComponent: Input as any,
          }}
        />
      </CellContainer>
    );
  };

  const renderMinSellPriceColumn = (rowData: LocalSPIVValue) => {
    const type =
      minSellPriceTypes?.[rowData.minSellPriceType as keyof typeof minSellPriceTypes] ??
      minSellPriceTypes.GoSell;
    const value = numberWithCommas(rowData.minSellPrice);
    if (!value || type === minSellPriceTypes.GoSell) {
      return null;
    }
    return getDisplayAmountValue(rowData.minSellPrice, '$');
  };

  const renderEditMinSellPriceColumn = (rowData: LocalSPIVValue, rowIndex: number) => {
    const defaultType =
      Consts.SPIVMinSellPriceType.find((item) => item.value === rowData.minSellPriceType) ??
      Consts.SPIVMinSellPriceTypeEnum.GoSell;
    const showError = valueErrors?.minSellPrice?.touched && !!valueErrors?.minSellPrice?.error;
    const defaultValue = !isNullish(rowData.minSellPrice) ? `${rowData.minSellPrice}` : '';

    return (
      <CellContainer>
        <TableCellSelect
          options={Consts.SPIVMinSellPriceType}
          defaultValue={defaultType}
          onChanged={(option: SelectOption) => {
            const minSellPrice =
              option.value === Consts.SPIVMinSellPriceTypeEnum.GoSell
                ? undefined
                : rowData.minSellPrice;
            const data = getUpdatedData(
              {...rowData, minSellPrice},
              'minSellPriceType',
              rowIndex,
              option.value
            );
            setData(data);
          }}
        />
        <TableCellInputField
          unitInLeft
          fullWidth
          disabled={rowData.minSellPriceType === Consts.SPIVMinSellPriceTypeEnum.GoSell}
          error={showError}
          helperText={showError ? valueErrors?.minSellPrice?.error : ''}
          value={defaultValue}
          placeholder="Add value"
          regexValidation={/^(-?[\d,]*(?:\.\d{0,2})?)?$/}
          onChanged={(value) => {
            const data = getUpdatedData(rowData, 'minSellPrice', rowIndex, value);
            setData(data);
          }}
          InputProps={{
            inputComponent: NumberFormatInput as any,
          }}
        />
      </CellContainer>
    );
  };

  const renderActionColumn = (rowData: LocalSPIVValue, index: number) => {
    const leftActionButtonStyle = {marginRight: '5px'};
    if (rowData.dataMode === DataMode.Edit) {
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => handleUpdateValue(rowData, index)}
          />
          <CancelActionButton onClick={() => handleCancelEdit(rowData, index)} />
        </ActionCellContainer>
      );
    }
    if (rowData.dataMode === DataMode.Add) {
      const disabled =
        (rowData.dataMode === DataMode.Add && data.length === 1) || actions.deleteSpivValue;
      return (
        <ActionCellContainer>
          <SaveActionButton style={leftActionButtonStyle} onClick={() => handleAddValue(rowData)} />
          <DeleteActionButton
            disabled={disabled}
            onClick={() => handleDeleteValue(rowData, index)}
          />
        </ActionCellContainer>
      );
    }

    const disabled =
      actions.editSpivValues ||
      (rowData.dataMode !== DataMode.Edit && data.some((item) => item.dataMode === DataMode.Edit));

    return (
      <ActionCellContainer>
        <EditActionButton
          style={leftActionButtonStyle}
          disabled={disabled}
          onClick={() => handleStartEdit(rowData, index)}
        />
        <DeleteActionButton disabled={disabled} onClick={() => handleDeleteValue(rowData, index)} />
      </ActionCellContainer>
    );
  };

  const renderCell = (
    column: SPIVValueColumn,
    row: LocalSPIVValue,
    rowIndex: number
  ): null | React.ReactNode | SPIVValueColumn['field'] => {
    if ((row.dataMode === DataMode.Add || row.dataMode === DataMode.Edit) && column.renderEdit) {
      return column.renderEdit(row, rowIndex);
    } else if (column.render) {
      if (column.isAction) {
        return <ActionCellContainer>{column.render(row, rowIndex)}</ActionCellContainer>;
      } else {
        return <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
      }
    } else {
      return (row[column.field as keyof LocalSPIVValue] as string | null) ?? null;
    }
  };

  const renderRow = (
    row: LocalSPIVValue,
    rowIndex: number,
    rowSkuOverlaps?: ProductOverlap[] | null
  ) => {
    const rowHasSkuOverlaps = Array.isArray(rowSkuOverlaps) && rowSkuOverlaps.length > 0;
    return (
      <React.Fragment key={rowIndex}>
        <TableRow key={rowIndex}>
          {getColumns().map((column, columnIndex) => {
            const displayMode = row.dataMode === DataMode.Display || !row.dataMode;
            let style = column.style;
            if (!displayMode && column.editStyle) {
              style = column.editStyle;
            }
            return (
              <TableCell
                classes={{root: displayMode ? '' : classes.tableCell}}
                key={columnIndex}
                style={style}
              >
                {renderCell(column, row, rowIndex)}
              </TableCell>
            );
          })}
        </TableRow>
        {rowHasSkuOverlaps ? (
          <TableRow sx={{backgroundColor: 'orange.tint'}}>
            <TableCell colSpan={12} sx={{pt: 0}}>
              <SKUOverlapContent
                skuOverlaps={rowSkuOverlaps}
                textPrefix={OverlapDisplayType.Spiv}
              />
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  };

  const handleAddRow = () => {
    const emptyRowData = createNewRowData(spivId, isClaimVendorGstFree);
    setData([...data, emptyRowData]);
    setKeepAdding(true);
  };

  const postDeletion = () => {
    const isLastItemOnPage =
      `${spivValuesData?.length}`.at(-1) === '1' && spivValuesPagination.currentPage > 1;
    const currentPage = isLastItemOnPage
      ? spivValuesPagination.currentPage - 1
      : spivValuesPagination.currentPage;
    onPagination({currentPage});
  };

  const onChangePage = (currentPage: number) => {
    onPagination({currentPage});
  };

  const onChangePageSize = (pageSize: number) => {
    onPagination({currentPage: 1, pageSize});
  };

  return (
    <Stack direction="column">
      {tableHasSkuOverlaps ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            border: 'solid 1px #DA6A00',
            padding: '0.5rem 1rem',
          }}
        >
          <div>
            There is an overlap of SKUs across the same date period in the highlighted items below.
          </div>
        </ErrorBox>
      ) : null}
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {getColumns().map((column, index) => (
                <TableCell key={index} style={column.style}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="spivValues">
            {data.map((row, index) =>
              renderRow(
                row,
                index,
                skuOverlaps === null || !row.productCode
                  ? null
                  : skuOverlaps?.[row.productCode] ?? null
              )
            )}
            {duplicateSKUErrorMsg ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <Alert
                    icon={<ErrorIcon height="1.5rem" width="1.5rem" />}
                    severity="error"
                    sx={{padding: '0 1rem', border: 'solid 1px red'}}
                  >
                    {duplicateSKUErrorMsg}
                  </Alert>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        <TablePaginationWithAddButton
          addButtonText="Add another SPIV value"
          disabled={data.some((item) => item.dataMode === DataMode.Add) || actions.addSpivValue}
          onAdd={handleAddRow}
          pagination={spivValuesPagination}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
        <BulkUploadModal
          title="Bulk Upload SPIV Values"
          uploadUrl={api(
            Consts.Api.SpivValuesBulkUpload.replace(':id', `${spivId}`) +
              `?clearExisting=${clearExistingDuringBulkUpload}`
          )}
          open={bulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          onOpenTemplate={() => {
            setBulkUploadModalOpen(false);
            dispatch(setDealsTemplateModalOpen(true));
          }}
          onComplete={onUploadComplete}
          onReupload={clearSPIVValues}
        />
        <BulkUploadConfirmModal
          open={bulkUploadConfirmModalOpen}
          onKeepAdding={() => {
            setClearExistingDuringBulkUpload(false);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onReplace={() => {
            setClearExistingDuringBulkUpload(true);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onCancel={() => {
            setBulkUploadConfirmModalOpen(false);
          }}
          clearedText="all values in this spiv"
        />
      </StyledTableContainer>
    </Stack>
  );
};

export default SPIVValuesTable;
