import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Consts from '../../app/Consts';
import {StaticButton} from '../Button';
import {ITSupportLink} from '../Link';
import ErrorIcon from '../Icons/ErrorIcon';

const PREFIX = 'ErrorSection';

const classes = {
  errorHeader: `${PREFIX}-errorHeader`,
  errorIcon: `${PREFIX}-errorIcon`,
  errorSectionTitle: `${PREFIX}-errorSectionTitle`,
  errorSectionSubtitle: `${PREFIX}-errorSectionSubtitle`,
  errorSection: `${PREFIX}-errorSection`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.errorHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '38px',
    marginTop: '38px',
  },

  [`& .${classes.errorIcon}`]: {
    fontSize: '43px',
  },

  [`& .${classes.errorSectionTitle}`]: {
    fontSize: '20px',
    fontWeight: 500,
    flex: 1,
    textAlign: 'left',
    marginLeft: '20px',
  },

  [`& .${classes.errorSectionSubtitle}`]: {
    fontSize: '16px',
    fontWeight: 'normal',
    marginTop: '5px',
    lineHeight: '26px',
  },

  [`& .${classes.errorSection}`]: {
    marginBottom: '40px',
  },
}));

const listItemHeight = 66;
const listContainerHeight = listItemHeight * 4 + 10;

const ErrorList = styled(List)`
  margin: unset;
  padding: unset;
  width: 100%;
  z-index: 1;
  background-color: #f2f4f5;
  overflow: auto;
  max-height: ${listContainerHeight}px;
`;

const ErrorListItem = styled(ListItem)`
  border-top: 1px solid #cbd2d8;
  box-sizing: border-box;
  line-height: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
`;

const ErrorSection = ({
  title,
  toggleButtonText,
  expanded = false,
  onToggle = () => {},
  children,
  sectionIcon: SectionIcon = ErrorIcon,
}) => {
  const [open, setOpen] = useState(expanded);
  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <Root className={classes.errorSection}>
      <Divider />
      <div className={classes.errorHeader}>
        <SectionIcon className={classes.errorIcon} />
        <div className={classes.errorSectionTitle}>{title}</div>
        <StaticButton
          onClick={() => {
            setOpen(!open);
            onToggle();
          }}
        >
          {toggleButtonText} {open ? <ExpandLess /> : <ExpandMore />}
        </StaticButton>
      </div>
      {open && children}
    </Root>
  );
};

const SystemError = ({
  errorMessage = 'There was a system error and we could not upload your file.',
}) => {
  return (
    <div>
      <Divider />
      <div className={classes.errorHeader}>
        <ErrorIcon className={classes.errorIcon} />
        <div className={classes.errorSectionTitle}>
          <div>{errorMessage}</div>
          <div className={classes.errorSectionSubtitle}>
            <span>
              Please try uploading your file again. If the problem persists, contact{' '}
              {Consts.ItSupport} at{' '}
            </span>
            <ITSupportLink />
          </div>
        </div>
      </div>
    </div>
  );
};

const TemplateLinkButton = styled(StaticButton)`
  padding: 0;
  line-height: normal;
  vertical-align: unset;
`;

const InvalidFileFormatError = ({onOpenTemplate, linkText = 'one of our templates'}) => {
  return (
    <div>
      <Divider />
      <div className={classes.errorHeader}>
        <ErrorIcon className={classes.errorIcon} />
        <div className={classes.errorSectionTitle}>
          <div>Incorrect Excel template</div>
          <div className={classes.errorSectionSubtitle}>
            <span>Upload in Excel format using </span>
            <TemplateLinkButton onClick={onOpenTemplate}>{linkText}</TemplateLinkButton>
            <span>
              {' '}
              to ensure data is uploaded correctly. Make sure that you have not modified the
              template structure.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyDealUploadFileError = () => {
  return (
    <div>
      <Divider />
      <div className={classes.errorHeader}>
        <ErrorIcon className={classes.errorIcon} />
        <div className={classes.errorSectionTitle}>
          <div>It looks like your file is empty.</div>
          <div className={classes.errorSectionSubtitle}>
            <span>
              We did not find any data in your file. Please add at least one record in your Excel
              template and upload again.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyFileError = ({onOpenTemplate}) => {
  return (
    <div>
      <Divider />
      <div className={classes.errorHeader}>
        <ErrorIcon className={classes.errorIcon} />
        <div className={classes.errorSectionTitle}>
          <div>It looks like your file is empty.</div>
          <div className={classes.errorSectionSubtitle}>
            <span>
              We did not find any data in your file. Please add at least one record and upload
              again.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorSection;
export {
  SystemError,
  InvalidFileFormatError,
  EmptyDealUploadFileError,
  EmptyFileError,
  ErrorList,
  ErrorListItem,
};
