import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StepDivider from './StepDivider';

const StyledStepNumber = styled('div')`
  color: #626262;
  font-size: 0.8785rem;
  margin-bottom: 1rem;
`;
type Props = {
  step: number;
  title: string;
  renderHeader?: () => React.ReactNode;
  children: React.ReactNode;
};

const FormStep: FC<Props> = ({step, title, renderHeader, children}) => (
  <Grid container>
    <Grid item xs={12}>
      <Grid container>
        <Grid item flexGrow={1}>
          <StyledStepNumber>STEP {step}</StyledStepNumber>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {renderHeader ? <Box alignSelf="center">{renderHeader()}</Box> : null}
      </Grid>
      <StepDivider />
    </Grid>
    {children}
  </Grid>
);

export default FormStep;
