import React, {useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Consts from '../../app/Consts';
import {Button} from '../../components/Button';
import ButtonsContainer from '../../components/Container/ButtonsContainer';
import {PageTabs} from '../../components/PageTabs';
import ListFilterContainer from '../../components/ListFilterContainer';

const PREFIX = 'CatalogueListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&. ${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3.1875rem',
    paddingRight: '2.6875rem',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '125rem',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '3.125rem 0',
  },
}));

const CatalogueListing = ({my = false}) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [filterSelection, _setFilterSelection] = useState<{
    status?: string | null;
    departments?: any[];
    catalogueTypes?: any[];
    owner?: any;
  }>({
    status: null,
    departments: [],
    catalogueTypes: [],
    owner: null,
  });

  const status = Consts.CataloguesFilterStatus(my).filter(
    (x) => x.value === filterSelection?.status || null
  )[0];
  const statusTitle = status?.label ?? null;
  const statusValue = status?.value ?? null;

  const handleFilterByStatusChange = (_: any, newStatus: string) => {
    return null;
  };

  const handleAddNewCatalogue = () => {
    navigate(Consts.RouterPath.CreateCatalogue);
  };

  return (
    <Root className={classes.root} ref={filterRef}>
      <PageTabs
        title={statusTitle}
        value={statusValue}
        onChange={handleFilterByStatusChange}
        tabsList={Consts.CataloguesFilterStatus(my)}
        ariaLabel="Filter catalogues by status"
      />

      <Grid container wrap="nowrap">
        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              <ButtonsContainer>
                <Button
                  style={{height: '3.25rem'}}
                  variant="contained"
                  type="button"
                  onClick={handleAddNewCatalogue}
                >
                  Add New Catalogue
                </Button>
              </ButtonsContainer>
            </ListFilterContainer>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
};

export default CatalogueListing;
