import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {QueryCriteria} from '../types';
import {defaultCriteria} from '../components/Form/Agolia/ProductSelector/Consts';

export interface CriteriaState {
  data: QueryCriteria;
}

export const initialState: CriteriaState = {
  data: defaultCriteria,
};

const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    setCriteria(state, action: PayloadAction<QueryCriteria>) {
      state.data = action.payload;
    },
    setResultCount(state, action: PayloadAction<number>) {
      state.data.resultCount = action.payload;
    },
    resetCriteria(state) {
      state.data = defaultCriteria;
    },
  },
});

const criteriaReducer = criteriaSlice.reducer;
export const {setCriteria, setResultCount, resetCriteria} = criteriaSlice.actions;
export default criteriaReducer;
