import React, {FC} from 'react';
import {Configure, InstantSearch} from 'react-instantsearch-dom';
import Box from '@mui/material/Box';
import searchClient from '../../utils/algoliaSearchClient';
import Consts from '../../app/Consts';
import {useAppSelector, useAppDispatch} from '../../app/store';
import {selectCriteria, selectNoFacetsSelected} from '../../app/selectors';
import {setResultCount} from '../../app/criteriaReducer';
import {Criteria, ResultCount} from '../../components/Form/Agolia/ProductSelector';

type Props = {
  readOnly?: boolean;
  children?: React.ReactNode;
};

const ListGeneratorStyles: Record<string, React.CSSProperties> = {
  headerTitle: {
    fontWeight: 500,
    fontSize: '1rem',
  },
  refinementContainer: {
    marginBottom: '1rem',
  },
};

const ProductSelector: FC<Props> = ({readOnly = false, children}) => {
  const noFacetsSelected = useAppSelector(selectNoFacetsSelected);
  const currentCriteria = useAppSelector(selectCriteria);
  const dispatch = useAppDispatch();
  const hadleResult = (nbHits: number) => {
    dispatch(setResultCount(nbHits));
  };

  return (
    <div data-testid="generator-list-product-selector">
      <InstantSearch searchClient={searchClient} indexName={Consts.AlgoliaIndex.Products}>
        <Configure filters={currentCriteria?.filters} highlightPreTag="p" highlightPostTag="p" />
        <Criteria readOnly={readOnly} styleOverrides={ListGeneratorStyles} />
        {children}
        <Box sx={{margin: '1rem 0'}}>
          <ResultCount noSelection={noFacetsSelected} onResult={hadleResult} />
        </Box>
      </InstantSearch>
    </div>
  );
};

export default ProductSelector;
