import React, {FC, useEffect, useState} from 'react';
import {connectStats} from 'react-instantsearch-dom';
import {StatsProvided} from 'react-instantsearch-core';
import {styled} from '@mui/material/styles';
import CustomClearRefinements from './CustomClearRefinements';

const StyledStats = styled('div')`
  width: 100%;
  height: 4.375rem;
  font-weight: 500;
  box-sizing: border-box;
  font-size: 1.125rem;
  background-color: #f2f4f5;
  border: 1px solid #cbd2d8;
  padding-left: 1.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  canClearCriteria?: boolean;
  noSelection?: boolean;
  onClearCriteria?: () => void;
  onResult?: (nbHits: number) => void;
  pluralResultLabel?: string;
  resultLabel?: string;
} & StatsProvided;

export const Stats: FC<Props> = ({
  canClearCriteria,
  nbHits,
  noSelection,
  onClearCriteria,
  onResult,
  pluralResultLabel = 'results',
  resultLabel = 'result',
}) => {
  const [lastNbHits, setLastNbHits] = useState<number>();
  const [displayCount, setDisplayCount] = useState<number>(0);

  useEffect(() => {
    if (nbHits && lastNbHits !== nbHits) {
      setLastNbHits(nbHits);
      // override algolia results eg where null criteria equals max results
      const count = noSelection ? 0 : nbHits;
      setDisplayCount(count);
      onResult?.(count);
    }
  }, [nbHits, onResult, lastNbHits, noSelection]);

  return (
    <StyledStats data-testid="custom-stats">
      <span>
        {`${displayCount} ${
          displayCount === 1 ? resultLabel : pluralResultLabel
        } selected (based on your criteria) `}
      </span>
      {canClearCriteria && onClearCriteria ? (
        <CustomClearRefinements onClearCriteria={onClearCriteria} />
      ) : null}
    </StyledStats>
  );
};

export default connectStats(Stats);
