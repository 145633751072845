import React, {FC} from 'react';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  disabled: boolean;
  children: React.ReactNode;
};

const OptionalTooltip: FC<Props> = ({disabled, children}) => (
  <>
    {disabled ? (
      <Tooltip
        title="inclusion and/or exclusion criteria and template need to be selected to continue"
        sx={{cursor: 'not-allowed'}}
        placement="top"
        data-testid="optional-tooltip"
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);

export default OptionalTooltip;
