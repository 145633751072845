import React, {FC, useContext, useState} from 'react';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {AxiosResponseHeaders} from 'axios';
import Consts from '../../app/Consts';
import {selectCriteria, selectNoFacetsSelected} from '../../app/selectors';
import {useAppSelector} from '../../app/store';
import {alertService, defaultAlertId} from '../../app/AlertService';
import LoadingContext from '../../app/LoadingContext';
import {post, api} from '../../utils/Request';
import {useFeatureFlag} from '../../utils/featureFlags';
import type {SelectOption} from '../../types';
import {TableCellSelect} from '../../components/Table';
import {PageTabs} from '../../components/PageTabs';
import {TableTabPanel} from '../../components/TableTabPanel';
import {Button} from '../../components/Button';
import ProductSelector from './ProductSelector';
import OptionalTooltip from './OptionalTooltip';

const PREFIX = 'ProductListGenerator';

const classes = {
  root: `${PREFIX}-root`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  paper: `${PREFIX}-paper`,
  typography: `${PREFIX}-typography`,
  divider: `${PREFIX}-divider`,
  button: `${PREFIX}-button`,
  templateSelect: `${PREFIX}-templateSelect`,
  templateSelectText: `${PREFIX}-templateSelectText`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    color: theme.palette.black.main,
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '40rem',
      padding: theme.spacing(8, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 10, 6, 10),
    },
    margin: 'auto',
    borderRadius: '1rem',
  },

  [`& .${classes.typography}`]: {
    color: theme.palette.black.main,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(3, 0),
  },

  [`& .${classes.button}`]: {
    width: '14rem',
    height: '3.75rem',
  },

  [`& .${classes.tabPanelRoot}`]: {
    margin: theme.spacing(16, 0),
  },

  [`& .${classes.templateSelect}`]: {
    width: '20rem',
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.templateSelectText}`]: {
    marginBottom: theme.spacing(1),
  },
}));

const ProductListGenerator: FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const noFacetsSelected = useAppSelector(selectNoFacetsSelected);
  const criteria = useAppSelector(selectCriteria);

  const mnmFeatureEnabled = useFeatureFlag(Consts.FeatureFlags.EnableMixAndMatch)?.enabled;

  const options = mnmFeatureEnabled
    ? Consts.ProductListGeneratorTemplateType
    : Consts.ProductListGeneratorTemplateType.filter(
        (option) => option.value !== Consts.ProductListTemplateTypeEnum.MixAndMatchDeals
      );

  const canSubmit = selectedTemplate && !noFacetsSelected;

  const {showLoading, hideLoading} = useContext(LoadingContext);

  const handleTemplateChange = (option: SelectOption) => {
    setSelectedTemplate(option.value);
  };

  const handleSubmit = async () => {
    try {
      showLoading();
      const response = await post(
        api(Consts.Api.ProductListGenerator),
        {
          productListTemplate: selectedTemplate,
          queryCriteria: criteria,
        },
        {responseType: 'blob'}
      );

      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const contentDisposition = (response.headers as AxiosResponseHeaders)?.get(
        'Content-Disposition'
      );
      let filename = 'ProductList.xlsx';
      if (contentDisposition) {
        const filenameMatch = `${contentDisposition}`.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }
      link.href = url;
      link.download = filename;
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Root className={classes.root}>
      <PageTabs title="Product Selector Tool" />
      <TableTabPanel className={classes.tabPanelRoot}>
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.typography}>
            Product List Generator
          </Typography>
          <Divider className={classes.divider} />
          <Grid container direction="column">
            <Grid item>
              <ProductSelector>
                <Typography className={classes.templateSelectText}>
                  Select a template to display your selection:
                </Typography>
                <TableCellSelect
                  className={classes.templateSelect}
                  placeholder="Select"
                  options={options}
                  onChanged={handleTemplateChange}
                  displayEmpty
                  value={selectedTemplate}
                />
              </ProductSelector>
            </Grid>
            <Grid item>
              <OptionalTooltip disabled={noFacetsSelected}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  disabled={!canSubmit}
                  onClick={handleSubmit}
                >
                  Generate Product List
                </Button>
              </OptionalTooltip>
            </Grid>
          </Grid>
        </Paper>
      </TableTabPanel>
    </Root>
  );
};

export default ProductListGenerator;
