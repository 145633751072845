import React, {FC, useState, useEffect, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import * as R from 'ramda';
import {Configure, InstantSearch} from 'react-instantsearch-dom';
import Consts from '../../../../app/Consts';
import {QueryCriteria, Facet} from '../../../../types';
import {defaultCriteria, departmentFacet, supplierFacet} from './Consts';
import {Criteria, Actions, Dialog} from './Sections';
import searchClient from '../../../../utils/algoliaSearchClient';
import {DialogProps} from '@mui/material/Dialog';
import {useAppDispatch, useAppSelector} from '../../../../app/store';
import {selectCriteria} from '../../../../app/selectors/criteria';
import {resetCriteria, setCriteria} from '../../../../app/criteriaReducer';
import SelectorCloseConfirmation from '../../../Modal/SelectorCloseConfirmation';

const ContentContainer = styled('div')`
  padding: 1rem 2.625rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  flex: 1 1 auto;
  overflow-y: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

type Props = {
  open: boolean;
  productCriteria?: QueryCriteria | null;
  defaultBuyerDepartments?: any[];
  defaultSuppliers?: any[];
  handleClose: () => void;
  onApplyCriteria?: (productCriteria: QueryCriteria | null) => void;
  scrollType?: 'paper' | 'body';
  readOnly?: boolean;
} & DialogProps;

const ProductSelectorWithDialog: FC<Props> = ({
  productCriteria,
  readOnly = false,
  onApplyCriteria,
  open,
  defaultBuyerDepartments,
  defaultSuppliers,
  handleClose,
}) => {
  const loadRef = useRef(false);
  const dispatch = useAppDispatch();
  const criteria = useAppSelector(selectCriteria);
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (!loadRef.current) {
      if (productCriteria) {
        dispatch(setCriteria(productCriteria));
      } else {
        const criteria = {...defaultCriteria};
        const supplierFacetInclusion = criteria.facetInclusions.find(
          (x) => x.name === supplierFacet
        );
        const departmentFacetInclusion = criteria.facetInclusions.find(
          (x: Facet) => x.name === departmentFacet
        );

        const inclusionSuppliers = (defaultSuppliers ?? []).map((x) => `${x.number}--${x.name}`);
        const inclusionDepartments = (defaultBuyerDepartments ?? []).map(
          (x) => `${x.number}--${x.description}`
        );

        const newCriteria = {
          ...criteria,
          facetInclusions: [
            {...supplierFacetInclusion, values: inclusionSuppliers},
            {...departmentFacetInclusion, values: inclusionDepartments},
          ],
        };
        dispatch(setCriteria(newCriteria));
      }
    }
    loadRef.current = true;
  }, [productCriteria, defaultSuppliers, defaultBuyerDepartments, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCriteria());
    };
  }, [dispatch]);

  const closeSelector = () => {
    if (readOnly) {
      handleClose?.();
      return;
    }
    const hasInclusions = R.any(
      (facet) => !R.isEmpty(facet.values),
      criteria.facetInclusions || []
    );
    const hasExclusions = R.any(
      (facet) => !R.isEmpty(facet.values),
      criteria.facetExclusions || []
    );

    if (
      R.equals(productCriteria, criteria) ||
      (!productCriteria && !hasInclusions && !hasExclusions)
    ) {
      handleClose?.();
    } else {
      setCloseConfirmModalOpen(true);
    }
  };

  const handleApplyCriteria = () => {
    onApplyCriteria?.(criteria);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={Consts.AlgoliaIndex.Products}>
      <Configure filters={criteria?.filters} highlightPreTag="p" highlightPostTag="p" />
      <Dialog open={open} onClose={closeSelector}>
        <Divider />
        <ContentContainer>
          <Criteria readOnly={readOnly} />
        </ContentContainer>
        <Divider />
        <Actions onApplyCriteria={handleApplyCriteria} readOnly={readOnly} />
      </Dialog>
      <SelectorCloseConfirmation
        open={closeConfirmModalOpen}
        onCancel={() => setCloseConfirmModalOpen(false)}
        onOk={() => handleClose?.()}
      />
    </InstantSearch>
  );
};

export default ProductSelectorWithDialog;
