import React, {FC, useState, useEffect, useRef} from 'react';
import Consts from '../../app/Consts';
import {ProductOverlap, Pagination, TableColumn, OverlapDisplayType} from '../../types';
import {formatDate} from '../../utils/DateUtils';
import {SimpleDataTable} from '../SimpleDataTable';

type Props = {
  data?: ProductOverlap[];
  textPrefix: OverlapDisplayType;
};

const BulkUploadSKUOverlapTable: FC<Props> = ({data, textPrefix}) => {
  const isInitialMount = useRef(true);
  const [pagination, setPagination] = useState({
    ...Consts.DefaultPagination,
    pageSize: 10,
  });
  const [rows, setRows] = useState<ProductOverlap[]>([]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (Array.isArray(data) && data.length > 0) {
        setRows(data.slice(0, pagination.pageSize));
        setPagination({
          ...Consts.DefaultPagination,
          totalCount: data.length,
          pageSize: pagination.pageSize,
          totalPages: Math.ceil(data.length / pagination.pageSize),
        });
      }
    }
  }, [data, pagination.pageSize]);

  const handlePagination = (newPagination: Partial<Pagination>) => {
    const nextPagination = {
      ...pagination,
      ...newPagination,
    };
    setPagination({
      ...nextPagination,
      totalPages: Math.ceil(nextPagination.totalCount / nextPagination.pageSize),
    });
    setRows(
      data?.slice(
        (nextPagination.currentPage - 1) * nextPagination.pageSize,
        nextPagination.currentPage * nextPagination.pageSize
      ) || []
    );
  };

  const columns: TableColumn<ProductOverlap>[] = [
    {
      id: 'productCode',
      label: 'Overlapping SKU',
    },
    {
      id: 'id',
      label: `${textPrefix} Id`,
    },
    {
      id: 'description',
      label: `${textPrefix} Description`,
    },
    {
      id: 'ownedByStaffName',
      label: 'Owner',
      render: (row) => row.ownedByStaffName,
    },
    {
      id: 'departments',
      label: 'Department',
      render: (row) => row.departments?.join(', '),
      // Dept only relevant for M&M
      hide: textPrefix !== OverlapDisplayType.MixAndMatch,
    },
    {
      id: 'startAt',
      label: 'Start Date',
      render: (row) => formatDate(row.startAt),
    },
    {
      id: 'endAt',
      label: 'End Date',
      render: (row) => formatDate(row.endAt),
    },
  ];
  const displayedColumns = columns.filter((x) => !x.hide);
  return (
    <SimpleDataTable
      columns={displayedColumns}
      rows={rows}
      pagination={pagination}
      onChangePage={handlePagination}
      onChangePageSize={handlePagination}
      hideRowSpacer
    />
  );
};

export default BulkUploadSKUOverlapTable;
