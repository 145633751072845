import React, {FC, useEffect, useMemo} from 'react';
import {useFormikContext, FieldArray, FieldArrayRenderProps} from 'formik';
import {styled} from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputField from '../../../components/Form/InputField';
import Consts from '../../../app/Consts';
import {LinkButton, StaticButton} from '../../../components/Button';
import {ErrorBox} from '../../Alert';
import AccountFieldLabel from './AccountFieldLabel';
import AmountField from '../AmountField';
import {getFixedValue} from '../../../utils/AmountUtils';
import {AccountOption, FinanceDetails, SplitAccount} from '../../../types';

const PREFIX = 'FinanceAccount';

const classes = {
  splitTypeUnit: `${PREFIX}-splitTypeUnit`,
};

const Root = styled('div')(() => ({
  [`& .${classes.splitTypeUnit}`]: {
    marginRight: '-1px',
  },
}));

const AccountGrid = styled(Grid)`
  margin-bottom: 1.25rem;
`;

type Props = {
  name: string;
  canSplit: boolean;
  totalAmount: number;
  agreementType: string;
  accountOptions: AccountOption[];
  setDefaultIfEmpty?: boolean;
  disabled?: boolean;
};

interface FormErrors {
  splitAccounts?: Array<{
    accountId?: string;
    amount?: string;
  }>;
}

interface FormTouched {
  splitAccounts?: Array<{
    accountId?: boolean;
    amount?: boolean;
  }>;
}

const FinanceAccount: FC<Props> = ({
  name,
  canSplit,
  totalAmount,
  agreementType,
  accountOptions = [],
  setDefaultIfEmpty = true,
  disabled = false,
}) => {
  const bag = useFormikContext<FinanceDetails>();
  const {values, errors, touched, setFieldValue, getFieldMeta} = bag;
  const splitAccounts = useMemo(
    () => (values[name as keyof FinanceDetails] as SplitAccount[] | undefined) ?? [],
    [name, values]
  );
  const splitAccountAmountType = values.splitAccountAmountType;
  //if there is only one account, for contract agreement rebate the total percentage will be the same as totalAmount
  const singleAccountPercentageTotal =
    agreementType === Consts.AgreementTypeEnum.Deal ? 100 : totalAmount * 1;
  const splitAccountsMeta = getFieldMeta(name);
  const options = accountOptions.map(({name, id, ...rest}) => ({
    ...rest,
    label: name,
    value: id,
  }));

  // add default finance account if no finance account
  useEffect(() => {
    if (splitAccounts.length === 0) {
      const defaultOption =
        setDefaultIfEmpty && accountOptions && accountOptions.length > 0
          ? accountOptions.find((x) => x.isDefault)
          : undefined;
      setFieldValue(name, [
        {
          accountId: defaultOption && defaultOption.id,
          accountName: defaultOption && defaultOption.name,
          amount: singleAccountPercentageTotal,
          hasAdditionalField: defaultOption && defaultOption.hasAdditionalField,
          additionalFieldName: defaultOption && defaultOption.additionalFieldName,
          additionalInfo: (defaultOption && defaultOption.additionalFieldName) || '',
        },
      ]);
    }
  }, [
    splitAccounts,
    accountOptions,
    setFieldValue,
    name,
    singleAccountPercentageTotal,
    setDefaultIfEmpty,
  ]);

  //update the first account amount based on others' amount
  useEffect(() => {
    //contract agreement rebate always allow split
    const shouldSplit =
      agreementType === Consts.AgreementTypeEnum.ContractAgreement ||
      splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Value;
    if (shouldSplit) {
      if (splitAccounts.length > 1) {
        if (splitAccounts.filter((x: SplitAccount) => isNaN(Number(x.amount))).length > 0) {
          return;
        }
        let totalSum = Number(
          getFixedValue(
            splitAccounts
              .map((x: SplitAccount) => x.amount)
              .reduce((a: number, b: number) => a * 1 + b * 1, 0)
          )
        );
        let difference = Number(getFixedValue(totalAmount * 1 - totalSum));
        if (difference !== 0) {
          setFieldValue(
            `${name}.0.amount`,
            getFixedValue(Number(splitAccounts[0].amount) * 1 + difference)
          );
        }
      }
    }
  }, [name, totalAmount, setFieldValue, splitAccounts, splitAccountAmountType, agreementType]);

  function getAdditionalInfo(splitAccount: SplitAccount, index: number) {
    return splitAccount.hasAdditionalField ? (
      <InputField
        fullWidth
        name={`${name}.${index}.additionalInfo`}
        placeholder={`${splitAccount.additionalFieldName}(Optional)`}
      />
    ) : null;
  }

  function getAccountSelect(splitAccount: SplitAccount, index: number) {
    const typedErrors = errors as FormErrors;
    const typedTouched = touched as unknown as FormTouched;

    const hasAccountError =
      typedErrors.splitAccounts &&
      typedErrors.splitAccounts[index] &&
      typedErrors.splitAccounts[index].accountId &&
      typedTouched.splitAccounts &&
      typedTouched.splitAccounts[index] &&
      typedTouched.splitAccounts[index].accountId;

    return (
      <FormControl variant="outlined" fullWidth error={!!hasAccountError}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          value={
            splitAccount.accountId && options.length > 0
              ? {
                  value: splitAccount.accountId,
                  label: splitAccount.accountName || '',
                  isDefault: false,
                  hasAdditionalField: !!splitAccount.hasAdditionalField,
                  additionalFieldName: splitAccount.additionalFieldName || null,
                }
              : null
          }
          popupIcon={<ExpandMore />}
          autoHighlight
          fullWidth
          onChange={(event, option, reason) => {
            setFieldValue(`${name}.${index}.accountId`, option ? option.value : '');
            setFieldValue(`${name}.${index}.accountName`, option ? option.label : '');
            setFieldValue(
              `${name}.${index}.hasAdditionalField`,
              option && option.hasAdditionalField
            );
            setFieldValue(
              `${name}.${index}.additionalFieldName`,
              option ? option.additionalFieldName : ''
            );

            if (!option || !option.hasAdditionalField) {
              setFieldValue(`${name}.${index}.additionalInfo`, '');
              setFieldValue(`${name}.${index}.additionalFieldName`, '');
            }
          }}
          isOptionEqualToValue={(option, value) => {
            return option && option.value === value.value;
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant="outlined"
                name={`${name}.${index}.accountName`}
                {...params}
                error={!!hasAccountError}
              />
            );
          }}
          disabled={disabled}
        />
        {hasAccountError && typedErrors.splitAccounts && typedErrors.splitAccounts[index] ? (
          <FormHelperText id={`${name}.${index}.account-helper-text`}>
            {typedErrors.splitAccounts[index].accountId}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }

  function getAmountField(index: number) {
    return (
      <Grid container>
        <TextField
          variant="outlined"
          disabled
          sx={{flex: '0 0 50px'}}
          InputProps={{
            classes: {
              notchedOutline: classes.splitTypeUnit,
            },
          }}
          defaultValue={
            splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Value ? '$' : '%'
          }
        />
        <AmountField
          sx={{flex: '1 1'}}
          name={`${name}.${index}.amount`}
          id={`${name}.${index}.amount`}
          disabled={index === 0}
        ></AmountField>
      </Grid>
    );
  }

  function removeAccount(arrayHelpers: FieldArrayRenderProps, index: number) {
    if (splitAccounts.length === 2) {
      const firstAccount = splitAccounts[0];
      arrayHelpers.replace(0, {
        ...firstAccount,
        amount: singleAccountPercentageTotal,
      });
      //for deal if there is only one finance account, the split amount will be 100, and the slplit amount type will be Percentage
      if (agreementType === Consts.AgreementTypeEnum.Deal) {
        setFieldValue('splitAccountAmountType', Consts.SplitAccountAmountTypeEnum.Percentage);
      }
    }
    arrayHelpers.remove(index);
  }

  function addAccount(arrayHelpers: FieldArrayRenderProps) {
    //update the split account amount type for deal if previous one account
    if (
      agreementType === Consts.AgreementTypeEnum.Deal &&
      splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Percentage
    ) {
      const firstAccount = splitAccounts[0];
      arrayHelpers.replace(0, {
        ...firstAccount,
        amount: totalAmount,
      });
      setFieldValue('splitAccountAmountType', Consts.SplitAccountAmountTypeEnum.Value);
    }

    arrayHelpers.push({
      accountId: undefined,
      accountName: undefined,
      amount: 0,
      hasAdditionalField: false,
      additionalInfo: '',
    });
  }

  function getAccountLabel(arrayHelpers: FieldArrayRenderProps, index: number) {
    return (
      <AccountFieldLabel>
        Finance Account {index + 1}
        {index !== 0 && (
          <LinkButton
            type="button"
            onClick={() => {
              removeAccount(arrayHelpers, index);
            }}
          >
            Remove
          </LinkButton>
        )}
      </AccountFieldLabel>
    );
  }

  function getAddAcountButton(arrayHelpers: FieldArrayRenderProps) {
    return (
      <StaticButton
        style={{padding: 0, height: 'unset'}}
        type="button"
        disabled={disabled}
        onClick={() => {
          addAccount(arrayHelpers);
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        {splitAccounts.length > 1
          ? 'Add another finance account'
          : 'Split across multiple finance accounts'}
      </StaticButton>
    );
  }

  return (
    <Root>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div>
            {splitAccounts &&
              splitAccounts.length > 0 &&
              splitAccounts.map((splitAccount, index) => (
                <div key={index}>
                  {splitAccounts.length > 1 && getAccountLabel(arrayHelpers, index)}
                  {splitAccounts.length > 1 ? (
                    <AccountGrid container spacing={1}>
                      <Grid item xs={6}>
                        {getAccountSelect(splitAccount, index)}
                      </Grid>
                      <Grid item xs={6}>
                        {getAmountField(index)}
                      </Grid>
                      <Grid item xs={12}>
                        {getAdditionalInfo(splitAccount, index)}
                      </Grid>
                    </AccountGrid>
                  ) : (
                    <AccountGrid container spacing={1}>
                      <Grid item xs={12}>
                        {getAccountSelect(splitAccount, index)}
                      </Grid>
                      <Grid item xs={12}>
                        {getAdditionalInfo(splitAccount, index)}
                      </Grid>
                    </AccountGrid>
                  )}
                </div>
              ))}
            {canSplit && getAddAcountButton(arrayHelpers)}
          </div>
        )}
      />
      {!!splitAccountsMeta.error &&
      typeof splitAccountsMeta.error === 'string' &&
      !!splitAccountsMeta.touched ? (
        <Grid item xs={12}>
          <ErrorBox>{splitAccountsMeta.error}</ErrorBox>
        </Grid>
      ) : null}
    </Root>
  );
};

export default FinanceAccount;
