import React, {FC, useEffect, useState, useCallback, useMemo} from 'react';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {useAppSelector} from '../../app/store';
import {selectUserAllowedToBulkUploadEditRebateClaims} from '../../app/selectors';
import {api, get, put} from '../../utils/Request';
import {ClaimDetailsViewModel, EditClaimAmountRequest} from '../../types';
import {Amount} from '../Amount';
import useDelayLoading from '../Hook/useDelayLoading';
import Spinner from '../Spinner/Spinner';
import HeaderWithGst from '../Table/HeaderWithGst';
import EditableField from '../EditableField';
import ClaimPeriodView, {CellMode} from './ClaimPeriodView';
import EditableAmount from './EditableAmount';
import EditableApprovalNumber from './EditableApprovalNumber';
import EditDealConfirmModal from './EditDealConfirmModal';
import AdjustClaimButton from './AdjustClaimButton';

const CurrentPeriodTitle = 'Claim Period';
const TotalPeriodTitle = 'Total';
export const detailedCellWidth = 12.5;

const ClaimAdjButtonContainer = styled('div')`
  padding: 1rem 0 0 ${detailedCellWidth}rem;
  button {
    margin-left: 0;
  }
`;

const WrappedText = styled('div')`
  overflow-wrap: break-word;
`;
type HeaderProps = {
  header: React.ReactNode | null;
  data: ClaimDetailsViewModel;
};

export const HeaderWithGstFromData: FC<HeaderProps> = ({header, data}) => (
  <HeaderWithGst
    header={header}
    gstType={
      data.totalClaimAmount !== 0 && data.claimTaxAmount === 0
        ? Consts.GstTypeEnum.Free
        : data.claimTaxAmount !== 0
        ? Consts.GstTypeEnum.Inclusive
        : Consts.GstTypeEnum.Exclusive
    }
  />
);

const getTypeDetails = (rowData: ClaimDetailsViewModel) => {
  switch (rowData.agreementSubType) {
    case Consts.AgreementSubTypeEnum.Spiv:
      return {
        routerPath:
          Consts.RouterPath.EditSpiv.replace(':id', `${rowData.agreementId}`) +
          '?fromClaim=' +
          rowData.id,
        label: 'Edit SPIV',
      };
    case Consts.AgreementSubTypeEnum.MixAndMatch:
      return {
        routerPath:
          Consts.RouterPath.EditMixAndMatch.replace(':id', `${rowData.mixAndMatchId}`) +
          '?fromClaim=' +
          rowData.id,
        label: 'Edit Mix & Match',
      };
    default:
      return {
        routerPath:
          Consts.RouterPath.EditDeal.replace(':id', `${rowData.agreementId}`) +
          '?fromClaim=' +
          rowData.id,
        label: 'Edit Deal',
      };
  }
};

type Props = {
  id: number;
  status: string;
  onClaimUpdated: (claim: any) => void;
};

const ClaimExpandedView: FC<Props> = ({id, status, onClaimUpdated}) => {
  const [data, setData] = useState<ClaimDetailsViewModel | null>(null);
  const [editDealLabelText, setEditDealLabelText] = useState('');
  const [editDealRouterPath, setEditDealRouterPath] = useState('');
  const [loading, setLoading] = useDelayLoading(false);
  const [openEditDealForm, setOpenEditDealForm] = useState(false);

  const isBulkUploadRebateClaimsAllowed = useAppSelector(
    selectUserAllowedToBulkUploadEditRebateClaims
  );

  const handleFetchData = useCallback(() => {
    setLoading(true);
    get(api(Consts.Api.ClaimDetails.replace(':id', `${id}`)), {})
      .then((response) => {
        setLoading(false);
        setData(response.data);
        // update claim amount in parent list item
        onClaimUpdated({...response.data});
      })
      .catch((error) => {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [id, setLoading, onClaimUpdated]);

  useEffect(() => {
    if (data) {
      const {routerPath, label} = getTypeDetails(data);
      if (routerPath && label) {
        setEditDealLabelText(label);
        setEditDealRouterPath(routerPath);
      }
    }
  }, [data]);

  useEffect(() => {
    setLoading(true);
    get(api(Consts.Api.ClaimDetails.replace(':id', `${id}`)), {})
      .then((response: AxiosResponse<ClaimDetailsViewModel>) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [id, setLoading]);

  const isAdjustment = Boolean(data?.parentClaimId);
  const showClaimAdjustmentButton = useMemo(
    () =>
      [
        isBulkUploadRebateClaimsAllowed,
        status === Consts.ClaimStatusEnum.Raised || status === Consts.ClaimStatusEnum.NoClaimRaised,
        !isAdjustment,
        data?.agreementType === 'ContractAgreement',
      ].every(Boolean),
    [isBulkUploadRebateClaimsAllowed, status, isAdjustment, data]
  );

  /**
   * where request body props differ from local state props
   *      local       |       request
  /*  approvalNumber     rebateApprovalNumber
  /*  claimComment       comment
 */
  const mapRequestToLocalData = ({
    rebateApprovalNumber,
    comment,
    ...rest
  }: Partial<EditClaimAmountRequest>) => ({
    ...rest,
    ...(rebateApprovalNumber && {approvalNumber: rebateApprovalNumber}),
    ...(comment && {claimComment: comment}),
  });

  const handleClaimUpdate = useCallback(
    async (claimUpdate: Partial<EditClaimAmountRequest>, onSuccessCallback?: () => void) => {
      if (!data) {
        return;
      }
      const requestData = {
        ...(claimUpdate ?? {}),
      };
      setLoading(true);
      try {
        await put(api(Consts.Api.ClaimDetails.replace(':id', `${data.id}`)), requestData);
        setLoading(false);
        alertService.clear(defaultAlertId);
        setData({...data, ...mapRequestToLocalData(claimUpdate)});
        onSuccessCallback?.();
      } catch (error: any) {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      }
    },
    [data, setLoading]
  );

  const handleApprovalNumberUpdate = useCallback(
    async (rebateApprovalNumber: string) => {
      await handleClaimUpdate({rebateApprovalNumber}, () =>
        onClaimUpdated({...data, ...mapRequestToLocalData({rebateApprovalNumber})})
      );
    },
    [handleClaimUpdate, onClaimUpdated, data]
  );

  const handleCommentUpdate = useCallback(
    async (comment?: string) => {
      await handleClaimUpdate({comment: comment ?? ''});
    },
    [handleClaimUpdate]
  );

  const handleExTaxClaimAmountUpdate = useCallback(
    async (claimAmountTaxExcluded: number) => {
      // need to refetch claim detail to get updated gst amounts not calculated locally
      await handleClaimUpdate({claimAmountTaxExcluded}, handleFetchData);
    },
    [handleClaimUpdate, handleFetchData]
  );

  const renderPeriodView = useCallback(
    (data: ClaimDetailsViewModel) => {
      const renderFixedAmountPeriodView = (data: ClaimDetailsViewModel) => {
        const rows = [
          {
            startAt: data.startAt,
            endAt: data.endAt,
            title: CurrentPeriodTitle,
            columns: [
              {
                label: '',
                colSpan: 6,
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Claim Amount" data={data} />,
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable ? (
                    <EditableAmount
                      value={data.totalClaimAmount}
                      buttonLabel="Edit Deal"
                      onEdit={() => setOpenEditDealForm(true)}
                    />
                  ) : (
                    <Amount value={data.totalClaimAmount} />
                  );
                },
                colSpan: 2,
              },
              {
                label: '',
                colSpan: 2,
                mode: CellMode.Calculated,
              },
            ],
          },
        ];
        return <ClaimPeriodView periods={rows} data={data} fixedTableLayout />;
      };
      const renderSaleDealPeriodView = (data: ClaimDetailsViewModel) => {
        const rows = [
          {
            startAt: data.startAt,
            endAt: data.endAt,
            title: CurrentPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.costTaxExcludedInPeriod} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Accrual" data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.accrualAmount} />,
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Claim Amount" data={data} />,
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable ? (
                    <EditableAmount
                      value={data.totalClaimAmount}
                      buttonLabel="Edit Deal"
                      onEdit={() => setOpenEditDealForm(true)}
                    />
                  ) : (
                    <Amount value={data.totalClaimAmount} />
                  );
                },
                colSpan: 2,
              },
              {
                label: 'Accrued vs claim ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.accrualAmountTaxExcluded - data.claimAmountTaxExcluded} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: 'Previously unclaimed amount',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.previouslyUnclaimedAmount} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
            ],
          },
          {
            startAt: data.startAtOfFirstClaim,
            endAt: data.endAt,
            title: TotalPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalCostTaxExcluded} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Accrual" data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.totalAccrualAmount} />,
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: 'Claimed to date ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.claimedAmountToDateTaxExcluded} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: 'Available to claim ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.availableToClaimTaxExcluded} />
                ),
                colSpan: 4,
                mode: CellMode.Fixed,
              },
            ],
          },
        ];
        return <ClaimPeriodView periods={rows} data={data} fixedTableLayout />;
      };
      const renderPurchasesDealPeriodView = (data: ClaimDetailsViewModel) => {
        const rows = [
          {
            startAt: data.startAt,
            endAt: data.endAt,
            title: CurrentPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.costTaxExcludedInPeriod} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },

              {
                label: <HeaderWithGstFromData header="Accrual" data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.accrualAmount} />,
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Claim Amount" data={data} />,
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable ? (
                    <EditableAmount
                      value={data.totalClaimAmount}
                      buttonLabel={editDealLabelText}
                      onEdit={() => setOpenEditDealForm(true)}
                    />
                  ) : (
                    <Amount value={data.totalClaimAmount} />
                  );
                },
                colSpan: 2,
              },
              {
                label: 'Accrued vs claim ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.accrualAmountTaxExcluded - data.claimAmountTaxExcluded} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: 'Previously unclaimed amount',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.previouslyUnclaimedAmount} />
                ),
                colSpan: 2,
                mode: CellMode.Fixed,
              },
            ],
          },
          {
            startAt: data.startAtOfFirstClaim,
            endAt: data.endAt,
            title: TotalPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                colSpan: 2,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalCostTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header={'Adjustments'} gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                colSpan: 2,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalAdjustmentAmount} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header={'Accrual'} data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.totalAccrualAmount} />,
                colSpan: 2,
                mode: CellMode.Fixed,
              },
              {
                label: 'Claimed to date ex.',
                colSpan: 2,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.claimedAmountToDateTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: 'Available to claim ex.',
                colSpan: 2,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.availableToClaimTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
            ],
          },
        ];
        return <ClaimPeriodView periods={rows} data={data} fixedTableLayout />;
      };
      const renderRebatePeriodView = (data: ClaimDetailsViewModel) => {
        const rows = [
          {
            startAt: data.startAt,
            endAt: data.endAt,
            title: CurrentPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.costTaxExcludedInPeriod} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Write Backs" gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.writebacksAmountInPeriod} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGst header="Adjustment" gstType={Consts.GstTypeEnum.Exclusive} />,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.adjustmentAmountInPeriod} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Net Purchases" gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.netCostTaxExcludedInPeriod} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Accrual" data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.accrualAmount} />,
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Supplier Amount" gstType={Consts.GstTypeEnum.Inclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable ? (
                    <EditableAmount
                      value={data.supplierAmount}
                      onUpdate={(value) => {
                        setData({...data, supplierAmount: value});
                      }}
                      onSave={async () => {
                        let requestData = {
                          supplierAmount: data.supplierAmount,
                          claimAmountTaxExcluded: data.claimAmountTaxExcluded,
                        };
                        setLoading(true);
                        try {
                          await put(
                            api(Consts.Api.ClaimDetails.replace(':id', `${data.id}`)),
                            requestData
                          );
                          setLoading(false);
                          alertService.clear(defaultAlertId);
                        } catch (error: any) {
                          setLoading(false);
                          alertService.alert({
                            ...{message: error.message, response: error.response},
                            id: defaultAlertId,
                          });
                        }
                      }}
                    />
                  ) : data.supplierAmount === null ? (
                    '-'
                  ) : (
                    <Amount value={data.supplierAmount} />
                  );
                },
                mode: CellMode.Editable,
              },
              {
                label: (
                  <HeaderWithGst
                    header="Total Claim Amount"
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable ? (
                    <EditableAmount
                      value={data.claimAmountTaxExcluded}
                      onUpdate={(value) => {
                        setData({...data, claimAmountTaxExcluded: value ?? 0});
                      }}
                      onSave={handleExTaxClaimAmountUpdate}
                    />
                  ) : (
                    <Amount value={data.claimAmountTaxExcluded} />
                  );
                },
                mode: CellMode.Editable,
              },
              {
                label: (
                  <HeaderWithGst
                    header="Total Claim Amount"
                    gstType={Consts.GstTypeEnum.Inclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => <Amount value={data.totalClaimAmount} />,
                mode: CellMode.Fixed,
              },
              {
                label: 'Claim Tax Amount',
                render: (data: ClaimDetailsViewModel) => <Amount value={data.claimTaxAmount} />,
                mode: CellMode.Fixed,
              },
              {
                label: 'Accrued vs claim ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.accrualAmountTaxExcluded - data.claimAmountTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
            ],
          },
          {
            startAt: data.startAtOfFirstClaim,
            endAt: data.endAt,
            title: TotalPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst
                    header={data.rebateType === Consts.RebateTypeEnum.Sales ? 'Sales' : 'Purchases'}
                    gstType={Consts.GstTypeEnum.Exclusive}
                  />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalCostTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Write Backs" gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalWritebacksAmount} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGst header="Adjustment" gstType={Consts.GstTypeEnum.Exclusive} />,
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalAdjustmentAmount} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Net Purchases" gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.totalNetCostTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: <HeaderWithGstFromData header="Accrual" data={data} />,
                render: (data: ClaimDetailsViewModel) => <Amount value={data.totalAccrualAmount} />,
                mode: CellMode.Fixed,
              },
              {
                label: (
                  <HeaderWithGst header="Supplier Amount" gstType={Consts.GstTypeEnum.Inclusive} />
                ),
                render: (data: ClaimDetailsViewModel) =>
                  data.totalSupplierAmount ? <Amount value={data.totalSupplierAmount} /> : '-',
                mode: CellMode.Fixed,
              },
              {
                label: 'Claimed to date ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.claimedAmountToDateTaxExcluded} />
                ),
                mode: CellMode.Fixed,
              },
              {
                label: 'Available to claim ex.',
                render: (data: ClaimDetailsViewModel) => (
                  <Amount value={data.availableToClaimTaxExcluded} />
                ),
                mode: CellMode.Fixed,
                colSpan: 3,
              },
            ],
          },
        ];
        return (
          <ClaimPeriodView
            periods={rows}
            data={data}
            onRebateApprovalNumberUpdated={handleApprovalNumberUpdate}
          />
        );
      };
      const renderClaimAdjustmentPeriodView = (data: ClaimDetailsViewModel) => {
        const rows = [
          {
            startAt: data.startAt,
            endAt: data.endAt,
            title: CurrentPeriodTitle,
            columns: [
              {
                label: (
                  <HeaderWithGst header="Claim Amount" gstType={Consts.GstTypeEnum.Exclusive} />
                ),
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable && isBulkUploadRebateClaimsAllowed ? (
                    <EditableAmount
                      value={data.claimAmountTaxExcluded}
                      buttonLabel="Edit amount"
                      // onSave uses state in this component because
                      // a modal is used in some cases to update claim amount
                      onUpdate={(value) => {
                        setData({...data, claimAmountTaxExcluded: value ?? 0});
                      }}
                      onSave={handleExTaxClaimAmountUpdate}
                    />
                  ) : (
                    <Amount value={data.claimAmountTaxExcluded} />
                  );
                },
                colSpan: 3,
                mode: CellMode.Fixed,
              },
              {
                label: 'Approval Number:',
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable && isBulkUploadRebateClaimsAllowed ? (
                    <EditableApprovalNumber
                      isEditable
                      approvalNumber={data.approvalNumber}
                      onApprovalNumberUpdated={handleApprovalNumberUpdate}
                    />
                  ) : (
                    <WrappedText>{data.approvalNumber ?? ''}</WrappedText>
                  );
                },
                colSpan: 3,
                mode: CellMode.Fixed,
              },
              {
                label: 'Comments:',
                render: (data: ClaimDetailsViewModel) => {
                  return data.isEditable && isBulkUploadRebateClaimsAllowed ? (
                    <EditableField
                      readOnly={false}
                      value={data.claimComment ?? undefined}
                      onSave={handleCommentUpdate}
                      multiline
                      maxRows={10}
                      title="" // mt title aligns actions to start
                    />
                  ) : (
                    <WrappedText>{data.claimComment ?? ''}</WrappedText>
                  );
                },
                colSpan: 4,
                mode: CellMode.Fixed,
              },
            ],
          },
        ];
        return <ClaimPeriodView periods={rows} data={data} fixedTableLayout />;
      };
      if (isAdjustment) {
        return renderClaimAdjustmentPeriodView(data);
      } else if (data.agreementType === Consts.AgreementTypeEnum.ContractAgreement) {
        return renderRebatePeriodView(data);
      } else if (data.rebateType === Consts.RebateTypeEnum.Sales) {
        return renderSaleDealPeriodView(data);
      } else if (data.rebateType === Consts.RebateTypeEnum.Purchases) {
        return renderPurchasesDealPeriodView(data);
      }
      return renderFixedAmountPeriodView(data);
    },
    [
      editDealLabelText,
      handleApprovalNumberUpdate,
      handleExTaxClaimAmountUpdate,
      handleCommentUpdate,
      isAdjustment,
      isBulkUploadRebateClaimsAllowed,
      setLoading,
      setOpenEditDealForm,
    ]
  );

  return (
    <Grid
      sx={{
        padding: '0 1rem',
        position: 'relative',
        minHeight: '6.25rem',
      }}
    >
      <Spinner loading={loading} />
      {data ? (
        <>
          {renderPeriodView(data)}
          {showClaimAdjustmentButton ? (
            <ClaimAdjButtonContainer>
              <AdjustClaimButton id={`${id}`} />
            </ClaimAdjButtonContainer>
          ) : null}
          <EditDealConfirmModal
            open={openEditDealForm}
            onOk={() => {
              setOpenEditDealForm(false);
              window.open(editDealRouterPath, '_blank');
            }}
            onCancel={() => {
              setOpenEditDealForm(false);
            }}
            agreementSubType={data.agreementSubType}
          />
        </>
      ) : null}
    </Grid>
  );
};

export default ClaimExpandedView;
