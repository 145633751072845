import React, {FC, useState} from 'react';
import Consts from '../../app/Consts';
import {BulkUploadLine, OverlapDisplayType, ProductOverlap} from '../../types';
import {WarnIcon} from '../Icons';
import {ConfirmModal, BulkUploadSKUOverlapTable} from '.';
import ErrorSection, {ErrorList, ErrorListItem} from './ErrorSection';

const styles = {
  okButton: {
    width: '21.25rem',
  },
  cancelButton: {
    width: '21.25rem',
  },
  content: {
    width: '60rem',
  },
  root: {
    width: 'fit-content',
  },
};

export type BaseBulkUploadInfoModalProps = {
  open: boolean;
  onClose: () => void;
  onReupload: () => void;
  lineWarnings?: BulkUploadLine[];
  skuOverlaps?: ProductOverlap[];
  modalStyles?: Record<string, any>;
  textPrefix?: OverlapDisplayType;
};

const BaseBulkUploadInfoModal: FC<BaseBulkUploadInfoModalProps> = ({
  open,
  onClose,
  onReupload,
  lineWarnings = [],
  skuOverlaps = [],
  modalStyles,
  textPrefix,
}) => {
  const [currentError, setCurrentError] = useState(Consts.ProductBulkUploadErrorType.None);

  if (lineWarnings.length === 0 && skuOverlaps.length === 0) {
    return null;
  }

  const renderSubtitle = () => (
    <div>Please review the below information and choose how to proceed.</div>
  );

  const renderLineWarnings = () => {
    if (lineWarnings.length > 0) {
      const count = lineWarnings.length;
      const expanded = currentError === Consts.ProductBulkUploadErrorType.LineWarnings;
      return (
        <ErrorSection
          title={`${count} ${count > 1 ? 'lines have warnings' : 'line has warnings'}`}
          toggleButtonText="View Warnings"
          expanded={expanded}
          onToggle={() => {
            setCurrentError(
              expanded
                ? Consts.ProductBulkUploadErrorType.None
                : Consts.ProductBulkUploadErrorType.LineWarnings
            );
          }}
          sectionIcon={WarnIcon}
        >
          {expanded ? (
            <ErrorList>
              {lineWarnings.map((lineError: BulkUploadLine) => {
                let message = lineError.warningMessages.join(', ');
                return (
                  <ErrorListItem key={lineError.line}>
                    Line {lineError.line} - {message}
                  </ErrorListItem>
                );
              })}
            </ErrorList>
          ) : null}
        </ErrorSection>
      );
    }
    return null;
  };

  const renderSKUOverlap = () => {
    if (skuOverlaps.length > 0) {
      const count = skuOverlaps.length;
      const expanded = currentError === Consts.ProductBulkUploadErrorType.SKUOverlap;
      return (
        <ErrorSection
          title={`${count} ${count > 1 ? 'lines have SKU overlaps' : 'line has SKU overlaps'}`}
          toggleButtonText="View SKU Overlaps"
          expanded={expanded}
          onToggle={() => {
            setCurrentError(
              expanded
                ? Consts.ProductBulkUploadErrorType.None
                : Consts.ProductBulkUploadErrorType.SKUOverlap
            );
          }}
          sectionIcon={WarnIcon}
        >
          {expanded ? (
            <BulkUploadSKUOverlapTable
              data={skuOverlaps}
              textPrefix={textPrefix ?? OverlapDisplayType.Deal}
            />
          ) : null}
        </ErrorSection>
      );
    }
    return null;
  };

  return (
    <ConfirmModal
      title="Upload Information"
      open={open}
      onOk={onClose}
      onCancel={onReupload}
      okText="Continue with current upload"
      cancelText="Upload Again"
      renderSubtitle={renderSubtitle}
      styles={modalStyles || styles}
    >
      {renderLineWarnings()}
      {renderSKUOverlap()}
    </ConfirmModal>
  );
};

export default BaseBulkUploadInfoModal;
