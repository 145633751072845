import React from 'react';
import {styled} from '@mui/material/styles';
import Tabs, {TabsProps} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    width: '100%',
    maxWidth: '25rem',
    fontSize: '1.25rem',
    color: theme.palette.gray.dark,
    padding: '1.875rem 0.9375rem',
    backgroundColor: theme.palette.gray.extraLight,
    borderRight: '1px solid #CBD2D8',
    opacity: 0.7,
    flex: '1 1',
  },
  '&.Mui-selected': {
    color: theme.palette.orange.main,
    opacity: 1,
  },
  [theme.breakpoints.down('lg')]: {
    '&.MuiTab-root': {
      padding: '1.25rem 0.625rem',
      fontSize: '1rem',
      minWidth: '6.25rem',
    },
  },
}));

const StyledTabs = styled(Tabs)(({theme}) => ({
  '&.MuiTabs-root': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .MuiTabs-indicator': {
    top: '0',
    height: '0.375rem',
    backgroundColor: theme.palette.orange.main,
  },
}));

type Props = {
  tabs: {value: string; label: string}[];
  selectedTab: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
} & TabsProps;

const TabsComponent = ({tabs, selectedTab, handleTabChange, ...props}: Props) => (
  <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="tabs options" {...props}>
    {tabs.length &&
      tabs.map((tab, index) => <StyledTab key={index} value={tab.value} label={tab.label} />)}
  </StyledTabs>
);

export default TabsComponent;
