import React, {FC, useState, useEffect, useContext, useCallback, useRef, useMemo} from 'react';
import * as R from 'ramda';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import qs from 'qs';
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useAppDispatch} from '../../../app/store';
import {setDealsTemplateModalOpen} from '../../../app/dealsReducer';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, defaultAlertId, AlertType} from '../../../app/AlertService';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {post, api, put, del, get} from '../../../utils/Request';
import {
  AddEditMixAndMatchGroupProductRequest,
  AddEditMixAndMatchGroupProductResponse,
  GroupProductBulkUploadResponse,
  GroupProductsResponse,
  ProductOverlap,
  MixAndMatchGroupResponse,
  MixAndMatchProduct,
  Pagination,
  RecursiveUndefined,
  SelectOption,
  TError,
  TableColumn,
  TableErrors,
  ValidMixAndMatchFormValues,
  OverlapDisplayType,
} from '../../../types';
import SkuSearch from '../../Form/Agolia/SkuSearch';
import TablePaginationWithAddButton from '../../Table/Pagination/TablePaginationWithAddButton';
import {
  TableCellInputField,
  TableCellSelect,
  SaveActionButton,
  CancelActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../../Table';
import {WarnIcon} from '../../Icons';
import {ErrorBox} from '../../Alert';
import {BulkUploadConfirmModal} from '../../Modal';
import {SearchInputField} from '../../SearchInputField';
import {BulkUploadIconButton} from '../../Button';
import SKUOverlapContent from '../../SKUOverlapContent';
import GroupConfirmModal from '../GroupConfirmModal';
import {mnmDisabled} from '../mixAndMatchUtils';
import MixAndMatchValuesBulkUploadModal from './MixAndMatchValuesBulkUploadModal';

const PREFIX = 'GroupProductsTable';

const classes = {
  tableCell: `${PREFIX}-tableCell`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
    '& > span:first-of-type': {
      display: 'inline-block',
      marginTop: '1rem',
    },
  },
});

const ValidateRule = {
  Required: 'Required',
  NumberOnly: 'NumberOnly',
  PositiveNumber: 'PositiveNumber',
};

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;
const ActionCellContainer = styled('div')`
  display: flex;
`;
const DataMode = {
  Edit: 'Edit',
  Add: 'Add',
  Display: 'Display',
};

export const isDuplicateSKUError = (errorMsg: string) =>
  /There is an existing product with the product code|Product already exists in the group|Product already exist in this group/.test(
    errorMsg
  );

type ErrorState = {
  productSupplierNotFound?: string;
  skuNotFound?: string;
  genericError?: string;
  duplicateSKU?: string;
};

export const formatErrorMsg = (error: any): ErrorState => {
  const decimalError = 'Could not convert string to decimal';
  const arithmeticError = 'Arithmetic overflow error converting numeric to data type numeric.';
  const numberInputErrorMessage = 'Please enter a number less than 100,000,000.';
  const skuDoesNotExistMessage = 'SKU does not exist';
  const duplicateSKUMessage = 'Product already exists in the group';

  const errors = error?.response?.data?.errors || {};
  const detail = error?.response?.data?.detail || '';
  const message = error?.message || '';

  const productSupplierNotFound = errors['']?.[0];
  const skuNotFound =
    errors['ProductCode']?.[0]?.replaceAll('"', '') ||
    (detail === skuDoesNotExistMessage ? skuDoesNotExistMessage : undefined);

  const containsExpectedError = [arithmeticError, decimalError].some((err) => detail.includes(err));
  const genericError = containsExpectedError ? numberInputErrorMessage : detail || message;

  const duplicateSKU = isDuplicateSKUError(genericError) ? duplicateSKUMessage : undefined;

  return {
    ...(productSupplierNotFound && {productSupplierNotFound}),
    ...(skuNotFound && {skuNotFound}),
    ...(duplicateSKU && {duplicateSKU}),
    ...(genericError &&
      ![skuDoesNotExistMessage, duplicateSKUMessage, productSupplierNotFound].includes(
        genericError
      ) && {genericError}),
  };
};

export type LocalProduct = RecursiveUndefined<MixAndMatchProduct> & {
  dataMode?: string;
  skuOverlap?: ProductOverlap[] | null;
};
type ProductQueryParams = {
  searchText?: string;
} & Partial<Pagination>;

export const getProductRequestData = (
  rowData: LocalProduct
): AddEditMixAndMatchGroupProductRequest => {
  const {productCode, gstType, claimAmount} = rowData;
  const nextClaimAmount = !claimAmount && claimAmount !== 0 ? null : claimAmount;
  return {
    productCode,
    gstType: gstType ?? Consts.GstTypeEnum.Exclusive,
    claimAmount: nextClaimAmount,
    ...(!nextClaimAmount && nextClaimAmount !== 0
      ? {}
      : {claimAmountType: rowData.claimAmountType}),
  };
};

const newRowData: LocalProduct = {
  dataMode: DataMode.Add,
  id: undefined,
  ticketPrice: undefined,
  gstType: Consts.GstTypeEnum.Exclusive,
  productCode: undefined,
  description: undefined,
  modelNumber: undefined,
  claimAmount: undefined,
  claimAmountType: Consts.AmountTypeEnum.ValuePerUnit,
};

type Props = {
  groupId: MixAndMatchGroupResponse['id'];
};

const defaultPagination: Pagination = {
  ...Consts.DefaultPagination,
  pageSize: 10,
};

type SKUOverlapState = Record<ProductOverlap['productCode'], ProductOverlap[]>;

const GroupProductsTable: FC<Props> = ({groupId}) => {
  const [keepAdding, setKeepAdding] = useState(false);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [data, setData] = useState<LocalProduct[]>([]);
  const [tableErrors, setTableErrors] = useState<TableErrors>({});
  const [skuErrors, setSKUErrors] = useState<ErrorState>({});
  const [valuesSnapshot, setValuesSnapshot] = useState<LocalProduct[]>([]);
  const [bulkUploadConfirmModalOpen, setBulkUploadConfirmModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [clearExistingDuringBulkUpload, setClearExistingDuringBulkUpload] = useState(false);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [uploadAgain, setUploadAgain] = useState(false);
  const [showMinProductsWarning, setShowMinProductsWarning] = useState(false);
  const [skuOverlaps, setSKUOverlaps] = useState<SKUOverlapState | null>(null);

  const updateFormProductsRef = useRef(false);
  const setInitialData = useRef(false);

  const dispatch = useAppDispatch();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {groups, hasFinalClaim, id: mixAndMatchId},
    setFieldValue,
  } = bag;

  const disabledState = mnmDisabled(bag.values);
  const group = groups?.find((g) => g.id === groupId) as MixAndMatchGroupResponse;
  const groupIndex = groups?.findIndex((g) => g.id === groupId);
  const overlapBackgroundColour = groupIndex === 2 ? 'green.tint' : 'orange.tint';
  const claimOnGroup = group?.claimOnGroup;

  const products = group?.products?.data as LocalProduct[] | undefined;

  const isAddModeActive = data.some((item) => item.dataMode === DataMode.Add);
  const isEditModeActive = data.some((item) => item.dataMode === DataMode.Edit);

  // toggle group overlap alert
  const groupHasSkuOverlaps = Object.keys(skuOverlaps ?? {}).some((sku) =>
    data?.some((product) => product.productCode === sku)
  );

  const skuInputHasAPIError = useMemo(
    () => skuErrors.skuNotFound || skuErrors.productSupplierNotFound || skuErrors.duplicateSKU,
    [skuErrors]
  );

  const handleGroupFormUpdate = useCallback(
    (group: MixAndMatchGroupResponse) => {
      const nextGroups = [...(groups ?? [])];
      const groupIndex = nextGroups?.findIndex((g) => g.id === group.id);
      if (groupIndex === -1) {
        return;
      }
      nextGroups.splice(groupIndex, 1, group);
      setFieldValue('groups', nextGroups);
    },
    [groups, setFieldValue]
  );

  useEffect(() => {
    // update product claim amount when claimOnGroup changes
    setData(products ?? []);
  }, [products, group?.claimOnGroup]);

  useEffect(() => {
    if (skuErrors.genericError && !skuInputHasAPIError) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: skuErrors.genericError},
      });
    } else {
      alertService.clear(defaultAlertId);
    }
  }, [skuErrors.genericError, skuInputHasAPIError]);

  useEffect(() => {
    setValuesSnapshot(products ?? []);
  }, [products]);

  useEffect(() => {
    const {data, ...groupPagination} = group?.products ?? {};
    setPagination({...defaultPagination, ...groupPagination});
  }, [setPagination, group?.products]);

  useEffect(() => {
    if (setInitialData.current) {
      return;
    }
    if (products && products?.length === 0 && !hasFinalClaim) {
      setKeepAdding(true);
      setData([newRowData]);
    } else if (keepAdding && !hasFinalClaim) {
      setData([...(products ?? []), newRowData]);
    } else {
      setData(products ?? [newRowData]);
    }
    setInitialData.current = true;
  }, [products, keepAdding, hasFinalClaim]);

  const updateFormProducts = useCallback(
    (productsData: MixAndMatchProduct[]) => {
      handleGroupFormUpdate({
        ...group,
        products: {
          ...group.products,
          data: productsData as MixAndMatchProduct[],
          totalCount: group.products?.totalCount ?? 0,
          totalPages: group.products?.totalPages ?? 0,
          pageSize: group.products?.pageSize ?? defaultPagination.pageSize,
          currentPage: group.products?.currentPage ?? defaultPagination.currentPage,
        },
      });
    },
    [group, handleGroupFormUpdate]
  );

  useEffect(() => {
    if (updateFormProductsRef.current) {
      updateFormProducts(data as MixAndMatchProduct[]);
      updateFormProductsRef.current = false;
    }
  }, [data, updateFormProducts]);

  const setUpdatedData = (index: number, updates: Partial<LocalProduct>, replace?: boolean) => {
    setSKUErrors({});
    setData((prevData) => {
      const nextRowData = (replace ? updates : {...prevData[index], ...updates}) as LocalProduct;
      // we expect the update when replaced to be valid
      if (!replace) {
        validateRow(nextRowData, index);
      }
      const newData = [...prevData];
      newData.splice(index, 1, nextRowData);
      return newData;
    });
  };

  const handleAddRow = () => {
    if (!isEditModeActive) {
      setUpdatedData(data.length, newRowData, true);
      setKeepAdding(true);
    }
  };

  const onBulkUpload = () => {
    // skip confirmation when we are uploading again after warnings
    if (products && products.length > 0 && !uploadAgain) {
      setBulkUploadConfirmModalOpen(true);
      setBulkUploadModalOpen(false);
    } else {
      setBulkUploadModalOpen(true);
      setUploadAgain(false);
    }
    setShowMinProductsWarning(false);
  };

  const ValidateWithRules = (
    rules: (string | ((value: any, data: LocalProduct) => any))[],
    value: any,
    rowData: LocalProduct
  ) => {
    let errorMessage;
    const isEmptyOrNil = R.isEmpty(value) || R.isNil(value);
    rules.some((rule) => {
      if (rule === ValidateRule.Required && isEmptyOrNil) {
        errorMessage = Consts.ValidationMessage.Required;
        return true;
      } else if (rule === ValidateRule.NumberOnly && !isEmptyOrNil) {
        const hasError = isNaN(value);
        if (hasError) {
          errorMessage = Consts.ValidationMessage.NumberOnly;
          return true;
        }
      } else if (rule === ValidateRule.PositiveNumber && !isEmptyOrNil) {
        const hasError = isNaN(value) || value < 0;
        if (hasError) {
          errorMessage = Consts.ValidationMessage.PositiveValue;
          return true;
        }
      } else if (typeof rule === 'function') {
        const message = rule(value, rowData);
        if (message) {
          errorMessage = message;
          return true;
        }
      }
      return false;
    });
    return errorMessage;
  };

  const getUpdatedErrors = (
    propName: string,
    errorMessage: string | undefined,
    rowIndex: number,
    errors: TableErrors
  ): TableErrors => {
    if (errorMessage) {
      return R.assocPath([rowIndex, propName, 'error'], errorMessage, errors);
    } else {
      return R.dissocPath([rowIndex, propName, 'error'], errors);
    }
  };

  const shouldShowError = (propName: string, rowIndex: number, errors: TableErrors) => {
    return R.pathSatisfies(
      (prop: TError) => !!(prop && prop.error && prop.touched),
      [rowIndex, propName],
      errors
    );
  };

  const validateRow = (rowData: LocalProduct, rowIndex: number, propTouched?: string) => {
    let isValid = true;
    let newErrors = {...tableErrors};

    // Validate claim amount
    const claimAmountRules = [
      ValidateRule.NumberOnly,
      ValidateRule.PositiveNumber,
      (value: number, rowData: LocalProduct) => {
        if (rowData.claimAmountType === Consts.AmountTypeEnum.Percentage && value > 100) {
          return Consts.ValidationMessage.PercentageValue;
        }
      },
      claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.SKU ? ValidateRule.Required : '',
    ];

    const claimAmountErrorMessage = ValidateWithRules(
      claimAmountRules,
      rowData.claimAmount,
      rowData
    );
    newErrors = getUpdatedErrors('claimAmount', claimAmountErrorMessage, rowIndex, newErrors);

    // Validate product code
    const productCodeErrorMessage = ValidateWithRules(
      [ValidateRule.Required],
      rowData.productCode,
      rowData
    );

    newErrors = getUpdatedErrors('productCode', productCodeErrorMessage, rowIndex, newErrors);

    if (propTouched) {
      R.assocPath([rowIndex, propTouched, 'touched'], true, newErrors);
    } else if (newErrors && rowIndex in newErrors) {
      Object.keys(newErrors[rowIndex]).forEach(
        (key) => (newErrors[rowIndex][key]['touched'] = true)
      );
    }

    if (newErrors && rowIndex in newErrors) {
      isValid = !Object.values(newErrors[rowIndex]).some((x) => x.error);
    }
    setTableErrors(newErrors);

    return isValid;
  };

  const getProducts = useCallback(
    async (params?: ProductQueryParams) => {
      try {
        showLoading();
        const response: AxiosResponse<GroupProductsResponse> = await get(
          api(
            Consts.Api.MixAndMatchGroupProducts.replace(':id', `${mixAndMatchId}`).replace(
              ':groupId',
              `${groupId}`
            )
          ),
          {
            params,
            paramsSerializer: (params: ProductQueryParams) =>
              qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'}),
          }
        );
        const {data: responseData, ...pagination} = response.data ?? {};
        setData(responseData);
        setPagination(pagination);
        handleGroupFormUpdate({...group, products: response.data});
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load group products', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading, groupId, group, mixAndMatchId, handleGroupFormUpdate]
  );

  const handleProductSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    const params = {
      ...(newSearchText ? {searchText: newSearchText} : {}),
      ...pagination,
      currentPage: 1,
    };
    getProducts(params);
  };

  const onPagination = useCallback(
    (next?: Partial<Pagination>) => {
      const params = {
        ...(searchText ? {searchText} : {}),
        currentPage: next?.currentPage ?? pagination.currentPage,
        pageSize: next?.pageSize ?? pagination.pageSize,
      };
      getProducts(params);
    },
    [getProducts, pagination, searchText]
  );

  const onUploadComplete = async (
    _uploadResponse?: GroupProductBulkUploadResponse,
    keepModalOpen?: boolean
  ) => {
    try {
      setBulkUploadModalOpen(Boolean(keepModalOpen));
      showLoading();
      const response: AxiosResponse<GroupProductsResponse> = await get(
        api(
          Consts.Api.MixAndMatchGroupProducts.replace(':id', `${mixAndMatchId}`).replace(
            ':groupId',
            `${groupId}`
          )
        )
      );
      const {data: responseData, ...pagination} = response.data ?? {};
      setPagination(pagination);
      setData(responseData);
      handleGroupFormUpdate({...group, products: response.data});
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load group products', response: error.response},
      });
    } finally {
      hideLoading();
    }
  };

  const handleUpdateProduct = async (rowData: LocalProduct, index: number) => {
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    showLoading();
    alertService.clear(defaultAlertId);
    const requestData = getProductRequestData(rowData);
    try {
      const response: AxiosResponse<AddEditMixAndMatchGroupProductResponse> = await put(
        api(Consts.Api.MixAndMatchGroupProduct.replace(':id', `${rowData.id}`)),
        requestData
      );
      const nextOverlapState = !response.data?.productOverlaps
        ? {}
        : response.data?.productOverlaps?.reduce((acc: SKUOverlapState, curr: ProductOverlap) => {
            acc[curr.productCode] = [...(acc[curr.productCode] ?? []), curr];
            return acc;
          }, {});
      setSKUOverlaps(nextOverlapState);
      const nextRowData = {...response.data, dataMode: DataMode.Display} as LocalProduct;
      setUpdatedData(index, nextRowData, true);

      setValuesSnapshot((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1, nextRowData);
        return newData;
      });

      setTableErrors((prevErrors) => {
        const {[index]: _, ...newErrors} = {...prevErrors};
        return newErrors;
      });
      updateFormProductsRef.current = true;
    } catch (error: any) {
      const errors = formatErrorMsg(error);
      if (Object.values(errors).some((x) => x)) {
        setSKUErrors(errors);
      }
    } finally {
      hideLoading();
    }
  };

  const onAdd = async (rowData: LocalProduct, index: number) => {
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    showLoading();
    setShowMinProductsWarning(false);
    const requestData = getProductRequestData(rowData);
    try {
      const addProductResponse: AxiosResponse<AddEditMixAndMatchGroupProductResponse> = await post(
        api(Consts.Api.MixAndMatchGroupProducts).replace(':groupId', `${groupId}`),
        requestData
      );
      const nextOverlapState = !addProductResponse.data?.productOverlaps
        ? {}
        : addProductResponse.data?.productOverlaps?.reduce(
            (acc: SKUOverlapState, curr: ProductOverlap) => {
              acc[curr.productCode] = [...(acc[curr.productCode] ?? []), curr];
              return acc;
            },
            {}
          );
      setSKUOverlaps(nextOverlapState);
      const goToNextPage = !!products?.length && !(products.length % pagination.pageSize);
      const currentPage = goToNextPage ? pagination.currentPage + 1 : pagination.currentPage;
      onPagination({currentPage});
      alertService.clear(defaultAlertId);
      setTableErrors({});
      setSKUErrors({});
    } catch (error: any) {
      const errors = formatErrorMsg(error);
      if (Object.values(errors).some((x) => x)) {
        setSKUErrors(errors);
      }
    } finally {
      hideLoading();
    }
  };

  const onConfirmClearData = () => {
    setClearDataWarningModalOpen(false);
  };

  const onEdit = (index: number) => {
    setUpdatedData(index, {dataMode: DataMode.Edit});
  };

  const savedProducts = data?.filter((x) => x.dataMode !== DataMode.Add);
  const onDelete = async (rowData: LocalProduct, index: number) => {
    if (!data) {
      return;
    } else if (rowData.dataMode === DataMode.Add) {
      setSKUErrors({});
      setTableErrors((prevErrors) => {
        const newErrors = {...prevErrors};
        const {[index]: _, ...rest} = newErrors;
        return rest;
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
      setKeepAdding(false);
    } else if (savedProducts.length === 1) {
      setShowMinProductsWarning(true);
    } else {
      setSKUOverlaps((prevOverlaps) => {
        if (prevOverlaps && rowData.productCode) {
          const {[rowData.productCode]: _, ...rest} = prevOverlaps;
          return rest;
        }
        return prevOverlaps;
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
      showLoading();
      try {
        await del(api(Consts.Api.MixAndMatchGroupProduct.replace(':id', `${rowData.id}`)));
        alertService.clear(defaultAlertId);
        const isLastItemOnPage = `${products?.length}`.at(-1) === '1' && pagination.currentPage > 1;
        const currentPage = isLastItemOnPage ? pagination.currentPage - 1 : pagination.currentPage;
        onPagination({currentPage});
        setSKUErrors({});
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    }
  };

  const onCancelEdit = (_rowData: LocalProduct, index: number) => {
    const resetRowData = {...valuesSnapshot[index], dataMode: DataMode.Display} as LocalProduct;
    setUpdatedData(index, resetRowData, true);
    setTableErrors((prevErrors) => {
      const {[index]: _, ...newErrors} = {...prevErrors};
      return newErrors;
    });
  };

  const renderClaimAmount = (rowData: LocalProduct) => {
    const unit = Consts.ClaimAmountType.find((item) => item.value === rowData.claimAmountType);
    if ((!rowData.claimAmount && rowData.claimAmount !== 0) || !unit) {
      return '-';
    }
    const prefix = rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit ? '$' : '';
    const postfix = rowData.claimAmountType === Consts.AmountTypeEnum.Percentage ? '%' : '';
    return getDisplayAmountValue(rowData.claimAmount, prefix, postfix);
  };

  const showAmountWarning = (rowData: LocalProduct): boolean => {
    return (
      (rowData.claimAmountType === Consts.AmountTypeEnum.Percentage &&
        !!rowData.claimAmount &&
        rowData.claimAmount > config.dealPercentageAmountWarningThreshold) ||
      (rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
        !!rowData.claimAmount &&
        rowData.claimAmount > config.dealDollarAmountWarningThreshold)
    );
  };

  const getAmountWarning = (rowData: LocalProduct) => {
    if (
      rowData.claimAmountType === Consts.AmountTypeEnum.Percentage &&
      rowData.claimAmount &&
      rowData.claimAmount > config.dealPercentageAmountWarningThreshold
    ) {
      return `The value is greater than ${config.dealPercentageAmountWarningThreshold}%`;
    }
    if (
      rowData.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
      rowData.claimAmount &&
      rowData.claimAmount > config.dealDollarAmountWarningThreshold
    ) {
      return `The value is greater than ${getDisplayAmountValue(
        config.dealDollarAmountWarningThreshold,
        '$'
      )}`;
    }
  };

  const renderEditClaimAmount = (rowData: LocalProduct, rowIndex: number) => {
    const defaultType =
      Consts.ClaimAmountType.find((item) => item.value === rowData.claimAmountType) ??
      Consts.ClaimAmountType[0];
    const showError = shouldShowError('claimAmount', rowIndex, tableErrors);
    const errorMessage = showError && R.path([rowIndex, 'claimAmount', 'error'], tableErrors);
    const showWarning = showAmountWarning(rowData);
    const warningMessage = getAmountWarning(rowData);

    const defaultValue =
      rowData.claimAmount !== null && rowData.claimAmount !== undefined
        ? `${rowData.claimAmount}`
        : '';

    const regex = /^(-?[\d,]*(?:\.\d{0,2})?)?$/;
    return (
      <CellContainer>
        <TableCellSelect
          data-testid="edit-claim-amount-type"
          sx={{minWidth: '6.25rem'}}
          options={Consts.ClaimAmountType}
          defaultValue={defaultType}
          onChanged={(option: SelectOption) => {
            setUpdatedData(rowIndex, {claimAmountType: option.value, claimAmount: undefined});
          }}
        />
        <TableCellInputField
          fullWidth
          error={showError}
          warning={!showError && showWarning}
          helperText={errorMessage || warningMessage}
          value={defaultValue}
          placeholder="Add value"
          regexValidation={regex}
          digits={2}
          onChanged={(value) => {
            const nextValue = value === '' ? null : value;
            const updates = {
              claimAmount: nextValue,
              claimAmountType: !rowData.claimAmountType
                ? defaultType.value
                : rowData.claimAmountType,
            };
            setUpdatedData(rowIndex, updates as Partial<LocalProduct>);
          }}
        />
      </CellContainer>
    );
  };

  const getColumns = () => {
    const columns: TableColumn<LocalProduct>[] = [
      {
        id: 'productCode',
        field: 'productCode',
        title: renderSkuColumnTitle(),
        render: (rowData) => {
          const text = rowData.productCode ? `${rowData.productCode} ${rowData.description}` : null;
          const hasSkuOverlap =
            rowData.productCode && (skuOverlaps?.[rowData.productCode] ?? [])?.length > 0;
          return (
            <Stack direction="row" alignItems="flex-end" gap={1}>
              {hasSkuOverlap ? <WarnIcon /> : null}
              <span>{text}</span>
            </Stack>
          );
        },
        renderEdit: renderEditSkuColumn,
        editStyle: {minWidth: '12.5rem'},
      },
      {
        id: 'modelNumber',
        field: 'modelNumber',
        title: 'Model Number',
        render: (rowData) => rowData.modelNumber ?? '',
        renderEdit: (rowData) => <span>{rowData.modelNumber ?? ''}</span>,
      },
      {
        id: 'gstType',
        field: 'gstType',
        title: 'GST',
        render: (rowData) => rowData.gstType,
        renderEdit: renderEditGstColumn,
        editStyle: {minWidth: '7.25rem'},
        hide: group?.claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.SKU,
      },
      {
        id: 'ticketPrice',
        field: 'ticketPrice',
        title: 'Ticket/ELQ Price',
        render: (rowData) => <span>{getDisplayAmountValue(rowData.ticketPrice, '$')}</span>,
        renderEdit: (rowData) => <span>{getDisplayAmountValue(rowData.ticketPrice, '$')}</span>,
      },
      {
        id: 'claimAmount',
        field: 'claimAmount',
        title: 'Claim Amount',
        render: renderClaimAmount,
        renderEdit: renderEditClaimAmount,
        hide: group?.claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.SKU,
      },
      {
        id: 'actions',
        field: null,
        title: '',
        render: renderActionColumn,
        style: {width: '6.25rem'},
        isAction: true,
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const renderEditGstColumn = (rowData: LocalProduct, index: number) => {
    const defaultValue = Consts.GstType.find((item) => item.value === rowData.gstType);
    const options = Consts.GstType;

    const handleGstTypeChange = (option: SelectOption) => {
      setUpdatedData(index, {gstType: option.value});
    };

    return (
      <TableCellSelect
        fullWidth
        sx={{minWidth: '7rem'}}
        options={options}
        defaultValue={defaultValue}
        onChanged={handleGstTypeChange}
      />
    );
  };
  const renderActionColumn = (rowData: LocalProduct, index: number) => {
    const leftActionButtonStyle = {marginRight: '0.25rem'};
    if (rowData.dataMode === DataMode.Add) {
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => onAdd(rowData, index)}
            disabled={disabledState.actions.addProduct}
          />
          <DeleteActionButton
            disabled={data?.length === 1 || disabledState.actions.removeProduct}
            onClick={() => onDelete(rowData, index)}
          />
        </ActionCellContainer>
      );
    }

    if (rowData.dataMode === DataMode.Edit) {
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => handleUpdateProduct(rowData, index)}
          />
          <CancelActionButton onClick={() => onCancelEdit(rowData, index)} />
        </ActionCellContainer>
      );
    }

    const disabled =
      (rowData.dataMode !== DataMode.Edit && isEditModeActive) ||
      disabledState.actions.editProduct ||
      isAddModeActive; // Disable edit if a row is in Add mode

    return (
      <ActionCellContainer>
        <EditActionButton
          style={leftActionButtonStyle}
          disabled={disabled}
          onClick={() => onEdit(index)}
        />
        <DeleteActionButton
          disabled={disabled || disabledState.actions.removeProduct}
          onClick={() => onDelete(rowData, index)}
        />
      </ActionCellContainer>
    );
  };

  const confirmBody = (
    <div>
      This action will permanently clear and replace all the products for this group. Would you like
      to continue?
    </div>
  );
  const renderSkuColumnTitle = () => (
    <span>
      SKU*
      <>
        <BulkUploadIconButton
          onClick={onBulkUpload}
          sx={{
            lineHeight: 'normal',
            fontWeight: 'normal',
            paddingLeft: '1rem',
            paddingRight: '0',
          }}
          disabled={hasFinalClaim}
        >
          Bulk Upload SKUs
        </BulkUploadIconButton>
        <GroupConfirmModal
          open={clearDataWarningModalOpen}
          onOk={onConfirmClearData}
          onCancel={() => {
            setClearDataWarningModalOpen(false);
          }}
          body={confirmBody}
        />
      </>
    </span>
  );

  const renderEditSkuColumn = (rowData: LocalProduct, rowIndex: number) => {
    const showError = shouldShowError('productCode', rowIndex, tableErrors) || skuInputHasAPIError;
    const errorMessage =
      showError &&
      (R.path([rowIndex, 'productCode', 'error'], tableErrors) ||
        skuErrors.skuNotFound ||
        skuErrors.productSupplierNotFound ||
        skuErrors.duplicateSKU);
    return (
      <SkuSearch
        fullWidth
        disabled={disabledState.fields.groups.products.productCode}
        placeholder="Product search"
        defaultValue={
          rowData.productCode
            ? {
                code: rowData.productCode,
                name: rowData.description,
                ticketPrice: rowData.ticketPrice,
                modelNumber: rowData.modelNumber,
              }
            : null
        }
        errorMessage={errorMessage}
        onChanged={(item?: {
          code: string;
          name: string;
          ticketPrice: number;
          modelNumber: string;
        }) => {
          const productUpdates = {
            productCode: item?.code,
            description: item?.name,
            ticketPrice: item?.ticketPrice,
            modelNumber: item?.modelNumber,
          };
          setUpdatedData(rowIndex, productUpdates);
        }}
      />
    );
  };

  const onChangePage = (currentPage: number) => {
    onPagination({currentPage});
  };

  const onChangePageSize = (pageSize: number) => {
    onPagination({currentPage: 1, pageSize});
  };

  const renderCell = (
    column: TableColumn<LocalProduct>,
    row: LocalProduct,
    rowIndex: number
  ): null | React.ReactNode | TableColumn<LocalProduct>['field'] => {
    let rowElement = null;
    if ((row.dataMode === DataMode.Add || row.dataMode === DataMode.Edit) && column.renderEdit) {
      rowElement = column.renderEdit(row, rowIndex);
    } else if (column.render) {
      if (column.isAction) {
        rowElement = <ActionCellContainer>{column.render(row, rowIndex)}</ActionCellContainer>;
      } else {
        rowElement = <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
      }
    } else {
      rowElement = (row[column.field as keyof LocalProduct] as string | null) ?? null;
    }
    return rowElement;
  };

  const renderRow = (
    row: LocalProduct,
    rowIndex: number,
    rowSkuOverlaps?: ProductOverlap[] | null
  ) => {
    const rowHasSkuOverlaps = Array.isArray(rowSkuOverlaps) && rowSkuOverlaps.length > 0;
    return (
      <React.Fragment key={`row-${rowIndex}`}>
        <TableRow sx={{backgroundColor: rowHasSkuOverlaps ? overlapBackgroundColour : 'inherit'}}>
          {getColumns().map((column, columnIndex) => {
            const displayMode = row.dataMode === DataMode.Display || !row.dataMode;
            const style = {
              ...column.style,
              ...(!displayMode && column.editStyle ? column.editStyle : {}),
              ...(rowHasSkuOverlaps ? {borderBottom: 'none'} : {}),
            };
            return (
              <TableCell
                classes={{root: displayMode ? '' : classes.tableCell}}
                key={`cell-${rowIndex}-${columnIndex}`}
                style={style}
              >
                {renderCell(column, row, rowIndex)}
              </TableCell>
            );
          })}
        </TableRow>
        {rowHasSkuOverlaps ? (
          <TableRow sx={{backgroundColor: overlapBackgroundColour}}>
            <TableCell colSpan={12} sx={{pt: 0}}>
              <SKUOverlapContent
                skuOverlaps={rowSkuOverlaps}
                textPrefix={OverlapDisplayType.MixAndMatch}
              />
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div>Add individual SKUs or Bulk Upload using the link</div>
        <SearchInputField
          iconPosition="start"
          placeholder="Search previously uploaded SKUs"
          width="26rem"
          onSearch={handleProductSearch}
          defaultValue={searchText ?? ''}
        />
      </Stack>
      {showMinProductsWarning ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            border: 'solid 1px #DA6A00',
            padding: '0.5rem 1rem',
          }}
        >
          <div>Each group in the Mix & Match selection must contain at least one product.</div>
        </ErrorBox>
      ) : null}
      {groupHasSkuOverlaps ? (
        <ErrorBox
          type={AlertType.Warning}
          sx={{
            border: 'solid 1px #DA6A00',
            padding: '0.5rem 1rem',
          }}
        >
          <div>There is an overlap of SKUs in the highlighted items below.</div>
        </ErrorBox>
      ) : null}
      <StyledTableContainer data-testid="group-products-table" sx={{overflowY: 'hidden'}}>
        <Table>
          <TableHead>
            <TableRow>
              {getColumns().map((column, index) => (
                <TableCell key={index} style={column.style}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="products">
            {data.map((row, index) =>
              renderRow(
                row,
                index,
                skuOverlaps === null || !row.productCode
                  ? null
                  : skuOverlaps?.[row.productCode] ?? null
              )
            )}
          </TableBody>
        </Table>
        <TablePaginationWithAddButton
          addButtonText="Add another product"
          disabled={
            data?.some((item) => item.dataMode === DataMode.Add) || disabledState.actions.addProduct
          }
          onAdd={handleAddRow}
          pagination={pagination}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
        <MixAndMatchValuesBulkUploadModal
          title="Bulk Upload Mix & Match Values"
          uploadUrl={api(
            Consts.Api.MixAndMatchBulkUpload.replace(':id', `${groupId}`) +
              `?clearExisting=${clearExistingDuringBulkUpload}`
          )}
          open={bulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          onOpenTemplate={() => {
            setBulkUploadModalOpen(false);
            dispatch(setDealsTemplateModalOpen(true));
          }}
          onComplete={onUploadComplete}
          onReupload={() => {
            setBulkUploadModalOpen(true);
            setClearExistingDuringBulkUpload(true);
            setUploadAgain(true);
          }}
        />
        <BulkUploadConfirmModal
          open={bulkUploadConfirmModalOpen}
          onKeepAdding={() => {
            setClearExistingDuringBulkUpload(false);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onReplace={() => {
            setClearExistingDuringBulkUpload(true);
            setBulkUploadConfirmModalOpen(false);
            setBulkUploadModalOpen(true);
          }}
          onCancel={() => {
            setBulkUploadConfirmModalOpen(false);
          }}
          clearedText="all values in this group"
        />
      </StyledTableContainer>
    </Stack>
  );
};

export default GroupProductsTable;
