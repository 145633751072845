import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import MUIDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'Dialog';

const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  hidden: `${PREFIX}-hidden`,
  dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(MUIDialog)(() => ({
  [`& .${classes.dialogPaper}`]: {
    left: '25%',
    width: '50%',
  },

  [`& .${classes.hidden}`]: {
    display: 'none',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '1rem 2.625rem',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
  },
}));

const StyledDialogTitle = styled('div')`
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  padding: 2.875rem;
  justify-content: center;
  align-items: center;
  > span {
    flex-grow: 1;
  }
`;

type Props = {
  open: boolean;
  children: React.ReactNode;
  readOnly?: boolean;
  scrollType?: 'paper' | 'body';
  onClose?: () => void;
};

const Dialog: FC<Props> = ({
  open,
  children,
  onClose,
  readOnly = false,
  scrollType = 'paper',
  ...dialogProps
}) => {
  return (
    <>
      <StyledDialog
        fullScreen
        open={open}
        onClose={onClose}
        scroll={scrollType}
        classes={{paper: classes.dialogPaper}}
        {...dialogProps}
      >
        <StyledDialogTitle>
          <span>Product Selector</span>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        {children}
      </StyledDialog>
    </>
  );
};

export default Dialog;
