import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {useAppDispatch, useAppSelector} from '../../../../../app/store';
import {selectNoFacetsSelected} from '../../../../../app/selectors';
import {resetCriteria, setResultCount} from '../../../../../app/criteriaReducer';
import {Button} from '../../../../Button';
import CustomStats from '../../CustomStats';

const ActionsContainer = styled('div')`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
`;

const ApplyCriteriaButton = styled(Button)`
  width: 22rem;
`;

const OptionalTooltip: FC<{disabled: boolean; children: React.ReactNode}> = ({
  disabled,
  children,
}) => (
  <>
    {disabled ? (
      <Tooltip
        title="add inclusion and/or exclusion criteria to continue"
        sx={{cursor: 'not-allowed'}}
        placement="top"
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);

type Props = {
  readOnly?: boolean;
  onApplyCriteria?: () => void;
};

const Actions: FC<Props> = ({readOnly = false, onApplyCriteria}) => {
  const dispatch = useAppDispatch();
  const noFacetsSelected = useAppSelector(selectNoFacetsSelected);

  const handleApplyCriteria = () => {
    onApplyCriteria?.();
  };

  const handleClearCriteria = () => {
    dispatch(resetCriteria());
  };

  const handleResult = (count: number) => {
    dispatch(setResultCount(count));
  };

  return (
    <ActionsContainer>
      <CustomStats
        resultLabel="SKU"
        pluralResultLabel="SKUs"
        onResult={handleResult}
        canClearCriteria={!readOnly}
        onClearCriteria={handleClearCriteria}
        noSelection={noFacetsSelected}
      />
      {!readOnly ? (
        <OptionalTooltip disabled={noFacetsSelected}>
          <ApplyCriteriaButton onClick={handleApplyCriteria} disabled={noFacetsSelected}>
            Apply Criteria
          </ApplyCriteriaButton>
        </OptionalTooltip>
      ) : null}
    </ActionsContainer>
  );
};

export default Actions;
