import React, {useCallback, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import Consts from '../../app/Consts';
import {AdjustmentListItem, AxiosUploadResponse} from '../../types';
import {api} from '../../utils/Request';
import {PageHeading} from '../PageHeading/PageHeading';
import {PageContentContainer} from '../Container';
import {Button, LinkButton, OutlinedButton} from '../Button';
import {
  BulkUploadModal,
  DownloadTemplatesModal,
  UnSavedChangesConfirmModal,
  ClearAdjustmentValueDataConfirmModal,
} from '../Modal';
import AdjustmentValuesTable from './AdjustmentValuesTable';

const PREFIX = 'AdjustmentForm';

const classes = {
  root: `${PREFIX}-root`,
  pageContentContainer: `${PREFIX}-pageContentContainer`,
  tableTitle: `${PREFIX}-tableTitle`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.pageContentContainer}`]: {
    width: '100% !important',
    maxWidth: '1700px !important',
    padding: '40px !important',
    margin: '0 !important',
  },

  [`& .${classes.tableTitle}`]: {
    marginBottom: '10px',
  },
}));

const AdjustmentForm = () => {
  const navigate = useNavigate();
  const [isEditingRow, setIsEditingRow] = useState(false);
  const [unSavedChangesConfirmModalOpen, setUnSavedChangesConfirmModalOpen] = useState(false);
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [values, setValues] = useState<AdjustmentListItem[]>([]);

  function onTableDataUpdating(isUpdating: boolean) {
    setIsEditingRow(isUpdating);
  }
  function onFinish() {
    if (isEditingRow) {
      setUnSavedChangesConfirmModalOpen(true);
    } else {
      navigate(Consts.RouterPath.Adjustments);
    }
  }
  function onConfirmIgnoreChangesAndContinue() {
    navigate(Consts.RouterPath.Adjustments);
  }

  function onBulkUpload() {
    if (values.length > 0) {
      setClearDataWarningModalOpen(true);
    } else {
      setBulkUploadModalOpen(true);
    }
  }
  function onUploadComplete(
    response?: AxiosUploadResponse<AdjustmentListItem[]>,
    keepModalOpen?: boolean
  ) {
    setBulkUploadModalOpen(Boolean(keepModalOpen));
    if (response?.data && 'data' in response.data) {
      setValues(response.data.data);
    }
  }
  function onConfirmClearData() {
    setClearDataWarningModalOpen(false);
    setBulkUploadModalOpen(true);
  }
  function onCancelClearData() {
    setClearDataWarningModalOpen(false);
  }
  function renderBulkUploadHeader() {
    return (
      <>
        <LinkButton
          type="button"
          onClick={() => {
            setTemplatesModalOpen(true);
          }}
        >
          Download Excel Templates
        </LinkButton>
        <OutlinedButton onClick={onBulkUpload}>Bulk Upload</OutlinedButton>
        <DownloadTemplatesModal
          title={'Download Excel Templates'}
          fileNames={['Adjustments Template.xlsx']}
          open={templatesModalOpen}
          onClose={() => {
            setTemplatesModalOpen(false);
          }}
        />
        <BulkUploadModal
          title={'Bulk Upload Adjustments'}
          uploadUrl={api(Consts.Api.AdjustmentsUpload)}
          additionalData={values.map((x) => x.id)}
          open={bulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          onOpenTemplate={() => {
            setBulkUploadModalOpen(false);
            setTemplatesModalOpen(true);
          }}
          onComplete={onUploadComplete}
        />
        <ClearAdjustmentValueDataConfirmModal
          open={clearDataWarningModalOpen}
          onOk={onConfirmClearData}
          onCancel={onCancelClearData}
        />
      </>
    );
  }

  const onTableDataUpdated = useCallback((values: AdjustmentListItem[]) => {
    setValues(values);
  }, []);

  return (
    <Root className={classes.root}>
      <PageHeading title="Add Adjustment" />
      <Box sx={{margin: '3rem 1.5rem', display: 'flex', justifyContent: 'center'}}>
        <PageContentContainer className={classes.pageContentContainer}>
          <Grid container className={classes.tableTitle}>
            <Box alignSelf="center" flexGrow={1}>
              <Typography variant="h5">Add Adjustment Values</Typography>
            </Box>
            <Box alignSelf="center">{renderBulkUploadHeader()}</Box>
          </Grid>

          <AdjustmentValuesTable
            initialValues={values}
            onTableDataUpdating={onTableDataUpdating}
            onTableDataUpdated={onTableDataUpdated}
          />
          <UnSavedChangesConfirmModal
            open={unSavedChangesConfirmModalOpen}
            onOk={onConfirmIgnoreChangesAndContinue}
            onCancel={() => {
              setUnSavedChangesConfirmModalOpen(false);
            }}
          />
          <Button
            sx={{
              marginTop: '30px',
            }}
            onClick={onFinish}
          >
            Finish
          </Button>
        </PageContentContainer>
      </Box>
    </Root>
  );
};

export default AdjustmentForm;
