import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {StyledMaterialSwitch} from '../../Form/Switch';
import FieldLabel from '../../Form/FieldLabel';
import {MixAndMatchFinanceDetails, ValidMixAndMatchFormValues} from '../../../types';
import {useFormikContext} from 'formik';

const StyledLabel = styled(FieldLabel)`
  font-weight: normal;
`;

export const shouldShowSingleInvoicePrompt = (
  financeDetails: MixAndMatchFinanceDetails[] | undefined
) => {
  if (!financeDetails || financeDetails.length < 2) {
    return false;
  }

  const vendorCodeCounts = financeDetails.reduce((acc, detail) => {
    const code = detail.claimVendorCode;
    acc[code] = (acc[code] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return Object.values(vendorCodeCounts).some((count) => count >= 2);
};

const SingleInvoiceSwitch = () => {
  const {values, setFieldValue} = useFormikContext<ValidMixAndMatchFormValues>();
  const {financeDetails} = values;

  if (!shouldShowSingleInvoicePrompt(financeDetails)) {
    return null;
  }

  const value = financeDetails?.some((financeDetail) => financeDetail.groupClaimsOnSingleInvoice);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFinanceDetails = financeDetails?.map((financeDetail) => ({
      ...financeDetail,
      groupClaimsOnSingleInvoice: event.target.checked,
    }));
    setFieldValue('financeDetails', newFinanceDetails);
  };

  return (
    <Stack direction="column" spacing={2} sx={{marginTop: '-1rem', marginBottom: '3rem'}}>
      <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '1rem'}}>
        You have selected to claim across more than 1 group of products.
      </Typography>
      <StyledLabel>Group claims for the same vendor into one invoice?</StyledLabel>
      <StyledMaterialSwitch checked={value} onChange={handleChange} color="primary" />
    </Stack>
  );
};

export default SingleInvoiceSwitch;
