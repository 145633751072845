import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {useParams} from 'react-router-dom';
import type {AxiosResponse} from 'axios';
import {api, getCached, put} from '../../../utils/Request';
import type {FinanceDetailsResponse} from '../../../types';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import EditableField from '../../../components/EditableField';

const Container = styled(Box)(({theme}) => ({
  margin: theme.spacing(0, 4),
  backgroundColor: theme.palette.white.main,
  height: '28.5rem',
}));

const FieldContainer = styled(Box)(({theme}) => ({
  padding: theme.spacing(6, 12),
  backgroundColor: theme.palette.white.main,
  maxWidth: '40rem',
}));

const DealCommentTabContent = () => {
  const {id = ''} = useParams<{id: string}>();
  const [financeDetails, setFinanceDetails] = useState<FinanceDetailsResponse | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    const fetchComments = async () => {
      showLoading();
      try {
        const response: AxiosResponse<FinanceDetailsResponse> = await getCached(
          api(Consts.Api.DealFinanceDetails.replace(':id', id))
        );
        setFinanceDetails(response.data);
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    };
    fetchComments();
  }, [id, hideLoading, showLoading]);

  const handleSave = async (newValue?: string) => {
    if (!financeDetails) {
      return;
    }
    showLoading();
    try {
      const requestData = {
        ...financeDetails,
        comment: newValue,
      };
      setFinanceDetails(requestData as FinanceDetailsResponse);
      await put(api(Consts.Api.DealFinanceDetails.replace(':id', id)), requestData);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Container>
      <FieldContainer>
        <EditableField
          multiline
          showPlaceholder
          value={financeDetails?.comment ?? ''}
          onSave={handleSave}
          rows={12}
          direction="column-reverse"
          title="Comment"
        />
      </FieldContainer>
    </Container>
  );
};

export default DealCommentTabContent;
