import React, {FC, useEffect, useState} from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {EditIcon} from '../Icons';
import IconLabelButton from '../Button/IconLabelButton';
import {CancelActionButton, SaveActionButton} from '../Table';
import Typography from '@mui/material/Typography';
import type {ResponsiveStyleValue} from '@mui/system';

/**
 * @param {boolean} showPlaceholder - if true, a disabled input field is shown when the value is empty
 * @param {string} direction - flex direction of the actions buttons and input field
 */
type Props = TextFieldProps & {
  readOnly?: boolean;
  value?: string;
  onSave: (newValue?: string) => Promise<void>;
  direction?: ResponsiveStyleValue<'row' | 'column' | 'row-reverse' | 'column-reverse'>;
  validationSchema?: any;
  name?: string;
  reverseActionPosition?: boolean;
  title?: string;
  showPlaceholder?: boolean;
};

const EditableField: FC<Props> = ({
  readOnly,
  value,
  onSave,
  direction,
  validationSchema,
  name,
  reverseActionPosition,
  title,
  showPlaceholder,
  ...textFieldProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState<string | undefined | null>('');
  const [error, setError] = useState<string | null>(null);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setNewValue(value);
    setIsEditing(false);
  };

  const handleSave = () => {
    onSave(newValue ?? undefined);
    setIsEditing(false);
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setNewValue(inputValue);

    if (validationSchema && name) {
      setIsValidating(true);
      try {
        await validationSchema.validate({[name]: inputValue});
        setError(null);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsValidating(false);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (textFieldProps.multiline) {
      if (e.key === 'Enter') {
        e.preventDefault();
        setNewValue((prevValue) => prevValue + '\n');
      }
    } else {
      if (e.key === 'Enter') {
        handleSave();
      }
    }
  };

  const titlePosition = title !== undefined ? 'space-between' : 'flex-end';

  if (isEditing) {
    return (
      <Stack direction={direction ?? 'column'} spacing={2} sx={{width: '100%'}}>
        <TextField
          {...textFieldProps}
          variant="outlined"
          autoFocus
          type="text"
          value={newValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          fullWidth
          error={!!error}
          helperText={error}
          sx={{
            '& .MuiFormHelperText-root': {
              position: 'absolute',
              top: '42px',
            },
          }}
        />
        <Stack direction="row" spacing={1} sx={{justifyContent: titlePosition}}>
          {title ? <Typography variant="h6">{title}</Typography> : null}
          <Stack direction="row" spacing={1}>
            <SaveActionButton
              size="small"
              type="submit"
              onClick={handleSave}
              disabled={!!error || isValidating}
              style={{
                height: '1.5rem',
                width: '1.5rem',
                padding: '0.25rem',
                borderRadius: '0.75rem !important',
              }}
            />
            <CancelActionButton
              size="small"
              onClick={handleCancel}
              style={{
                height: '1.5rem',
                width: '1.5rem',
                padding: '0.25rem',
                borderRadius: '0.75rem !important',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      direction={direction ?? 'column'}
      spacing={2}
      sx={{
        svg: {width: '1.0625rem !important'},
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {value || !showPlaceholder ? (
        <Typography sx={{whiteSpace: 'pre-line'}}>{value}</Typography>
      ) : (
        <TextField
          {...textFieldProps}
          variant="outlined"
          type="text"
          value={newValue}
          rows={12}
          fullWidth
          disabled
          placeholder="Enter text here"
        />
      )}
      <Stack direction="row" spacing={1} justifyContent={titlePosition} sx={{width: '100%'}}>
        {title ? <Typography variant="h6">{title}</Typography> : null}
        {!readOnly ? (
          <IconLabelButton
            sx={{
              fontSize: '1rem',
              fontWeight: 'normal',
              padding: '0.375rem',
              lineHeight: 'normal',
              textDecoration: 'underline',
              height: 'unset',
            }}
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEdit}
            data-testid="edit-button"
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default EditableField;
