import config from '../../../../app/Config';
import Consts from '../../../../app/Consts';

export const inclusionFacetLimit = 50;

export const brandFacet = Consts.AlgoliaFacet.ProductBrand.Index;
export const brandFacetLabel = Consts.AlgoliaFacet.ProductBrand.Label;
export const departmentFacet = Consts.AlgoliaFacet.ProductDepartment.Index;
export const departmentFacetLabel = Consts.AlgoliaFacet.ProductDepartment.Label;
export const productGroupFacet = Consts.AlgoliaFacet.ProductGroup.Index;
export const productGroupFacetLabel = Consts.AlgoliaFacet.ProductGroup.Label;
export const supplierFacet = Consts.AlgoliaFacet.ProductSupplier.Index;
export const supplierFacetLabel = Consts.AlgoliaFacet.ProductSupplier.Label;
export const seasonCodeFacet = Consts.AlgoliaFacet.ProductSeasonCode.Index;
export const seasonCodeFacetLabel = Consts.AlgoliaFacet.ProductSeasonCode.Label;
export const productSkuFacet = Consts.AlgoliaFacet.ProductSku.Index;
export const productSkuFacetLabel = Consts.AlgoliaFacet.ProductSku.Label;
export const productSkuBulkFacet = Consts.AlgoliaFacet.ProductSkuBulk.Label;
export const productSkuBulkFacetLabel = Consts.AlgoliaFacet.ProductSkuBulk.Label;
export const specificationFacet = Consts.AlgoliaFacet.ProductSpecification.Index;
export const specificationFacetLabel = Consts.AlgoliaFacet.ProductSpecification.Label;

export const inclusionFacets = [
  {
    label: departmentFacetLabel,
    value: departmentFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductDepartment.PluralLabel,
  },
  {
    label: supplierFacetLabel,
    value: supplierFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSupplier.PluralLabel,
  },
  {
    label: brandFacetLabel,
    value: brandFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductBrand.PluralLabel,
  },
  {
    label: seasonCodeFacetLabel,
    value: seasonCodeFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSeasonCode.PluralLabel,
  },
  {
    label: productGroupFacetLabel,
    value: productGroupFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductGroup.PluralLabel,
  },
  {
    label: productSkuFacetLabel,
    value: productSkuFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSku.PluralLabel,
  },
  {
    label: productSkuBulkFacetLabel,
    value: productSkuBulkFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSkuBulk.PluralLabel,
  },
  {
    label: specificationFacetLabel,
    value: specificationFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSpecification.PluralLabel,
  },
];

export const exclusionFacets = [...inclusionFacets];

export const defaultCriteria = {
  indexName: Consts.AlgoliaIndex.Products,
  filters: `entityCode:${config.entityCode}`,
  facetInclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
    {
      name: specificationFacet,
      facetType: Consts.AlgoliaFacet.ProductSpecification.Type,
      values: [],
    },
  ],
  facetExclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
    {
      name: specificationFacet,
      facetType: Consts.AlgoliaFacet.ProductSpecification.Type,
      values: [],
    },
  ],
  resultCount: 0,
};
