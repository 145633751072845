import React, {FC, useState, useRef, useCallback} from 'react';
import {styled} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from '../../../../../app/store';
import {setCriteria} from '../../../../../app/criteriaReducer';
import {selectCriteria, selectNoFacetsSelected} from '../../../../../app/selectors';
import Consts from '../../../../../app/Consts';
import {QueryCriteria, Facet} from '../../../../../types';
import CustomSelect from '../../../../Select/CustomSelect';
import CustomCurrentRefinements from '../../CustomCurrentRefinements';
import CriteriaSection from '../../CriteriaSection';
import ExcludeRefinementList from '../../ExcludeRefinementList';
import IncludeRefinementList from '../../IncludeRefinementList';
import BulkSKUSelector from '../../BulkSKUSelector';
import NoResultErrorStats from '../../NoResultErrorStats';
import {inclusionFacetLimit, inclusionFacets, exclusionFacets} from '../Consts';
import {RefinementListItem, AlgoliaFacet, FacetUpdate} from './types';
import {getFacetLabelByFacetName, renderLabel, arrayEquals} from './utils';

const PREFIX = 'Criteria';

const classes = {
  hidden: `${PREFIX}-hidden`,
};

const StyledListItem = styled('div')(() => ({
  [`&.${classes.hidden}`]: {
    display: 'none',
  },
}));

const CriteriaSelectorContainer = styled('div')`
  display: flex;
`;

const CriteriaSelect = styled(CustomSelect)`
  flex: 0 0 12.5rem;
  margin-right: 1rem;
`;

const CriteriaRefinementListContainer = styled('div')`
  flex: 1;
`;

const RefinementContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledHeader = styled('div')({
  fontWeight: 500,
  fontSize: '1.5rem',
});

type Props = {
  readOnly?: boolean;
  styleOverrides?: Record<string, React.CSSProperties>;
};

const Criteria: FC<Props> = ({readOnly = false, styleOverrides}) => {
  const dispatch = useAppDispatch();
  const criteria = useAppSelector(selectCriteria);
  const noFacetsSelected = useAppSelector(selectNoFacetsSelected);
  const noResultRef = useRef<HTMLDivElement | null>(null);
  const [visibleInclusionFacet, setVisibleInclusionFacet] = useState(inclusionFacets[0]);
  const [visibleExclusionFacet, setVisibleExclusionFacet] = useState(exclusionFacets[0]);

  const updateFacetArray = (prevFacets: Facet[], updateFacets?: Facet[]): Facet[] => {
    const updatedFacetsMap = new Map<string, Facet>();

    prevFacets.forEach((facet) => {
      if (facet.name) {
        updatedFacetsMap.set(facet.name, facet);
      }
    });

    updateFacets?.forEach((updateFacet) => {
      if (updateFacet.name) {
        updatedFacetsMap.set(updateFacet.name, updateFacet);
      }
    });

    return Array.from(updatedFacetsMap.values());
  };

  const updateCriteria = useCallback(
    (updates: Partial<QueryCriteria>) => {
      const nextCriteria = {
        ...criteria,
        ...updates,
        ...(updates.facetInclusions && {
          facetInclusions: updateFacetArray(criteria.facetInclusions, updates.facetInclusions),
        }),
        ...(updates.facetExclusions && {
          facetExclusions: updateFacetArray(criteria.facetExclusions, updates.facetExclusions),
        }),
        resultCount: noFacetsSelected ? 0 : updates.resultCount ?? criteria.resultCount,
      };
      dispatch(setCriteria(nextCriteria));
    },
    [dispatch, criteria, noFacetsSelected]
  );

  const updateFacets = useCallback(
    (attribute: string, values: string[], facetType: string | undefined, isInclusion: boolean) => {
      const update: FacetUpdate = {name: attribute, facetType, values};
      updateCriteria({
        [isInclusion ? 'facetInclusions' : 'facetExclusions']: [update],
      });
    },
    [updateCriteria]
  );

  const handleRefine = (item: RefinementListItem, attribute: AlgoliaFacet['Index']) => {
    const facet = Object.values(Consts.AlgoliaFacet).find((f) => f.Index === attribute);
    if (!facet) return;

    const exclusionValues = item.value.filter((val) => val.startsWith('-'));
    const inclusionValues = item.value.filter((val) => !val.startsWith('-'));

    const currentInclusions =
      criteria.facetInclusions.find((f) => f.name === attribute)?.values || [];
    const currentExclusions =
      criteria.facetExclusions.find((f) => f.name === attribute)?.values || [];

    if (!arrayEquals(inclusionValues, currentInclusions)) {
      updateFacets(attribute, inclusionValues, facet.Type, true);
    }
    if (!arrayEquals(exclusionValues, currentExclusions)) {
      updateFacets(attribute, exclusionValues, facet.Type, false);
    }
  };

  const onDeleteFacetValue = (
    attribute: AlgoliaFacet['Index'],
    valueToDelete: string,
    isInclusion: boolean
  ) => {
    const facetArray = isInclusion ? criteria.facetInclusions : criteria.facetExclusions;
    const facetsMap = new Map(facetArray.map((f) => [f.name, f]));

    const facet = facetsMap.get(attribute);
    if (!facet) {
      return;
    }
    const updatedFacet = {
      ...facet,
      values: facet.values?.filter((x) => x !== valueToDelete) ?? [],
    };
    facetsMap.set(attribute, updatedFacet);
    const nextCriteria = {
      ...criteria,
      [isInclusion ? 'facetInclusions' : 'facetExclusions']: Array.from(facetsMap.values()),
    };
    dispatch(setCriteria(nextCriteria));
  };

  const handleDefaultRefinement = (facet: AlgoliaFacet, inclusive?: boolean) => {
    if (inclusive) {
      return [...(criteria?.facetInclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    } else {
      return [...(criteria?.facetExclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    }
  };

  const getBulkSKUListItem = (inclusive?: boolean) => {
    if (inclusive) {
      return (
        <StyledListItem
          className={
            visibleInclusionFacet &&
            visibleInclusionFacet.label === Consts.AlgoliaFacet.ProductSkuBulk.Label
              ? ''
              : classes.hidden
          }
        >
          <BulkSKUSelector
            attribute={Consts.AlgoliaFacet.ProductSkuBulk.Index}
            onSearchResolve={updateCriteria}
            inclusive
          />
        </StyledListItem>
      );
    } else {
      return (
        <StyledListItem
          className={
            visibleExclusionFacet &&
            visibleExclusionFacet.label === Consts.AlgoliaFacet.ProductSkuBulk.Label
              ? ''
              : classes.hidden
          }
        >
          <BulkSKUSelector
            attribute={Consts.AlgoliaFacet.ProductSkuBulk.Index}
            onSearchResolve={updateCriteria}
          />
        </StyledListItem>
      );
    }
  };

  const getListItem = (facet: AlgoliaFacet, inclusive?: boolean) => {
    const defaultRefinement = handleDefaultRefinement(facet, inclusive);
    if (inclusive) {
      return (
        <StyledListItem
          className={
            visibleInclusionFacet && visibleInclusionFacet.label === facet.Label
              ? ''
              : classes.hidden
          }
        >
          <IncludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit}
            onRefine={handleRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </StyledListItem>
      );
    } else {
      return (
        <StyledListItem
          className={
            visibleExclusionFacet && visibleExclusionFacet.label === facet.Label
              ? ''
              : classes.hidden
          }
        >
          <ExcludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit * 2}
            onRefine={handleRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </StyledListItem>
      );
    }
  };

  const getInclusionCriteria = () => (
    <CriteriaSection title="Add Inclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={inclusionFacets}
          defaultValue={inclusionFacets[0]}
          onChanged={setVisibleInclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier, true)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode, true)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSku, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSpecification, true)}
          {getBulkSKUListItem(true)}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  const getExcludeCriteria = () => (
    <CriteriaSection title="Add Exclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={exclusionFacets}
          defaultValue={exclusionFacets[0]}
          onChanged={setVisibleExclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup)}
          {getListItem(Consts.AlgoliaFacet.ProductSku)}
          {getListItem(Consts.AlgoliaFacet.ProductSpecification)}
          {getBulkSKUListItem()}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  return (
    <>
      <RefinementContainer style={styleOverrides?.refinementContainer}>
        <StyledHeader style={styleOverrides?.headerTitle}>
          Include all SKUs that meet the following criteria:
        </StyledHeader>

        {getInclusionCriteria()}
        <CustomCurrentRefinements
          renderLabel={getFacetLabelByFacetName}
          criteria={inclusionFacets}
          inclusive
          onDelete={onDeleteFacetValue}
          readOnly={readOnly}
        />
      </RefinementContainer>

      <RefinementContainer style={styleOverrides?.refinementContainer}>
        <StyledHeader style={styleOverrides?.headerTitle}>
          Exclude all SKUs that meet the following criteria:
        </StyledHeader>

        {getExcludeCriteria()}
        <CustomCurrentRefinements
          renderLabel={getFacetLabelByFacetName}
          criteria={exclusionFacets}
          inclusive={false}
          onDelete={onDeleteFacetValue}
          readOnly={readOnly}
        />
      </RefinementContainer>

      {criteria?.resultCount === 0 ? (
        <div ref={noResultRef}>
          <NoResultErrorStats resultLabel="SKU" pluralResultLabel="SKUs" />
        </div>
      ) : null}
    </>
  );
};

export default Criteria;
