import React, {useEffect, useState} from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {styled} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {LinkButton} from '../Button';

const PREFIX = 'SearchInputField';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTextField = styled(TextField)(({theme}) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.white.main,
  },
}));

type Props = {
  onSearch: (value: string) => void;
  width?: string;
  iconPosition?: 'start' | 'end';
  validation?: RegExp;
  defaultValue?: string | null;
} & TextFieldProps;

const SearchInputField = ({
  placeholder,
  onSearch,
  width,
  defaultValue = '',
  iconPosition = 'end',
  validation,
  ...textFieldProps
}: Props) => {
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [text, setText] = useState('');

  useEffect(() => {
    setText(defaultValue ?? '');
  }, [defaultValue]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    if (validation && value !== '') {
      if (!validation.test(value)) {
        return;
      }
    }

    setText(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        onSearch(value);
      }, 500)
    );
  };

  return (
    <StyledTextField
      variant="outlined"
      sx={{width: width ?? '100%'}}
      placeholder={placeholder}
      className={classes.root}
      onChange={handleSearch}
      value={text}
      InputProps={{
        startAdornment:
          iconPosition === 'start' ? (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ) : null,
        endAdornment: (
          <InputAdornment position="end">
            {text ? (
              <LinkButton
                type="button"
                onClick={() => {
                  setText('');
                  onSearch('');
                }}
              >
                Clear
              </LinkButton>
            ) : null}
            {iconPosition === 'end' ? <SearchIcon /> : null}
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
};

export {SearchInputField};
