import React, {useState, useCallback, useEffect, useMemo, useRef, FC} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import * as R from 'ramda';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {
  RebateListingFilter,
  setRebatesFilter,
  setRebatesOrder,
  setRebatesPagination,
  setRebatesSearch,
} from '../../../app/rebatesReducer';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import {
  ListPageParamOptions,
  Order,
  Pagination,
  RebateListItem,
  RebateListResponse,
  TableColumn,
} from '../../../types';
import {api, get} from '../../../utils/Request';
import {formatDate} from '../../../utils/DateUtils';
import {createOrderFromParams} from '../../../utils/common';
import {SimpleDataTable} from '../../SimpleDataTable';
import {Status} from '../../Status';
import {SearchInputField} from '../../SearchInputField';
import {TableTabPanel} from '../../TableTabPanel';
import ButtonsContainer from '../../Container/ButtonsContainer';
import {PageTabs} from '../../PageTabs';
import FiltersIndicator from '../../FiltersIndicator';
import ListFilterContainer from '../../ListFilterContainer';
import RebateListingSideFilter from './RebateListingSideFilter';
import DownloadRebateCsvButton from './DownloadRebateCsvButton';

const PREFIX = 'RebateListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
  textFieldRoot: `${PREFIX}-textFieldRoot`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};
const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3.1875rem',
    paddingRight: '2.6875rem',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '125rem',
  },

  [`& .${classes.textFieldRoot}`]: {
    width: '25.9375rem',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '3.125rem 0',
  },
}));

type GetRebatesOptions = ListPageParamOptions<RebateListingFilter>;

type Props = {
  my?: boolean;
};

const RebateListing: FC<Props> = ({my = false}) => {
  const columns: TableColumn<RebateListItem>[] = [
    {
      id: 'description',
      label: 'Description',
      minWidth: 200,
      sortable: true,
    },
    {id: 'agreementDescription', label: 'Agreement', minWidth: 80, sortable: true},
    {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80, sortable: true},
    {
      id: 'financeAccounts',
      label: 'Finance Account',
      minWidth: 150,
      render: (rowData: RebateListItem) => rowData.financeAccounts.join(', '),
    },
    {
      id: 'claimInterval',
      label: 'Claim Freq.',
      minWidth: 80,
      render: (rowData: RebateListItem) =>
        R.prop(
          'label',
          R.find(R.propEq('value', rowData.claimInterval))(Consts.RebateClaimInterval)
        ),
    },
    {
      id: 'startAt',
      label: 'Start Date',
      minWidth: 80,
      render: (rowData: RebateListItem) => formatDate(rowData.startAt),
      sortable: true,
    },
    {
      id: 'endAt',
      label: 'End Date',
      minWidth: 80,
      render: (rowData: RebateListItem) => formatDate(rowData.endAt),
      sortable: true,
    },
    {id: 'ownedByStaffName', label: 'Owner', minWidth: 80, sortable: true},
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      render: (rowData: RebateListItem) => <Status status={rowData.status} />,
      sortable: true,
    },
  ];

  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const {filterSelection, pagination, searchText, order} = useAppSelector((state) => state.rebates);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<RebateListItem[]>([]);
  const [openSideFilters, setOpenSideFilters] = useState(false);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const filterRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const setFilterSelection = (newFilters: RebateListingFilter) => {
    dispatch(setRebatesFilter(newFilters));
  };

  const setPagination = (pagination: Pagination) => {
    dispatch(setRebatesPagination(pagination));
  };

  const setSearchText = (searchText: string | null) => {
    dispatch(setRebatesSearch(searchText));
  };

  const setOrder = (newOrder: Order) => {
    dispatch(setRebatesOrder(newOrder));
  };

  const setData = useCallback((responseData: RebateListResponse) => {
    const rebates = responseData.data;
    setRows(rebates);
    setLoading(false);
    setPagination({
      totalPages: responseData.totalPages,
      currentPage: responseData.currentPage,
      pageSize: responseData.pageSize,
      totalCount: responseData.totalCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRebates = useCallback(
    (params: GetRebatesOptions, singleOrderBy?: boolean) => {
      setLoading(true);
      get(api(Consts.Api.Rebates), {
        params: {
          entityCode: config.entityCode,
          ownedByStaffCode: my ? loggedInStaffCode : null,
          ...params,
        },
        paramsSerializer: (params: GetRebatesOptions) => {
          const {orderBy, ...rest} = params;
          const nextParams =
            singleOrderBy || !isDefaultOrder
              ? params
              : {...rest, orderBy: [orderBy, 'description:asc']};
          const result = qs.stringify(nextParams, {skipNulls: true, arrayFormat: 'repeat'});
          return result;
        },
      })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    },
    [my, setData, loggedInStaffCode, isDefaultOrder]
  );

  const mapGetRebatesParams = (options: Partial<GetRebatesOptions>): GetRebatesOptions => {
    const claimVendorCode = options?.claimVendor?.code ?? undefined;
    const claimFrequency = options?.claimFrequency ?? undefined;
    const startAt = options?.startAt ?? undefined;
    const endAt = options?.endAt ?? undefined;
    const ownedByStaffCode = my ? loggedInStaffCode : options?.owner?.code ?? undefined;
    const contractStatus = options?.status ?? undefined;
    const financeAccountId = options?.financeAccount?.id ?? undefined;

    const searchText = options?.searchText ?? undefined;
    const totalCount = options?.totalCount ?? undefined;
    const totalPages = options?.totalPages ?? undefined;
    const pageSize = options?.pageSize ?? undefined;
    const currentPage = options?.currentPage ?? undefined;
    const orderBy =
      options?.orderBy && options?.orderByDirection
        ? `${options.orderBy}:${options.orderByDirection}`
        : undefined;

    const queryParams = [
      {name: 'claimVendorCode', value: claimVendorCode},
      {name: 'claimFrequency', value: claimFrequency},
      {name: 'startAt', value: startAt},
      {name: 'endAt', value: endAt},
      {name: 'ownedByStaffCode', value: ownedByStaffCode},
      {name: 'status', value: contractStatus},
      {name: 'financeAccountId', value: financeAccountId},
      {name: 'searchText', value: searchText},
      {name: 'totalPages', value: totalPages},
      {name: 'pageSize', value: pageSize},
      {name: 'currentPage', value: currentPage},
      {name: 'totalCount', value: totalCount},
      {name: 'orderBy', value: orderBy},
    ];

    const result = queryParams.reduce((acc, x) => {
      if (x.value) {
        acc[x.name] = x.value;
      }
      return acc;
    }, {} as Record<string, any>);

    return result as GetRebatesOptions;
  };

  const initialLoad = useRef(false);
  useEffect(() => {
    if (initialLoad.current) return;
    initialLoad.current = true;
    const request = mapGetRebatesParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...order,
    });
    getRebates(request);
  });

  const handleFilterByStatusChange = (_: any, newStatus: string) => {
    setFilterSelection({...filterSelection, status: newStatus});
    setPagination({...pagination, currentPage: 1});
    const request = mapGetRebatesParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...order,
      status: newStatus,
      currentPage: 1,
    });
    getRebates(request);
  };

  const handleRebatesSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    setPagination({...pagination, currentPage: 1});

    const request = mapGetRebatesParams({
      searchText: newSearchText,
      ...pagination,
      ...filterSelection,
      ...order,
      currentPage: 1,
    });
    getRebates(request);
  };

  const handlePagination = (newPagination: Partial<Pagination>) => {
    const request = mapGetRebatesParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...newPagination,
      ...order,
    });

    getRebates(request);
  };

  const handleOrderBy = (newOrderBy: string) => {
    setIsDefaultOrder(false);
    const newOrder = createOrderFromParams(newOrderBy, setOrder);
    const request = mapGetRebatesParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...(newOrder ? newOrder : {}),
    });
    getRebates(request, true);
  };

  const viewRebate = (id: number, ctrlKeyPressed: boolean) => {
    const routerPath = Consts.RouterPath.RebateSummary.replace(':id', id.toString());
    if (ctrlKeyPressed) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  const handleUpdateFilters = (selection: RebateListingFilter) => {
    setFilterSelection(selection);
    const request = mapGetRebatesParams({
      searchText,
      ...pagination,
      currentPage: 1,
      ...filterSelection,
      ...order,
      ...selection,
    });
    getRebates(request);
  };

  const filterCount = useMemo(() => {
    const {status, ...relevantFilters} = filterSelection;

    if (my) {
      const {owner, ...selectionWithoutOwners} = relevantFilters;
      const result = R.filter((x) => x, R.values(selectionWithoutOwners)).length;
      return result;
    } else {
      return R.filter((x) => x, R.values(relevantFilters)).length;
    }
  }, [my, filterSelection]);

  const status = Consts.RebatesFilterStatus(my).filter(
    (x) => x.value === filterSelection?.status || null
  )[0];
  const statusTitle = status?.label ?? null;
  const statusValue = status?.value ?? null;

  return (
    <Root className={classes.root} ref={filterRef}>
      <PageTabs
        title={statusTitle}
        value={statusValue}
        onChange={handleFilterByStatusChange}
        tabsList={Consts.RebatesFilterStatus(my)}
      />
      <Grid container wrap="nowrap">
        {openSideFilters && (
          <Grid item>
            <RebateListingSideFilter
              my={my}
              defaultSelection={filterSelection}
              onClose={() => setOpenSideFilters(false)}
              onChange={handleUpdateFilters}
            />
          </Grid>
        )}

        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                <FiltersIndicator count={filterCount} onClick={toggleSideFilters} />
                <SearchInputField
                  placeholder="Search rebates"
                  width="26rem"
                  onSearch={handleRebatesSearch}
                  defaultValue={searchText ?? ''}
                />
              </Stack>
              <ButtonsContainer>
                <DownloadRebateCsvButton
                  my={my}
                  requestData={mapGetRebatesParams({
                    searchText,
                    ...pagination,
                    ...filterSelection,
                    ...order,
                  })}
                />
              </ButtonsContainer>
            </ListFilterContainer>
            <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
              <SimpleDataTable
                columns={columns}
                rows={rows}
                pagination={pagination}
                onChangePage={handlePagination}
                onChangePageSize={handlePagination}
                onOrderBy={(o) => handleOrderBy(o.orderBy)}
                sortOrderBy={order.orderBy}
                sortOrder={order.orderByDirection}
                highlightColumn={order.orderBy}
                onRowClick={viewRebate}
              />
            </TableTabPanel>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
};

export default RebateListing;
