import Consts from '../../../../../app/Consts';
import {productSkuFacet} from '../Consts';
import {AlgoliaFacet} from './types';

export const getFacetLabelByFacetName = (label: string, attribute: AlgoliaFacet['Index']) => {
  const facet = Object.values(Consts.AlgoliaFacet).find(
    (facet: AlgoliaFacet) => facet.Composited && facet.Index === attribute
  );

  if (facet) {
    return renderLabel(facet, label);
  }
  return label;
};

export const renderLabel = (facet: AlgoliaFacet, label: string) => {
  if (facet.Index === productSkuFacet) {
    return label.replace('--', ' ');
  } else if (facet.Composited) {
    return label.split('--')[1];
  }
  return label;
};

export const arrayEquals = (a: string[], b: string[]) => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};
