import React, {useContext, useEffect, useCallback, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as R from 'ramda';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import {api, get, post, del} from '../../../utils/Request';
import {formatDate, getFormattedDateTime} from '../../../utils/DateUtils';
import {getDisplayAmount} from '../../../utils/TypesStatusUtils';
import {
  ContractAgreementSummaryResponse,
  RebateValue,
  Pagination,
  QueryCriteria,
  TableColumn,
  EntityActionType,
} from '../../../types';
import {
  EditIcon,
  DeleteIcon,
  MoneyGraphIcon,
  DuplicateIcon,
  CreateContractAgreementIcon,
  ExternalLinkIcon,
} from '../../../components/Icons';
import {HeadingComponent} from '../../../components/HeadingComponent';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import TabsComponent from '../../../components/TabsComponent';
import {TableTabPanel} from '../../../components/TableTabPanel';
import ButtonsContainer from '../../../components/Container/ButtonsContainer';
import Button from '../../../components/Button/Button';
import StaticButton from '../../../components/Button/StaticButton';
import {EmptyDataMessageBox} from '../../../components/EmptyDataMessageBox';
import OutlinedButton from '../../../components/Button/OutlinedButton';
import {IconLabelButtonProps} from '../../../components/Button/IconLabelButton';
import {Status} from '../../../components/Status';
import {SimpleDataTable} from '../../../components/SimpleDataTable';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import ProductSelector from '../../../components/Form/Agolia/ProductSelector';
import {DeleteActionButton, EditActionButton} from '../../../components/Table/ActionIconButton';
import {
  DeleteAgreementOrRebateConfirmModal,
  DeleteAgreementOrRebateWithActiveClaimConfirmModal,
  DeleteAgreementorRebateWithClaimForbiddenModal,
} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import {ErrorBox} from '../../../components/Alert';

const PREFIX = 'ContractAgreementSummary';

const classes = {
  root: `${PREFIX}-root`,
  pageFlexColumn: `${PREFIX}-pageFlexColumn`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  fixWidthContainerSm: `${PREFIX}-fixWidthContainerSm`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  tabPanelContainerRoot: `${PREFIX}-tabPanelContainerRoot`,
  flexContentCentered: `${PREFIX}-flexContentCentered`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.pageFlexColumn}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.contentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 26px 0.5rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
  },

  [`& .${classes.fixWidthContainerSm}`]: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.tabPanelContainerRoot}`]: {
    width: '100%',
    maxWidth: '100%',
    padding: '85px 26px 100px',
  },

  [`& .${classes.flexContentCentered}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '40px',
  },
}));

const DownloadCsvButton = styled(Button)`
  width: 220px;
  height: 58px;
  font-weight: 500;
  background-color: #000000;
  color: #ffffff;
  margin-right: 0;
`;

const AddNewValueButton = styled(OutlinedButton)`
  width: 220px;
  height: 58px;
`;

const FlexGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RebateActionsContainer = styled('div')`
  display: flex;
`;

const EditButton = styled(EditActionButton)`
  margin-right: 15px;
`;

const DeleteButton = styled(DeleteActionButton)`
  margin-right: 10px;
`;

const ContractAgreementSummary = () => {
  const {id = ''} = useParams();
  const navigate = useNavigate();
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const [summaryData, setSummaryData] = useState<ContractAgreementSummaryResponse>();
  const [summaryDetails, setSummaryDetails] = useState<{title: string; value: string}[]>([]);
  const [pageReady, setPageReady] = useState(false);
  const [rows, setRows] = useState<RebateValue[]>([]);
  const [pagination, setPagination] = useState<Pagination>(Consts.DefaultPagination);
  const [selectedTab, setSelectedTab] = useState('AgreementInformation');
  const [productCriteria, setProductCriteria] = useState<QueryCriteria | null>(null);
  const [locationCriteria, setLocationCriteria] = useState<QueryCriteria | null>(null);
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [openDeleteWithClaimModal, setOpenDeleteWithClaimModal] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState(null);
  const [entityActionType, setEntityActionType] = useState<EntityActionType>(
    EntityActionType.ContractAgreement
  );
  const [referenceId, setReferenceId] = useState(id);
  const [ctrlKey, setCtrKey] = useState(false);

  const claimPagination = {
    ...Consts.DefaultPagination,
    pageSize: Consts.PageSize[0],
  };
  const columns: TableColumn<RebateValue>[] = [
    {
      id: 'rebateDesc',
      label: 'Rebate Description',
      minWidth: 395,
      render: (rowData: RebateValue) => (
        <FlexGroup>
          <span>{rowData.description}</span>
          {!rowData.isDeleted && (
            <RebateActionsContainer>
              <EditButton
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                  event.stopPropagation();
                  event.persist();
                  onEdit(EntityActionType.Rebate, rowData.id, event);
                }}
              />
              <DeleteButton
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  event.persist();
                  setReferenceId(`${rowData.id}`);
                  onDelete(EntityActionType.Rebate, rowData.id);
                }}
              />
            </RebateActionsContainer>
          )}
        </FlexGroup>
      ),
      cellOnClick: (rowData: RebateValue, index: number, ctrlKeyPressed: boolean) => {
        openRebateSummary(`${rowData.id}`, ctrlKeyPressed);
      },
    },
    {id: 'id', label: 'Rebate ID', minWidth: 120},
    {id: 'externalId', label: 'External ID', minWidth: 120},
    {id: 'type', label: 'Rebate Type', minWidth: 120},
    {
      id: 'writebackType',
      label: 'Writebacks',
      minWidth: 120,
      render: (rowData: RebateValue) =>
        R.prop('label', R.find(R.propEq('value', rowData.writebackType))(Consts.WritebackType)),
    },
    {
      id: 'financeAccounts',
      label: 'Finance Account',
      minWidth: 180,
      render: (rowData: RebateValue) => <span>{rowData?.financeAccounts?.join(', ')}</span>,
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData: RebateValue) =>
        R.prop(
          'label',
          R.find(R.propEq('value', rowData.claimFrequency))(Consts.RebateClaimInterval)
        ),
    },
    {
      id: 'excludeReturns',
      label: 'Returns',
      render: (rowData: RebateValue) => (rowData.excludeReturns ? 'Excluded' : 'Included'),
    },
    {
      id: 'amount',
      label: 'Value',
      minWidth: 120,
      render: (rowData: RebateValue) => getDisplayAmount(rowData.amountType, rowData.amount),
    },
    {
      id: 'startAt',
      label: 'Start Date',
      minWidth: 150,
      render: (rowData: RebateValue) => <span>{formatDate(rowData.startAt)}</span>,
    },
    {
      id: 'endAt',
      label: 'End Date',
      minWidth: 150,
      render: (rowData: RebateValue) => <span>{formatDate(rowData.endAt)}</span>,
    },
    {
      id: 'approvalNumber',
      label: 'Supplier Approval No.',
      minWidth: 180,
      render: (rowData: RebateValue) => (
        <span>{rowData.supplierApprovalNumber ? rowData.supplierApprovalNumber : ''}</span>
      ),
    },
    {id: 'gstType', label: 'GST', minWidth: 120},
    {
      id: 'sku',
      label: 'SKUs',
      minWidth: 150,
      render: (rowData: RebateValue) => renderSkuColumn(rowData),
    },
    {
      id: 'location',
      label: 'Location',
      minWidth: 150,
      render: (rowData: RebateValue) => renderLocationColumn(rowData),
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      render: (rowData: RebateValue) => <Status status={rowData.status} />,
    },
  ];

  const tabs = [
    {
      value: 'AgreementInformation',
      label: 'Agreement Information',
    },
  ];

  const getHeadingButtons = (summaryData: ContractAgreementSummaryResponse) => {
    const buttons: IconLabelButtonProps[] = [
      {
        label: 'Duplicate Agreement',
        icon: <DuplicateIcon style={{width: '21px'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('duplicate', event),
        role: Consts.UserRoleEnum.AddOrUpdateContractAgreements,
      },
    ];
    if (!summaryData.isDeleted) {
      buttons.push(
        {
          label: 'Edit Agreement',
          icon: <EditIcon style={{width: '21px'}} />,
          style: {color: '#626262', fontWeight: 400},
          onClick: (event) => handleNavAction('edit', event),
          role: Consts.UserRoleEnum.AddOrUpdateContractAgreements,
        },
        {
          label: 'Delete Agreement',
          icon: <DeleteIcon style={{width: '21px'}} />,
          style: {color: '#d0021b', fontWeight: 400},
          onClick: (event) => handleNavAction('delete', event),
          role: Consts.UserRoleEnum.DeleteContractAgreements,
          disabled: !summaryData.isDeletable,
          disabledText: 'Cannot be deleted as there are related invoiced and/or raised claim(s).',
        }
      );
    }
    return buttons;
  };

  const setData = useCallback(
    (responseData: ContractAgreementSummaryResponse) => {
      setSummaryData(responseData);
      setSummaryDetails([
        {
          title: 'Claim Vendor',
          value: responseData.claimVendorName,
        },
        {
          title: 'Start Date',
          value: getFormattedDateTime(responseData.startAt),
        },
        {
          title: 'End Date',
          value: getFormattedDateTime(responseData.endAt ?? ''),
        },
        {
          title: 'Last Modified Rebate ID',
          value: `${responseData.lastModifiedRebateId ?? ''}`,
        },
        {
          title: 'Last Modified Date',
          value: getFormattedDateTime(responseData.lastModifiedAt),
        },
        {
          title: 'Last Modified By',
          value: `${responseData.lastModifiedByStaffCode} - ${responseData.lastModifiedByStaffName}`,
        },
      ]);
      setRows(responseData.rebateValues.data);
      setPagination({
        pageSize: responseData.rebateValues.pageSize,
        currentPage: responseData.rebateValues.currentPage,
        totalCount: responseData.rebateValues.totalCount,
        totalPages: responseData.rebateValues.totalPages,
      });
      setPageReady(true);
      hideLoading();
    },
    [hideLoading]
  );

  const getPageData = useCallback(() => {
    showLoading();
    get(api(Consts.Api.ContractAgreementsSummary.replace(':id', id)))
      .then(({data}: AxiosResponse<ContractAgreementSummaryResponse>) => {
        setData(data);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [hideLoading, id, setData, showLoading]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const editRebate = (
    rebateId: number | string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const routerPath = Consts.RouterPath.EditRebate.replace(':id', `${rebateId}`);
    if (event.ctrlKey) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };
  const editContractAgreement = () => {
    navigate(Consts.RouterPath.EditContractAgreement.replace(':id', id));
  };

  const openRebateSummary = (rebateId: string, ctrlKeyPressed: boolean) => {
    const routerPath = Consts.RouterPath.RebateSummary.replace(':id', rebateId);
    if (ctrlKeyPressed) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };

  const deleteRebate = (rebateId: string) => {
    showLoading();
    del(api(Consts.Api.Rebate.replace(':id', rebateId)))
      .then(() => {
        hideLoading();
        getPageData();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const handleNavAction = (
    type: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.persist();
    setCtrKey(event.ctrlKey);
    showLoading();
    setReferenceId(id);
    if (type === 'duplicate') {
      const requestData = {
        agreementId: id,
        createdByStaffCode: loggedInStaffCode,
      };
      post(api(Consts.Api.ContractAgreementDuplicate), requestData)
        .then((response) => {
          hideLoading();
          navigate(Consts.RouterPath.EditContractAgreement.replace(':id', response.data.id));
        })
        .catch((error) => {
          hideLoading();
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    } else if (type === 'edit') {
      onEdit(EntityActionType.ContractAgreement, id, event);
    } else if (type === 'delete') {
      onDelete(EntityActionType.ContractAgreement, id);
    }
  };
  const onDelete = (entityActionType: EntityActionType, refId: number | string) => {
    const forContractAgreement = entityActionType === EntityActionType.ContractAgreement;
    setEntityActionType(entityActionType);
    const apiRoute = forContractAgreement
      ? Consts.Api.ContractAgreementClaims
      : Consts.Api.RebateClaims;
    get(api(apiRoute.replace(':id', `${refId}`)), {
      params: {
        ...claimPagination,
      },
    })
      .then((response) => {
        hideLoading();
        if (response.data.totalCount === 0) {
          setOpenDeleteConfirmModal(true);
        } else {
          if (response.data.totalRaisedCount > 0) {
            setOpenDeleteWithClaimForbiddenModal(true);
          } else {
            setOpenDeleteWithClaimModal(true);
          }
        }
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const onEdit = (
    entityActionType: EntityActionType,
    refId: string | number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setEntityActionType(entityActionType);
    setReferenceId(`${refId}`);
    setCtrKey(event.ctrlKey);
    const forContractAgreement = entityActionType === EntityActionType.ContractAgreement;
    const apiRoute = forContractAgreement
      ? Consts.Api.ContractAgreementClaims
      : Consts.Api.RebateClaims;
    get(api(apiRoute.replace(':id', `${refId}`)), {
      params: {
        status: Consts.ClaimStatusEnum.Raised,
        ...claimPagination,
      },
    })
      .then((response) => {
        const data = response.data;
        hideLoading();
        if (data.totalCount === 0) {
          forContractAgreement ? editContractAgreement() : editRebate(`${refId}`, event);
        } else {
          setRaisedClaimsResponse(data);
          setOpenEditModal(true);
        }
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };
  const deleteContractAgreement = () => {
    showLoading();
    del(api(Consts.Api.ContractAgreement.replace(':id', id)))
      .then(() => {
        hideLoading();
        navigate(Consts.RouterPath.MyContractAgreements);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const downloadRebateValues = () => {
    get(api(Consts.Api.ContractAgreementRebateValuesDownload.replace(':id', id)), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = 'RebatesValues.csv';
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const handleAddRebate = () => {
    navigate(Consts.RouterPath.CreateRebate.replace(':agreementId', id));
  };

  const refreshData = (paginationData: Partial<Pagination>) => {
    showLoading();
    get(api(Consts.Api.ContractAgreementsRebateValues.replace(':id', id)), {
      params: {...Consts.DefaultPagination, ...paginationData},
    })
      .then(({data}) => {
        setRows(data.data);
        setPagination({
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
        });
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const renderSkuColumn = (rowData: RebateValue) => {
    return rowData.productCriteria ? (
      <StaticButton
        startIcon={<ExternalLinkIcon />}
        sx={{
          height: '53px',
          fontSize: '15px',
        }}
        onClick={() => handleProductSelector(rowData.productCriteria)}
      >
        {rowData.productCriteria.resultCount} SKUs
      </StaticButton>
    ) : rowData.uploadedProductCount && rowData.uploadedProductCount > 0 ? (
      <StaticButton
        startIcon={<GetAppIcon />}
        sx={{
          height: '53px',
          fontSize: '15px',
        }}
        onClick={() => downloadSkus(rowData.id)}
      >
        {rowData.uploadedProductCount} SKUs
      </StaticButton>
    ) : null;
  };
  const downloadSkus = (rebateId: number) => {
    showLoading();
    get(api(Consts.Api.RebateProductDownload.replace(':id', `${rebateId}`)), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = `Skus of rebate ${rebateId}.xlsx`;
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const renderLocationColumn = (rowData: RebateValue) => {
    return (
      <StaticButton
        startIcon={<ExternalLinkIcon />}
        sx={{
          height: '53px',
          fontSize: '15px',
        }}
        onClick={() => handleLocationSelector(rowData.locationCriteria)}
      >
        {rowData.locationCriteria
          ? `${rowData.locationCriteria.resultCount} locations`
          : 'All locations'}
      </StaticButton>
    );
  };

  const handleProductSelector = (data: QueryCriteria | null) => {
    setProductCriteria(data);
    setProductSelectorOpen(true);
  };

  const handleLocationSelector = (data: QueryCriteria | null) => {
    setLocationCriteria(data);
    setLocationSelectorOpen(true);
  };

  return (
    <Root className={classes.root}>
      {pageReady && !!summaryData ? (
        <div className={classes.pageFlexColumn}>
          {summaryData.isDeleted && (
            <ErrorBox type={AlertType.Info}>
              This contract agreement is deleted - it will be removed overnight
            </ErrorBox>
          )}
          <Container maxWidth={false}>
            <HeadingComponent
              headingIcon={<CreateContractAgreementIcon />}
              headingLabel="Contract Agreement"
              buttons={getHeadingButtons(summaryData)}
              buttonGroupStyle={{width: '620px'}}
              style={summaryData.isDeleted ? {paddingTop: '0px'} : {}}
            />

            <Paper className={classes.contentWrapper}>
              <SummaryDetailsComponent
                id={summaryData.id}
                details={summaryDetails}
                status={summaryData.status}
                desc={summaryData.description}
                staffCode={summaryData.ownedByStaffCode}
                staffName={summaryData.ownedByStaffName}
                groupClaimsOnSingleInvoice={summaryData.groupClaimsOnSingleInvoice}
                type="Agreement"
              />

              <div className={classes.fixWidthContainerSm}>
                <SplitIconAmountComponent
                  icon={<MoneyGraphIcon />}
                  label="Total Amount Accrued"
                  amount={summaryData.amount}
                  taxAmount={summaryData.accrualTaxAmount}
                  gstType={summaryData.gstType}
                />
              </div>
            </Paper>
          </Container>

          <div>
            <TabsComponent
              tabs={tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />

            <TableTabPanel
              value={selectedTab}
              index="AgreementInformation"
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <div className={classes.flexContentCentered}>
                  <Typography variant="h5">Rebate Values</Typography>
                  {rows.length ? (
                    <ButtonsContainer>
                      <AddNewValueButton onClick={handleAddRebate}>
                        Add New Rebate Value
                      </AddNewValueButton>
                      <DownloadCsvButton onClick={downloadRebateValues}>
                        Download CSV Report
                      </DownloadCsvButton>
                    </ButtonsContainer>
                  ) : null}
                </div>

                {rows.length ? (
                  <SimpleDataTable
                    columns={columns}
                    rows={rows}
                    pagination={pagination}
                    onChangePage={refreshData}
                    onChangePageSize={refreshData}
                    firstColumnSticky={true}
                    darkTheme={true}
                  />
                ) : (
                  <EmptyDataMessageBox
                    icon={<CreateContractAgreementIcon />}
                    buttonLabel="Add your first rebate"
                    title="There are no rebates in this agreement yet."
                    subtitle="To start accruing, add a rebate for each value in your agreement."
                    onClick={() => handleAddRebate()}
                  />
                )}
              </Container>
            </TableTabPanel>
          </div>

          {productSelectorOpen ? (
            <ProductSelector
              fullScreen
              productCriteria={productCriteria}
              open={productSelectorOpen}
              defaultBuyerDepartments={[]}
              defaultSuppliers={[]}
              handleClose={() => setProductSelectorOpen(false)}
              onApplyCriteria={() => {
                setProductCriteria(null);
              }}
              readOnly={true}
            />
          ) : null}

          {locationSelectorOpen ? (
            <LocationSelector
              fullScreen
              locationCriteria={locationCriteria}
              open={locationSelectorOpen}
              handleClose={() => setLocationSelectorOpen(false)}
              readOnly
            />
          ) : null}
          <DeleteAgreementOrRebateConfirmModal
            entityActionType={entityActionType}
            open={openDeleteConfirmModal}
            onCancel={() => setOpenDeleteConfirmModal(false)}
            onOk={() => {
              setOpenDeleteConfirmModal(false);
              entityActionType === EntityActionType.ContractAgreement
                ? deleteContractAgreement()
                : deleteRebate(referenceId);
            }}
          />
          <DeleteAgreementOrRebateWithActiveClaimConfirmModal
            entityActionType={entityActionType}
            open={openDeleteWithClaimModal}
            onCancel={() => setOpenDeleteWithClaimModal(false)}
            onOk={() => {
              setOpenDeleteWithClaimModal(false);
              setOpenDeleteConfirmModal(true);
            }}
          />
          <DeleteAgreementorRebateWithClaimForbiddenModal
            entityActionType={entityActionType}
            open={openDeleteWithClaimForbiddenModal}
            onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
          />
          <EditAgreementOrRebateConfirmModal
            entityActionType={entityActionType}
            referenceId={referenceId}
            ctrlKey={ctrlKey}
            open={openEditModal}
            claimsResponse={raisedClaimsResponse}
            onOk={() => {
              setOpenEditModal(false);
            }}
            onCancel={() => setOpenEditModal(false)}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default ContractAgreementSummary;
