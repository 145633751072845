import React, {FC, useEffect, useState, useContext, useCallback} from 'react';
import {useFormikContext} from 'formik';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import {api, get, put} from '../../utils/Request';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import {
  DealFormValues,
  DealFormBag,
  FinanceDetailsResponse,
  EditFinanceDetailsRequest,
  EntityActionType,
} from '../../types';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import InputField from '../Form/InputField';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FinanceAccount from '../Form/FinanceAccount/FinanceAccount';
import SelectField from '../Form/SelectField';
import {filterClaimInterval} from './StepFinanceDetailsValidationSchema';
import CheckboxField from '../Form/CheckboxField';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import {dealFormDisabled} from './dealFormUtils';
import {ErrorBox} from '../Alert';

const DaysPastExpiryDateLabel = styled('span')`
  font-style: italic;
  margin-right: 20px;
`;
const ExcludeReturnsLabel = styled('div')`
  > span {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    padding-left: 5px;
  }
`;

export const getNextRequestData = (values: DealFormValues): EditFinanceDetailsRequest => ({
  comment: values.comment,
  merchandisingPurchaseOrderNumber: values.merchandisingPurchaseOrderNumber,
  externalPromotionIds: values.externalPromotionIds,
  claimDaysAfterExpiry: values.claimDaysAfterExpiry,
  claimInterval: values.claimInterval ?? Consts.DealClaimIntervalEnum.EndOfRebate,
  supplierApprovalNumber: values.supplierApprovalNumber,
  splitAccountAmountType: values.splitAccountAmountType,
  excludeReturns: Boolean(values.excludeReturns),
  splitAccounts: values.splitAccounts.map((x) => {
    return {
      accountId: x.accountId,
      additionalInfo: x.additionalInfo,
      amount: x.amount,
      hasAdditionalField: x.hasAdditionalField,
    };
  }),
});

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  handleBack: (values: DealFormValues, bag: DealFormBag) => void;
  handleNext: (values: DealFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

const StepFinanceDetails: FC<Props> = ({step, title, totalStep, handleBack, handleNext}) => {
  const bag = useFormikContext<DealFormValues>();
  const {
    values: {
      claimInterval,
      claimVendor,
      dealType,
      financeAccountCleared,
      hasActiveClaim,
      hasRaisedClaim,
      id,
      splitAccounts,
      status,
      totalAmount,
    },
    setFieldValue,
  } = bag;
  const disabled = dealFormDisabled(bag.values);
  const [accountOptions, setAccountOptions] = useState<FinanceDetailsResponse['accountOptions']>(
    []
  );

  const {showLoading, hideLoading} = useContext(LoadingContext);

  const hasEstimateAccounts = splitAccounts.some(
    (account) => account.accountName && account.accountName.includes('Estimate')
  );
  const isNarta = claimVendor?.code?.includes(Consts.Narta.Code);

  // only set default values if the deal is new
  // capture changes in splitAccounts but don't override default value if Narta
  useEffect(() => {
    if (hasEstimateAccounts || isNarta) {
      setFieldValue('claimInterval', Consts.DealClaimIntervalEnum.DoNotClaim);
      setFieldValue('claimDaysAfterExpiry', '');
    } else if (!isNarta) {
      setFieldValue('claimInterval', Consts.DealClaimIntervalEnum.EndOfRebate);
    }
  }, [hasEstimateAccounts, setFieldValue, isNarta, id]);

  const updateFormValues = useCallback(
    (financeDetailsResponse: FinanceDetailsResponse) => {
      setFieldValue('supplierApprovalNumber', financeDetailsResponse.supplierApprovalNumber ?? '');
      if (financeDetailsResponse.claimInterval) {
        setFieldValue('claimInterval', financeDetailsResponse.claimInterval);
      }
      setFieldValue(
        'splitAccountAmountType',
        financeDetailsResponse.splitAccountAmountType ??
          Consts.SplitAccountAmountTypeEnum.Percentage
      );
      setFieldValue('claimDaysAfterExpiry', financeDetailsResponse.claimDaysAfterExpiry ?? '');
      const shouldExcludeReturns =
        financeDetailsResponse.excludeReturns === null
          ? dealType === Consts.StandardDealTypeEnum.Sales &&
            status === Consts.AgreementStatusEnum.Draft
          : financeDetailsResponse.excludeReturns;
      setFieldValue('excludeReturns', shouldExcludeReturns);
      setFieldValue('comment', financeDetailsResponse.comment ?? '');
      setFieldValue(
        'merchandisingPurchaseOrderNumber',
        financeDetailsResponse.merchandisingPurchaseOrderNumber ?? ''
      );
      setFieldValue('externalPromotionIds', financeDetailsResponse.externalPromotionIds ?? '');
      setFieldValue('totalAmount', financeDetailsResponse.totalAmount);
      setAccountOptions(financeDetailsResponse.accountOptions);
      setFieldValue('splitAccounts', financeDetailsResponse.splitAccounts || []);
    },
    [setFieldValue, dealType, status]
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    showLoading();
    get(api(Consts.Api.DealFinanceDetails.replace(':id', `${id}`)))
      .then((response: AxiosResponse<FinanceDetailsResponse>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [id, hideLoading, showLoading, updateFormValues]);

  async function goToNext(values: DealFormValues, bag: DealFormBag) {
    const requestData = getNextRequestData(values);
    showLoading();
    put(api(Consts.Api.DealFinanceDetails.replace(':id', `${id}`)), requestData)
      .then(() => {
        alertService.clear(defaultAlertId);
        setFieldValue('financeAccountCleared', false);
        handleNext({...values, financeAccountCleared: false}, bag);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }
  function onDealClaimIntervalChanged(claimInterval: string | null) {
    if (claimInterval !== Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
      setFieldValue('claimDaysAfterExpiry', '');
    }
  }

  const canSplit = dealType === Consts.StandardDealTypeEnum.FixedAmount;
  return (
    <>
      {hasEstimateAccounts ? (
        <ErrorBox
          data-testid="estimate-account-info-alert"
          type={AlertType.Info}
          sx={({palette}) => ({p: '0 1rem', mb: '3rem', border: `1px solid ${palette.blue.main}`})}
        >
          Deals assigned to estimate accounts cannot be claimed.
        </ErrorBox>
      ) : null}
      {isNarta ? (
        <ErrorBox
          data-testid="narta-claim-info-alert"
          type={AlertType.Info}
          sx={({palette}) => ({p: '0 1rem', mb: '3rem', border: `1px solid ${palette.blue.main}`})}
        >
          Narta claims are handled outside of RMS. If you want to claim through RMS please update
          the claim dropdown section below.
        </ErrorBox>
      ) : null}
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          entityActionType={EntityActionType.Deal}
        />
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>Which finance account is the money going to?</FieldLabel>
          <FinanceAccount
            name="splitAccounts"
            accountOptions={accountOptions}
            canSplit={canSplit}
            totalAmount={totalAmount}
            agreementType={Consts.AgreementTypeEnum.Deal}
            setDefaultIfEmpty={
              status === Consts.AgreementStatusEnum.Draft && !financeAccountCleared
            }
            disabled={disabled.fields.splitAccounts}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth htmlFor="claimInterval">
            When is this deal going to be claimed?
          </FieldLabel>
          <SelectField
            name="claimInterval"
            id="claimInterval"
            fullWidth
            options={filterClaimInterval(dealType)}
            defaultOption={Consts.DealClaimInterval.find(
              (option) => option.value === claimInterval
            )}
            onChanged={onDealClaimIntervalChanged}
            disabled={disabled.fields.claimInterval}
          />
          {claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry ? (
            <Grid container sx={{marginTop: '20px'}} alignItems="baseline">
              <DaysPastExpiryDateLabel>Number of days past expiry date:</DaysPastExpiryDateLabel>
              <InputField
                id="claimDaysAfterExpiry"
                name="claimDaysAfterExpiry"
                disabled={disabled.fields.claimDaysAfterExpiry}
              />
            </Grid>
          ) : null}
          <CheckboxField
            id="excludeReturns"
            name="excludeReturns"
            style={{marginTop: '20px'}}
            disabled={disabled.fields.excludeReturns}
            label={
              <ExcludeReturnsLabel>
                Exclude returns when claiming
                <span>(*Note - including returns will reduce margin)</span>
              </ExcludeReturnsLabel>
            }
          />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="supplierApprovalNumber" fullWidth>
            Supplier Approval Number <OptionalIndicator />
          </FieldLabel>
          <InputField id="supplierApprovalNumber" name="supplierApprovalNumber" fullWidth />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="merchandisingPurchaseOrderNumber" fullWidth>
            Merch PO Number / Internal ID <OptionalIndicator />
          </FieldLabel>
          <InputField
            id="merchandisingPurchaseOrderNumber"
            name="merchandisingPurchaseOrderNumber"
            fullWidth
          />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="externalPromotionIds" fullWidth>
            Advertising / STACK Promo ID <OptionalIndicator />
          </FieldLabel>
          <InputField id="externalPromotionIds" name="externalPromotionIds" fullWidth />
        </FieldGrid>

        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="comment" fullWidth>
            Comments <OptionalIndicator />
          </FieldLabel>
          <InputField id="comment" name="comment" fullWidth multiline minRows={4} maxRows={10} />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={goToNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepFinanceDetails;
