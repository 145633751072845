import React, {FC, useEffect, useState} from 'react';
import {connectStats} from 'react-instantsearch-dom';
import {StatsProvided} from 'react-instantsearch-core';
import Typography from '@mui/material/Typography';

type Props = {
  canClearCriteria?: boolean;
  noSelection?: boolean;
  onClearCriteria?: () => void;
  onResult?: (nbHits: number) => void;
  pluralResultLabel?: string;
  resultLabel?: string;
} & StatsProvided;

export const ResultCount: FC<Props> = ({
  nbHits,
  noSelection,
  onResult,
  pluralResultLabel = 'results',
  resultLabel = 'result',
}) => {
  const [lastNbHits, setLastNbHits] = useState<number>();
  const [displayCount, setDisplayCount] = useState<number>(0);

  useEffect(() => {
    if (nbHits && lastNbHits !== nbHits) {
      setLastNbHits(nbHits);
      // override algolia results eg where null criteria equals max results
      const count = noSelection ? 0 : nbHits;
      setDisplayCount(count);
      onResult?.(count);
    }
  }, [nbHits, onResult, lastNbHits, noSelection]);

  return (
    <Typography>
      {`${displayCount} ${
        displayCount === 1 ? resultLabel : pluralResultLabel
      } selected (based on your criteria) `}
    </Typography>
  );
};

export default connectStats(ResultCount);
