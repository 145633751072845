import React, {FC, useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as R from 'ramda';
import Consts from '../../app/Consts';
import {
  getDisplayCommasValue,
  numberWithCommas,
  getDisplayAmountValue,
} from '../../utils/AmountUtils';
import {DealValuesForSummaryData, DealType, Pagination} from '../../types';
import ProductSelector from '../Form/Agolia/ProductSelector';
import PageNavigator from '../Table/Pagination/PageNavigator';
import PageCounter from '../Table/Pagination/PageCounter';
import PageSetting from '../Table/Pagination/PageSetting';
import SectionContainer from '../Table/Pagination/SectionContainer';
import {StaticButton} from '../Button';
import {ExternalLinkIcon} from '../Icons';

const PREFIX = 'DealSummaryValuesTable';

const classes = {
  tableHeading: `${PREFIX}-tableHeading`,
  tableHeadingCell: `${PREFIX}-tableHeadingCell`,
  tableCell: `${PREFIX}-tableCell`,
  tableFirstRow: `${PREFIX}-tableFirstRow`,
  tableRow: `${PREFIX}-tableRow`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableHeading}`]: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
  [`& .${classes.tableHeadingCell}`]: {
    color: '#FFFFFF',
  },
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.tableFirstRow}`]: {
    paddingLeft: '1.875rem',
  },
  [`& .${classes.tableRow}`]: {
    boxShadow: '0px 3px 6px #00000029',
  },
});

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 3.75rem;
  padding: 1.25rem 1.125rem 0;
  background-color: #f2f4f5;

  input,
  .MuiSelect-select {
    background-color: #ffffff;
  }
`;

type CustomTableColumn = {
  field: string;
  title: string | React.ReactNode;
  render?: (rowData: DealValuesForSummaryData, rowIndex: number) => React.ReactNode;
  hide?: boolean;
};

type Props = {
  values: DealValuesForSummaryData[];
  skuSelectorMode: boolean;
  dealType: DealType;
  pagination: Pagination;
  onPagination: (currentPage: Pagination['currentPage'], pageSize: Pagination['pageSize']) => void;
};

const DealSummaryValuesTable: FC<Props> = ({
  values,
  skuSelectorMode,
  dealType,
  pagination,
  onPagination,
}) => {
  const [productSelectorData, setProductSelectorData] = useState<{
    rowData: DealValuesForSummaryData;
    rowIndex: number;
  } | null>(null);
  const [data, setData] = useState<DealValuesForSummaryData[]>([]);

  useEffect(() => {
    setData(values);
  }, [values]);

  const getColumns = (dealType: DealType) => {
    const columns: CustomTableColumn[] = [
      {
        field: 'id',
        title: 'ID',
      },
      {
        field: 'amount',
        title: 'Value',
        render: renderValueColumn,
      },
      {
        field: 'gstType',
        title: 'GST',
        render: (rowData) => rowData.gstType,
      },
      {
        field: 'productCode',
        title: renderSkuColumnTitle(),
        render: renderSkuColumn,
      },
      {
        field: 'ticketPrice',
        title: 'Ticket Price',
        render: (rowData) => renderPriceColumn(rowData.ticketPrice),
        hide: dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode,
      },
      {
        field: 'promoPrice',
        title: <span>Promo Price</span>,
        render: (rowData) => renderPriceColumn(rowData.promoPrice),
        hide: dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode,
      },
      {
        field: 'promotionId',
        title: <span>Promotion Id</span>,
        render: (rowData) => rowData.promotionId,
        hide: dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode,
      },
      {
        field: 'capValue',
        title: <span>Unit or $ Cap (ex. GST)</span>,
        render: renderCapColumn,
        hide: dealType === Consts.StandardDealTypeEnum.FixedAmount,
      },
      {
        field: 'amountSoldOrPurchased',
        title:
          dealType === Consts.StandardDealTypeEnum.Purchases ? 'Total Purchased' : 'Amount Sold',
        render: (rowData) => rowData.amountSoldOrPurchased,
      },
      {
        field: 'accruedTotal',
        title: 'Accrued Total (ex)',
        render: (rowData) => getDisplayAmountValue(rowData.accruedTotal, '$'),
      },
      {
        field: 'gstTotal',
        title: 'GST Total',
        render: (rowData) => getDisplayAmountValue(rowData.gstTotal, '$'),
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const renderValueColumn = (rowData: DealValuesForSummaryData) => {
    const unit = Consts.AmountType.find((x) => x.value === rowData.amountType);
    if (dealType === Consts.StandardDealTypeEnum.FixedAmount) {
      return getDisplayAmountValue(rowData.amount, '$');
    }
    if (unit?.value === Consts.AmountTypeEnum.FixedAmount) {
      return getDisplayAmountValue(rowData.amount, '$');
    }
    if (unit?.value === Consts.AmountTypeEnum.ValuePerUnit) {
      return getDisplayAmountValue(rowData.amount, '$', ' per unit');
    }
    return getDisplayAmountValue(rowData.amount, '', '%');
  };

  const renderCapColumn = (rowData: DealValuesForSummaryData) => {
    const unit = R.find(R.propEq('value', rowData.capType), Consts.DealCapType);
    const value = numberWithCommas(rowData.capValue);
    if (!value) {
      return null;
    }
    if (unit?.value === Consts.DealCapTypeEnum.UnitCount) {
      return getDisplayCommasValue(
        rowData.capValue,
        '',
        (rowData.capValue ?? 0) > 1 ? ' Units' : ' Unit'
      );
    }
    return getDisplayCommasValue(rowData.capValue, '$');
  };

  const renderSkuColumnTitle = () => {
    return <span>SKU</span>;
  };
  const renderPriceColumn = (price: number | null) => {
    return getDisplayCommasValue(price, '$');
  };

  const renderSkuColumn = (rowData: DealValuesForSummaryData, rowIndex: number) => {
    if (skuSelectorMode) {
      return rowData.productCode ? `${rowData.productCode} ${rowData.productDescription}` : null;
    } else {
      return rowData.productCriteria ? (
        <StaticButton
          startIcon={<ExternalLinkIcon />}
          sx={{
            height: '53px',
            fontSize: '15px',
          }}
          onClick={() => {
            setProductSelectorData({rowData, rowIndex});
          }}
        >
          {rowData.productCriteria.resultCount ?? 0} SKUs selected (based on your criteria)
        </StaticButton>
      ) : null;
    }
  };

  const renderProductSelector = () => {
    if (productSelectorData) {
      const {rowData} = productSelectorData;
      return (
        <ProductSelector
          fullScreen
          open
          readOnly
          productCriteria={rowData.productCriteria}
          handleClose={() => setProductSelectorData(null)}
        />
      );
    }
  };

  function onChangePage(currentPage: Pagination['currentPage']) {
    onPagination(currentPage, pagination.pageSize);
  }
  function onChangePageSize(pageSize: Pagination['pageSize']) {
    onPagination(1, pageSize);
  }
  function renderCell(
    column: CustomTableColumn,
    row: DealValuesForSummaryData,
    rowIndex: number
  ): React.ReactNode | string | number | null {
    if (column.render) {
      return <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
    }
    return row[column.field as keyof DealValuesForSummaryData] as string | number | null;
  }
  function renderRow(row: DealValuesForSummaryData, rowIndex: number) {
    return (
      <TableRow className={classes.tableRow} key={`${dealType}-${rowIndex}`}>
        {getColumns(dealType).map((column, columnIndex) => (
          <TableCell className={columnIndex === 0 ? classes.tableFirstRow : ''} key={columnIndex}>
            {renderCell(column, row, rowIndex)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead className={classes.tableHeading}>
          <TableRow>
            {getColumns(dealType).map((column, index) => {
              return (
                <TableCell
                  className={
                    index === 0
                      ? `${classes.tableHeadingCell} ${classes.tableFirstRow}`
                      : classes.tableHeadingCell
                  }
                  key={index}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{data.map((row, index) => renderRow(row, index))}</TableBody>
      </Table>
      <TablePaginationContainer>
        <PageCounter pagination={pagination} />
        <PageNavigator pagination={pagination} onChangePage={onChangePage} />
        <PageSetting pagination={pagination} onChangePageSize={onChangePageSize} />
      </TablePaginationContainer>
      {renderProductSelector()}
    </StyledTableContainer>
  );
};

export default DealSummaryValuesTable;
