import React, {useEffect, useState, useContext, useCallback} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as R from 'ramda';
import {AxiosResponse} from 'axios';
import {api, get, post, del} from '../../../utils/Request';
import {getFormattedDateTime} from '../../../utils/DateUtils';
import {getRebateType, getDisplayAmount, getGSTType} from '../../../utils/TypesStatusUtils';
import {
  EntityActionType,
  RebateResponse,
  ClaimListResponse,
  RebateSummaryResponse,
  TableColumn,
} from '../../../types';
import Consts from '../../../app/Consts';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import LoadingContext from '../../../app/LoadingContext';
import {
  RebateSummaryIcon,
  EditIcon,
  DeleteIcon,
  MoneyGraphIcon,
  ChevronRightIcon,
  DuplicateIcon,
  LocationsIcon,
  BarcodeIcon,
} from '../../../components/Icons';
import {IconLabelButtonProps} from '../../../components/Button/IconLabelButton';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {TableTabPanel} from '../../../components/TableTabPanel';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import ProductSelector from '../../../components/Form/Agolia/ProductSelector';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import TabsComponent from '../../../components/TabsComponent';
import {HeadingComponent} from '../../../components/HeadingComponent';
import DynamicContainerWithHeader from '../../../components/Container/DynamicContainerWithHeader';
import {SimpleDataTable} from '../../../components/SimpleDataTable';
import {Amount} from '../../../components/Amount';

import {
  DeleteAgreementOrRebateConfirmModal,
  DeleteAgreementOrRebateWithActiveClaimConfirmModal,
  DeleteAgreementorRebateWithClaimForbiddenModal,
} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import HeaderWithGst from '../../../components/Table/HeaderWithGst';
import {ErrorBox} from '../../../components/Alert';
import FinanceAccountsSummary from '../../../components/DealSummary/FinanceAccountsSummary';
import RebateComments from './RebateComments';
import RebateAttachments from './RebateAttachments';

const PREFIX = 'RebateSummary';

const classes = {
  root: `${PREFIX}-root`,
  pageFlexColumn: `${PREFIX}-pageFlexColumn`,
  flexColumnHalf: `${PREFIX}-flexColumnHalf`,
  flexContent: `${PREFIX}-flexContent`,
  flexContentCentered: `${PREFIX}-flexContentCentered`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  fixWidthContainerSm: `${PREFIX}-fixWidthContainerSm`,
  tabs: `${PREFIX}-tabs`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  tabPanelContainerRoot: `${PREFIX}-tabPanelContainerRoot`,
  tabPanelContentWrapper: `${PREFIX}-tabPanelContentWrapper`,
  contentGroupWrapper: `${PREFIX}-contentGroupWrapper`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.pageFlexColumn}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.flexColumnHalf}`]: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '25rem',
  },

  [`& .${classes.flexContent}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  [`& .${classes.flexContentCentered}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '2.5rem',
  },

  [`& .${classes.contentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 0.5rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
  },

  [`& .${classes.fixWidthContainerSm}`]: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
  },

  [`& .${classes.tabs}`]: {
    '& .MuiTabs-root': {
      button: {
        maxWidth: '19rem',
      },
    },
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.tabPanelContainerRoot}`]: {
    width: '100%',
    maxWidth: '100%',
    padding: '5.25rem 1.5rem 1rem 1.5rem',
  },

  [`& .${classes.tabPanelContentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 0 5.625rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.contentGroupWrapper}`]: {
    paddingTop: '1.5rem',
  },
}));

const TabsIndex = {
  RebateInformation: 'RebateInformation',
  RebateAccrual: 'Accrual',
  Attachments: 'Attachments',
  Comments: 'Comments',
};
const tabs = [
  {
    value: TabsIndex.RebateInformation,
    label: 'Rebate Information',
  },
  {
    value: TabsIndex.RebateAccrual,
    label: 'Accrual',
  },
  {
    value: TabsIndex.Attachments,
    label: 'Attachments',
  },
  {
    value: TabsIndex.Comments,
    label: 'Comments',
  },
];

const RebateSummary = () => {
  const {id = ''} = useParams();
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);

  const [selectedTab, setSelectedTab] = useState('RebateInformation');
  const [rebateData, setRebateData] = useState<RebateSummaryResponse>();
  const [pageReady, setPageReady] = useState(false);
  const [rebateDetails, setRebateDetails] = useState<{title: string; value?: string}[]>([]);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);
  const [openDeleteWithClaimConfirmModal, setOpenDeleteWithClaimConfirmModal] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState<ClaimListResponse | null>(null);
  const [ctrlKey, setCtrlKey] = useState(false);

  const claimPagination = {
    ...Consts.DefaultPagination,
    pageSize: Consts.PageSize[0],
  };

  const editRebate = () => {
    navigate(Consts.RouterPath.EditRebate.replace(':id', id));
  };
  const handleNavAction = (
    type: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.persist();
    setCtrlKey(event.ctrlKey);
    if (type === 'duplicate') {
      const requestData = {
        rebateId: id,
        createdByStaffCode: loggedInStaffCode,
      };
      showLoading();
      post(api(Consts.Api.RebateDuplicate), requestData)
        .then((response: AxiosResponse<RebateResponse>) => {
          navigate(Consts.RouterPath.EditRebate.replace(':id', `${response.data.id}`));
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    } else if (type === 'edit') {
      showLoading();
      get(api(Consts.Api.RebateClaims.replace(':id', id)), {
        params: {
          status: Consts.ClaimStatusEnum.Raised,
          ...claimPagination,
        },
      })
        .then((response: AxiosResponse<ClaimListResponse>) => {
          const data = response.data;
          if (data.totalCount === 0) {
            editRebate();
          } else {
            setRaisedClaimsResponse(data);
            setOpenEditModal(true);
          }
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    } else if (type === 'delete') {
      showLoading();
      get(api(Consts.Api.RebateClaims.replace(':id', id)))
        .then((response) => {
          if (response.data.totalCount === 0) {
            setOpenDeleteConfirmModal(true);
          } else {
            if (response.data.totalRaisedCount > 0) {
              setOpenDeleteWithClaimForbiddenModal(true);
            } else {
              setOpenDeleteWithClaimConfirmModal(true);
            }
          }
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    }
  };
  const deleteRebate = () => {
    showLoading();
    del(api(Consts.Api.Rebate.replace(':id', id)))
      .then(() => {
        navigate(Consts.RouterPath.MyContractAgreements);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };
  const getHeadingButtons = (summaryData: RebateSummaryResponse) => {
    const buttons: IconLabelButtonProps[] = [
      {
        label: 'Duplicate Rebate',
        icon: <DuplicateIcon style={{width: '21px'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('duplicate', event),
        role: Consts.UserRoleEnum.AddOrUpdateContractAgreements,
      },
    ];
    if (!summaryData.isDeleted) {
      buttons.push(
        {
          label: 'Edit Rebate',
          icon: <EditIcon style={{width: '21px'}} />,
          style: {color: '#626262', fontWeight: 400},
          onClick: (event) => handleNavAction('edit', event),
          role: Consts.UserRoleEnum.AddOrUpdateContractAgreements,
        },
        {
          label: 'Delete Rebate',
          icon: <DeleteIcon style={{width: '21px'}} />,
          style: {color: '#d0021b', fontWeight: 400},
          onClick: (event) => handleNavAction('delete', event),
          role: Consts.UserRoleEnum.AddOrUpdateContractAgreements,
          disabled: !summaryData.isDeletable,
          disabledText: 'Cannot be deleted as there are related invoiced and/or raised claim(s).',
        }
      );
    }
    return buttons;
  };

  const setData = useCallback(
    (responseData: RebateSummaryResponse) => {
      setRebateData(responseData);
      setRebateDetails([
        {
          title: 'Claim Vendor',
          value: responseData.claimVendorName,
        },
        {
          title: 'Rebate Type',
          value: getRebateType(responseData.type),
        },
        {
          title: 'Value',
          value: `${getDisplayAmount(
            responseData.amountType,
            responseData.amount
          )} (GST ${getGSTType(responseData.gstType)})`,
        },
        {
          title: 'Start Date',
          value: getFormattedDateTime(responseData.startAt),
        },
        {
          title: 'End Date',
          value: getFormattedDateTime(responseData.endAt),
        },
        {
          title: 'Owner',
          value: `${responseData.ownedByStaffCode} - ${responseData.ownedByStaffName}`,
        },
        {
          title: 'Last Modified Date',
          value: getFormattedDateTime(responseData.lastModifiedAt),
        },
        {
          title: 'Last Modified By',
          value: `${responseData.lastModifiedByStaffCode} - ${responseData.lastModifiedByStaffName}`,
        },
      ]);
      setPageReady(true);
    },
    [setRebateData, setRebateDetails, setPageReady]
  );

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.RebateSummary.replace(':id', id)))
      .then((response: AxiosResponse<RebateSummaryResponse>) => {
        setData(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [hideLoading, id, setData, showLoading]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const downloadSkus = () => {
    showLoading();
    get(api(Consts.Api.RebateProductDownload.replace(':id', id)), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = `Skus of rebate ${id}.xlsx`;
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };
  const accrualStyle = {height: '3.3125rem'};
  const accrualColumns: TableColumn<RebateSummaryResponse['accrual']>[] = [
    {
      id: 'totalPurchases',
      label: <HeaderWithGst header="Total Cost" gstType={Consts.GstTypeEnum.Exclusive} />,
      render: (rowData) => <Amount value={rowData?.totalPurchases} prefix="$" />,
      style: accrualStyle,
    },
    {
      id: 'writebacks',
      label: <HeaderWithGst header="Writebacks" gstType={Consts.GstTypeEnum.Exclusive} />,
      render: (rowData) => <Amount value={rowData?.writebacks} prefix="$" />,
      style: accrualStyle,
    },
    {
      id: 'adjustments',
      label: <HeaderWithGst header="Adjustments" gstType={Consts.GstTypeEnum.Exclusive} />,
      render: (rowData) => <Amount value={rowData?.adjustments} prefix="$" />,
      style: accrualStyle,
    },
    {
      id: 'netPurchases',
      label: <HeaderWithGst header="Net Purchases" gstType={Consts.GstTypeEnum.Exclusive} />,
      render: (rowData) => <Amount value={rowData?.netPurchases} prefix="$" />,
      style: accrualStyle,
    },
    {
      id: 'accrual',
      label: <HeaderWithGst header="Rebate Accrual" gstType={rebateData?.accrualAmountGstType} />,
      render: (rowData) => <Amount value={rowData?.accrual} prefix="$" />,
      style: accrualStyle,
    },
  ];

  return (
    <Root className={classes.root}>
      {pageReady && !!rebateData ? (
        <div className={classes.pageFlexColumn}>
          <Container maxWidth={false}>
            {rebateData.isDeleted ? (
              <ErrorBox type={AlertType.Info}>
                This rebate is deleted - it will be removed overnight
              </ErrorBox>
            ) : null}

            <HeadingComponent
              headingIcon={<RebateSummaryIcon />}
              headingLabel="Rebate"
              buttons={getHeadingButtons(rebateData)}
              subheading="Under Agreement: "
              subheadingLink={rebateData.agreementDescription}
              subheadingLinkUrl={Consts.RouterPath.ContractAgreementSummary.replace(
                ':id',
                `${rebateData.agreementId}`
              )}
              style={rebateData.isDeleted ? {paddingTop: '40px'} : {}}
            />

            <Paper className={classes.contentWrapper}>
              <SummaryDetailsComponent
                id={rebateData.id}
                details={rebateDetails}
                status={rebateData.status}
                desc={rebateData.description}
                staffCode={rebateData.ownedByStaffCode}
                staffName={rebateData.ownedByStaffName}
                type="Rebate"
                hideOwner={true}
                externalId={rebateData.externalId}
                itemCountPerRow={3}
              />

              <div className={classes.fixWidthContainerSm}>
                <SplitIconAmountComponent
                  icon={<MoneyGraphIcon />}
                  label="Total Amount Accrued"
                  amount={rebateData.amountInClaimPeriod}
                  taxAmount={rebateData.taxAmountInClaimPeriod}
                  gstType={rebateData.accrualAmountGstType}
                />
              </div>
            </Paper>
          </Container>

          <div className={classes.tabs}>
            <TabsComponent
              tabs={tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />

            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.RebateInformation}
              className={classes.tabPanelRoot}
            >
              {!rebateData ? null : (
                <Container className={classes.tabPanelContainerRoot}>
                  <Paper className={classes.tabPanelContentWrapper}>
                    <div className={classes.flexContent}>
                      <div className={classes.flexColumnHalf}>
                        <DynamicContainerWithHeader title="Finance Details">
                          <SplitTextComponent
                            flexibleHeight
                            leftLabel="Finance Account"
                            rightLabel={
                              <FinanceAccountsSummary
                                financeAccounts={rebateData.financeAccounts ?? []}
                              />
                            }
                          />
                          <SplitTextComponent
                            leftLabel="Claim Frequency"
                            rightLabel={R.prop(
                              'label',
                              R.find(R.propEq('value', rebateData.claimInterval))(
                                Consts.RebateClaimInterval
                              )
                            )}
                          />
                          <SplitTextComponent
                            leftLabel="Returns"
                            rightLabel={rebateData.excludeReturns ? 'Excluded' : 'Included'}
                          />
                        </DynamicContainerWithHeader>

                        <DynamicContainerWithHeader title="Writebacks" style={{paddingTop: '50px'}}>
                          <SplitTextComponent
                            leftLabel={rebateData.writebackType ? 'Included' : 'None'}
                            rightLabel={R.prop(
                              'label',
                              R.find(R.propEq('value', rebateData.writebackType))(
                                Consts.WritebackType
                              )
                            )}
                          />
                        </DynamicContainerWithHeader>
                      </div>

                      <div className={classes.flexColumnHalf}>
                        <DynamicContainerWithHeader title="Location">
                          <SplitTextComponent
                            leftLabel={
                              rebateData.locationCriteria
                                ? `${rebateData.locationCriteria.resultCount} locations`
                                : 'All Locations'
                            }
                            buttonLabel="View Locations"
                            icon={<LocationsIcon />}
                            buttonIcon={<ChevronRightIcon />}
                            style={{color: '#DA6A00'}}
                            onButtonClick={() => setLocationSelectorOpen(true)}
                          />
                        </DynamicContainerWithHeader>

                        <DynamicContainerWithHeader title="SKUs" style={{paddingTop: '50px'}}>
                          {rebateData.productCriteria ? (
                            <SplitTextComponent
                              leftLabel={`${rebateData.productCriteria.resultCount} SKUs`}
                              buttonLabel="View SKUs"
                              icon={<BarcodeIcon />}
                              buttonIcon={<ChevronRightIcon />}
                              style={{color: '#DA6A00'}}
                              onButtonClick={() => setProductSelectorOpen(true)}
                            />
                          ) : null}

                          {rebateData.uploadedProductCount &&
                          rebateData.uploadedProductCount > 0 ? (
                            <SplitTextComponent
                              leftLabel={`${rebateData.uploadedProductCount} SKUs`}
                              buttonLabel="Download SKUs"
                              icon={<BarcodeIcon />}
                              buttonIcon={<GetAppIcon />}
                              style={{color: '#DA6A00'}}
                              onButtonClick={() => downloadSkus()}
                            />
                          ) : null}
                        </DynamicContainerWithHeader>
                      </div>
                    </div>
                  </Paper>
                </Container>
              )}
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.RebateAccrual}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <SimpleDataTable
                  columns={accrualColumns}
                  rows={[rebateData?.accrual]}
                  darkTheme={true}
                />
              </Container>
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Attachments}
              className={classes.tabPanelRoot}
            >
              {selectedTab === TabsIndex.Attachments ? (
                <Container className={classes.tabPanelContainerRoot}>
                  <Paper className={classes.tabPanelContentWrapper}>
                    <RebateAttachments />
                  </Paper>
                </Container>
              ) : null}
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Comments}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                {selectedTab === TabsIndex.Comments ? <RebateComments /> : null}
              </Container>
            </TableTabPanel>
          </div>

          {locationSelectorOpen ? (
            <LocationSelector
              fullScreen
              locationCriteria={rebateData?.locationCriteria ?? null}
              open={locationSelectorOpen}
              handleClose={() => setLocationSelectorOpen(false)}
              readOnly
            />
          ) : null}

          {productSelectorOpen ? (
            <ProductSelector
              fullScreen
              productCriteria={rebateData?.productCriteria ?? null}
              open={productSelectorOpen}
              defaultBuyerDepartments={[]}
              defaultSuppliers={[]}
              handleClose={() => setProductSelectorOpen(false)}
              readOnly={true}
            />
          ) : null}
          <DeleteAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Rebate}
            open={openDeleteConfirmModal}
            onCancel={() => setOpenDeleteConfirmModal(false)}
            onOk={() => {
              setOpenDeleteConfirmModal(false);
              deleteRebate();
            }}
          />
          <DeleteAgreementOrRebateWithActiveClaimConfirmModal
            entityActionType={EntityActionType.Rebate}
            open={openDeleteWithClaimConfirmModal}
            onCancel={() => setOpenDeleteWithClaimConfirmModal(false)}
            onOk={() => {
              setOpenDeleteWithClaimConfirmModal(false);
              setOpenDeleteConfirmModal(true);
            }}
          />
          <DeleteAgreementorRebateWithClaimForbiddenModal
            entityActionType={EntityActionType.Rebate}
            open={openDeleteWithClaimForbiddenModal}
            onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
          />
          <EditAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Rebate}
            referenceId={id}
            ctrlKey={ctrlKey}
            open={openEditModal}
            claimsResponse={raisedClaimsResponse}
            onOk={() => {
              setOpenEditModal(false);
            }}
            onCancel={() => setOpenEditModal(false)}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default RebateSummary;
